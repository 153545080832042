define('edibroker/controllers/language/administration/member/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    rendered: false,
    loading: true,
    filter: _ember['default'].computed.alias('model.filter'),
    members: _ember['default'].computed.alias('model.members'),

    availableStatus: _edibrokerServicesConstants.MEMBER_STATUSES,
    availableTypes: _edibrokerServicesConstants.MEMBER_TYPES,
    availableProtocols: ['SFTP', 'SFTP_PUSH', 'WEBSERVICE', 'NONE'],
    availableMessages: _edibrokerServicesConstants.MESSAGE_TYPES,

    filterChange: _ember['default'].observer('filter.{idsIn.[],typesIn.[],statusIn.[],protocolsIn.[],messagesIn.[],sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterMembers();
      }
    }),

    filterMembers: function filterMembers() {
      var _this = this;

      this.store.query('member', this.get('filter')).then(function (members) {
        if (!members.get('length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('members', members);
        _this.set('loading', false);
      });
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      sort: function sort(value) {
        var sort = this.get('filter.sort');
        if (sort.indexOf(value) === -1) {
          this.set('filter.sort', value);
        } else if (sort.indexOf(',') === -1) {
          this.set('filter.sort', value + ',desc');
        } else {
          this.set('filter.sort', value);
        }
      },
      reset: function reset() {
        this.set('selectedMembers', []);
        this.set('collapsed', false);
        return true;
      }
    }
  });
});