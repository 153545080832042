define('edibroker/components/member/member-form', ['exports', 'ember', 'edibroker/components/member/model-form'], function (exports, _ember, _edibrokerComponentsMemberModelForm) {

  /**
   * Form component that can be used to create or edit a member, reusing basic behavior defined by model-form component.
   *
   * ```hbs
   * {{member/member-form member success='successAction' cancel='cancelAction' error='errorAction'}}
   * ```
   *
   * @param success
   *          {String} name of the action to be triggered when submitting the form
   * @param cancel
   *          {String} name of the action to be triggered when canceling the form
   * @param error
   *          {String} name of the action to be triggered when an error occurs
   */
  var MemberForm = _edibrokerComponentsMemberModelForm['default'].extend({
    showModal: false,
    currentUser: _ember['default'].inject.service(),
    restrictedEditMode: _ember['default'].computed('model.isNew', function () {
      return !(this.get('model.isNew') || this.get('currentUser.isNetProassur'));
    }),
    actions: {
      next: function next() {
        this.validate('next');
      }
    }
  });

  MemberForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = MemberForm;
});