define('edibroker/components/user/preference-box', ['exports', 'ember'], function (exports, _ember) {

  var PreferenceBox = _ember['default'].Component.extend({

    classNames: ['box', 'box-primary'],

    isEdit: false,

    messagesSubscriptions: _ember['default'].computed.alias('model.messagesSubscriptions'),

    actions: {
      toggleEdit: function toggleEdit() {
        this.toggleProperty('isEdit');
      }
    }

  });

  PreferenceBox.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = PreferenceBox;
});