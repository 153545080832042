define('edibroker/components/user/preference-table', ['exports', 'ember'], function (exports, _ember) {

  /**
    Display a the user preference table.
  
    Example:
    ```hbs
    {{user/preference-table model}}
    ```
   */
  var PreferenceTable = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    preference: _ember['default'].computed('model.preferences.{language,pageSize,menuState}', function () {
      var preference = {};

      var _get = this.get('model.preferences');

      var language = _get.language;
      var pageSize = _get.pageSize;
      var menuState = _get.menuState;

      if (language) {
        preference['language'] = {
          value: this.get('intl').t('language.' + language),
          isDefault: false
        };
      } else {
        preference['language'] = {
          value: this.get('intl').t('language.' + this.get('intl.currentLanguage')),
          isDefault: true
        };
      }

      if (pageSize) {
        preference['pageSize'] = {
          value: pageSize,
          isDefault: false
        };
      } else {
        preference['pageSize'] = {
          value: 20,
          isDefault: true
        };
      }

      if (menuState) {
        preference['menuState'] = {
          value: this.get('intl').t('user.settings.menu-' + menuState),
          isDefault: false
        };
      } else {
        preference['menuState'] = {
          value: this.get('intl').t('user.settings.menu-collapsed'),
          isDefault: true
        };
      }

      return preference;
    })
  });

  PreferenceTable.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = PreferenceTable;
});