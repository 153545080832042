define('edibroker/components/side-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentUser: _ember['default'].inject.service(),

    user: _ember['default'].computed.alias('currentUser.user'),

    didInsertElement: function didInsertElement() {
      _ember['default'].$('[data-toggle="edibroker-sidebar"]').on('click', function () {
        _ember['default'].$('.edibroker-sidebar').toggleClass('control-sidebar-open');
      });
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('[data-toggle="edibroker-sidebar"]').off('click');
    }
  });
});