define('edibroker/controllers/language/file/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    isNetProAssur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),
    availableTransferTypes: _edibrokerServicesConstants.TRANSFER_TYPES,
    availableFileStatuses: _edibrokerServicesConstants.FILE_SEARCH_STATUSES,
    availableProtocolTypes: _edibrokerServicesConstants.PROTOCOL_TYPES,
    availableMessageFormats: _edibrokerServicesConstants.MESSAGE_FORMATS,
    availableMessageTypes: _edibrokerServicesConstants.MESSAGE_TYPES,
    availableMessageVersions: _edibrokerServicesConstants.MESSAGE_VERSIONS,
    filter: undefined,
    files: _ember['default'].computed.alias('model'),
    availableFilters: [],
    selectedReceivers: [],
    selectedSenders: [],
    transferTypesIn: [],
    selectedFilter: undefined,
    fileName: '',
    rendered: false,
    fileLoading: false,
    filterLoading: false,
    filterListLoading: false,
    showFilterNameModal: false,
    showDeleteModal: false,
    messageCount: 0,

    inProtocols: _ember['default'].computed(function () {
      var protocols = _edibrokerServicesConstants.PROTOCOL_TYPES.slice(0);
      protocols.removeObject('SFTP_PUSH');
      return protocols;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('filter', this.getEmptyFilter());
    },

    getEmptyFilter: function getEmptyFilter() {
      return {
        partnerIdsIn: [],
        receiverIdsIn: [],
        senderIdsIn: [],
        depositStart: '',
        depositEnd: '',
        msgTypesIn: [],
        fileNameStartsWith: '',
        statusesIn: [],
        firstReadyStart: '',
        firstReadyEnd: '',
        lastReadyStart: '',
        lastReadyEnd: '',
        firstWithdrawStart: '',
        firstWithdrawEnd: '',
        lastWithdrawStart: '',
        lastWithdrawEnd: '',
        inputVersionsIn: [],
        outputVersionsIn: [],
        inputFormatsIn: [],
        outputFormatsIn: [],
        inputProtocolsIn: [],
        outputProtocolsIn: [],
        sort: 'lastEventDate,desc',
        page: 0,
        size: this.get('currentUser.preferences.pageSize') || 20
      };
    },

    filterChange: _ember['default'].observer('filter.{option,partnerIdsIn.[],receiverIdsIn.[],senderIdsIn.[],depositStart,depositEnd,msgTypesIn.[],fileNameStartsWith,statusesIn.[],firstReadyStart,firstReadyEnd,lastReadyStart,lastReadyEnd,firstWithdrawStart,firstWithdrawEnd,lastWithdrawStart,lastWithdrawEnd,inputVersionsIn.[],outputVersionsIn.[],inputFormatsIn.[],outputFormatsIn.[],inputProtocolsIn.[],outputProtocolsIn.[],sort,page,size}', function () {
      _ember['default'].run.once(this, 'filterFiles');
    }),

    getMessageCountURL: function getMessageCountURL() {
      var filterJSON = JSON.parse(JSON.stringify(this.get('filter')));
      delete filterJSON['sort'];
      delete filterJSON['page'];
      delete filterJSON['size'];
      var messageCountURL = '/api/files/messageCount?';
      for (var key in filterJSON) {
        messageCountURL = messageCountURL.concat(key + '=' + filterJSON[key] + '&');
      }
      return messageCountURL = messageCountURL.slice(0, -1);
    },

    filterFiles: function filterFiles() {
      var _this = this;

      if (this.get('rendered')) {
        this.set('fileLoading', true);
        var promises = [this.store.query('file', this.get('filter')).then(function (files) {
          _this.set('files', files);
        }), this.get('ajax').request(this.getMessageCountURL()).then(function (msgCount) {
          return _this.set('messageCount', msgCount);
        })];
        _ember['default'].RSVP.allSettled(promises).then(function () {
          _this.set('fileLoading', false);
        });
      }
    },

    fileNameChange: _ember['default'].observer('fileName', function () {
      _ember['default'].run.debounce(this, 'updateFileFilter', 250);
    }),

    updateFileFilter: function updateFileFilter() {
      var fileName = this.get('fileName');
      if (fileName.length >= 13) {
        this.set('filter.fileNameStartsWith', fileName);
      } else {
        this.set('filter.fileNameStartsWith', undefined);
      }
    },

    transferTypeChange: _ember['default'].observer('transferTypesIn.[]', function () {
      var transferTypesIn = this.get('transferTypesIn');
      var sent = transferTypesIn.includes('SENT');
      var received = transferTypesIn.includes('RECEIVED');
      if (sent && !received) {
        this.set('filter.option', 'sent');
      } else if (received && !sent) {
        this.set('filter.option', 'received');
      } else {
        this.set('filter.option', undefined);
      }
    }),

    resetFields: function resetFields() {
      this.set('filter', this.getEmptyFilter());
      this.set('selectedReceivers', []);
      this.set('selectedSenders', []);
      this.set('transferTypesIn', []);
      this.set('fileName', '');
      this.set('selectedFilter', undefined);
    },

    getDisplayStyle: function getDisplayStyle(property) {
      if (this.get(property)) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    },

    loadingStyle: _ember['default'].computed('fileLoading', function () {
      return this.getDisplayStyle('fileLoading');
    }),

    filterLoadingStyle: _ember['default'].computed('filterLoading', function () {
      return this.getDisplayStyle('filterLoading');
    }),

    filterListLoadingStyle: _ember['default'].computed('filterListLoading', function () {
      return this.getDisplayStyle('filterListLoading');
    }),

    isSelectedFilter: _ember['default'].computed('selectedFilter', function () {
      return !!this.get('selectedFilter');
    }),

    addMemberPromise: function addMemberPromise(promises, memberIds, selectedMembers) {
      var _this2 = this;

      if (memberIds) {
        memberIds.forEach(function (memberId) {
          promises.pushObject(_this2.store.find('member', memberId).then(function (member) {
            return selectedMembers.pushObject(member);
          }));
        });
      }
    },

    actions: {
      loadFilters: function loadFilters() {
        var _this3 = this;

        this.set('filterListLoading', true);
        this.store.findAll('filter').then(function (searchFilters) {
          _this3.set('availableFilters', searchFilters);
          _this3.set('filterListLoading', false);
        });
      },

      showCreateFilterModal: function showCreateFilterModal() {
        this.set('previousFilter', this.get('selectedFilter'));
        this.set('selectedFilter', this.get('store').createRecord('filter', {
          filterContent: JSON.stringify(this.get('filter'))
        }));
        this.set('showFilterNameModal', true);
      },

      showDeleteFilterModal: function showDeleteFilterModal() {
        this.set('showDeleteModal', true);
      },

      startUpdateFilter: function startUpdateFilter(showRename) {
        this.set('selectedFilter.filterContent', JSON.stringify(this.get('filter')));
        if (showRename) {
          this.set('showFilterNameModal', true);
        } else {
          this.send('proceedSave');
        }
      },

      applyFilter: function applyFilter(selectedFilter) {
        var _this4 = this;

        this.resetFields();
        this.set('filterLoading', true);
        var filterContent = JSON.parse(selectedFilter.get('filterContent'));
        this.set('filter', filterContent);
        this.set('fileName', filterContent.fileNameStartsWith);
        var promises = [];
        this.addMemberPromise(promises, this.get('filter.receiverIdsIn'), this.get('selectedReceivers'));
        this.addMemberPromise(promises, this.get('filter.senderIdsIn'), this.get('selectedSenders'));
        _ember['default'].RSVP.allSettled(promises).then(function () {
          _this4.set('filterLoading', false);
          _this4.set('selectedFilter', selectedFilter);
        });
      },

      proceedSave: function proceedSave(defer) {
        var _this5 = this;

        var selectedFilter = this.get('selectedFilter');
        var creating = selectedFilter.get('isNew');
        selectedFilter.validate().then(function (filter) {
          _this5.set('didValidate', true);
          if (_ember['default'].get(filter, 'validations.isValid')) {
            selectedFilter.save().then(function () {
              if (creating) {
                _this5.get('toast').success(_this5.get('intl').t('file.filter.create-success'));
              } else {
                _this5.get('toast').success(_this5.get('intl').t('file.filter.edit-success'));
              }
            })['catch'](function (error) {
              return _this5.send('error', error);
            })['finally'](function () {
              _this5.set('didValidate', false);
              if (defer) {
                defer.resolve();
              }
            });
          } else {
            if (defer) {
              defer.reject();
            }
            _this5.get('toast').error(_this5.get('intl').t('error.validations.hasError'));
          }
        });
      },

      reloadModel: function reloadModel(file) {
        file.reload();
      },

      cancelSave: function cancelSave() {
        var filter = this.get('selectedFilter');
        if (filter.get('isNew')) {
          this.set('selectedFilter', this.get('previousFilter'));
        }
        filter.rollbackAttributes();
        this.set('didValidate', false);
      },

      proceedDelete: function proceedDelete() {
        var _this6 = this;

        var selectedFilter = this.get('selectedFilter');
        selectedFilter.destroyRecord().then(function () {
          _this6.get('toast').success(_this6.get('intl').t('file.filter.delete-success'));
          _this6.send('reset');
        }, function (error) {
          return _this6.send('error', error);
        });
      },

      cancelDepositDate: function cancelDepositDate() {
        this.set('filter.depositStart', undefined);
        this.set('filter.depositEnd', undefined);
      },

      cancelFirstAvailDate: function cancelFirstAvailDate() {
        this.set('filter.firstReadyStart', undefined);
        this.set('filter.firstReadyEnd', undefined);
      },

      cancelLastAvailDate: function cancelLastAvailDate() {
        this.set('filter.lastReadyStart', undefined);
        this.set('filter.lastReadyEnd', undefined);
      },

      cancelFirstRetrievalDate: function cancelFirstRetrievalDate() {
        this.set('filter.firstWithdrawStart', undefined);
        this.set('filter.firstWithdrawEnd', undefined);
      },

      cancelLastRetrievalDate: function cancelLastRetrievalDate() {
        this.set('filter.lastWithdrawStart', undefined);
        this.set('filter.lastWithdrawEnd', undefined);
      },

      reset: function reset() {
        this.resetFields();
      }
    }
  });
});