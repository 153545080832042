define('edibroker/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].testing ? _emberData['default'].RESTSerializer : _emberData['default'].RESTSerializer.extend({

    serialize: function serialize(record, options) {
      options = options || { includeId: true };
      return this._super(record, options);
    },

    extractMeta: function extractMeta(store, type, payload) {
      var metadata = {};
      if (payload && type) {
        if (payload.total !== undefined) {
          metadata.total = payload.total;
          delete payload.total;
        }
        if (payload.pageable !== undefined) {
          if (payload.pageable.page !== undefined) {
            metadata.page = payload.pageable.page;
          }
          if (payload.pageable.size !== undefined) {
            metadata.size = payload.pageable.size;
          }
          delete payload.pageable;
        }
      }
      return metadata;
    },

    normalizeResponse: function normalizeResponse(store, type, payload, id, requestType) {
      var content = _ember['default'].isArray(payload) ? [] : {};
      if (payload) {
        if (payload.content) {
          content = payload.content;
          delete payload.content;
        } else if (_ember['default'].isArray(payload)) {
          content = content.concat(payload);
          payload = {};
        } else {
          for (var key in payload) {
            if (payload.hasOwnProperty(key)) {
              content[key] = payload[key];
              delete payload[key];
            }
          }
        }
        payload[type.modelName] = content;
      }
      return this._super(store, type, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      _ember['default'].merge(hash, this.serialize(record, options));
    }
  });
});