define('edibroker/controllers/language/profile/password', ['exports', 'ember', 'edibroker/services/constants', 'ember-cp-validations'], function (exports, _ember, _edibrokerServicesConstants, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    oldPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'error.back.missing.password'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])).*/,
      messageKey: 'error.back.illegal.password-rules'
    })],
    newPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'error.back.missing.password'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])).*/,
      messageKey: 'error.back.illegal.password-rules'
    }), (0, _emberCpValidations.validator)('different', {
      on: 'oldPassword',
      messageKey: 'error.back.illegal.password-new'
    })],
    confirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      messageKey: 'error.back.illegal.password'
    })
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    didValidate: false,
    loading: false,

    oldPassword: '',
    newPassword: '',
    confirmation: '',

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.validate().then(function () {
          _this.set('didValidate', true);
          if (_ember['default'].get(_this, 'validations.isValid')) {
            var data = _this.getProperties('oldPassword', 'newPassword', 'confirmation');
            _this.set('oldPassword', null);
            _this.set('newPassword', null);
            _this.set('confirmation', null);
            _this.set('didValidate', false);
            _ember['default'].$.ajax({
              url: '/api/users/' + _this.get('currentUser.user.id') + '/password',
              method: 'POST',
              data: data
            }).then(function () {
              _ember['default'].Logger.info('Password updated');
              _this.get('toast').success(_this.get('intl').t('user.profile.password-success'));
              _this.set('loading', false);
              _this.transitionToRoute('index');
            }).fail(function (response) {
              _ember['default'].Logger.error('Error while updating password', response);
              var message = _ember['default'].get(response, 'responseJSON.errors.message') || '';
              var key = (0, _edibrokerServicesConstants.getErrorKey)(message, function (value) {
                return _this.get('intl').exists(value);
              });
              _this.get('toast').error(_this.get('intl').t(key));
              _this.set('loading', false);
            });
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      }
    }
  });
});