define('edibroker/controllers/language/administration/message/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    rendered: false,
    loading: false,
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    filter: _ember['default'].computed.alias('model.filter'),
    messages: _ember['default'].computed.alias('model.messages'),

    showDeleteModal: false,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    filterChange: _ember['default'].observer('filter.{sort,page,size}', function () {
      if (this.get('rendered')) {
        this.filterMessages();
      }
    }),

    filterMessages: function filterMessages() {
      var _this = this;

      this.set('loading', true);
      this.store.query('info', this.get('filter')).then(function (infos) {
        if (!_ember['default'].get(infos, 'length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('messages', infos);
        _this.set('loading', false);
      })['catch'](function (errors) {
        _this.send('error', errors);
      });
    },

    actions: {
      askDelete: function askDelete(message) {
        this.set('msgToDelete', message);
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this2 = this;

        var message = this.get('msgToDelete');
        this.set('msgToDelete', null);
        message.destroyRecord().then(function () {
          defer.resolve();
          _this2.get('toast').success(_this2.get('intl').t('message.delete-success'));
        }, function (error) {
          defer.resolve();
          _this2.send('error', error);
        });
      }
    }

  });
});