define('edibroker/controllers/language/file/upload', ['exports', 'ember', 'ember-cp-validations', 'edibroker/services/constants'], function (exports, _ember, _emberCpValidations, _edibrokerServicesConstants) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    file: [(0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('file-extension', { 'extensions': _edibrokerServicesConstants.FILE_UPLOAD_EXTENSIONS, 'message': 'error.validations.invalidFileExtension' })],
    member: (0, _emberCpValidations.validator)('presence', { presence: true })
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    didValidate: false,
    loading: false,
    upload: false,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    data: _ember['default'].computed('member', function () {
      if (!_ember['default'].isBlank(this.get('member'))) {
        return new Blob([JSON.stringify(this.get('member'))], { type: 'application/json;charset=UTF-8' });
      }
    }),

    resetController: function resetController() {
      this.set('upload', false);
      this.set('file', undefined);
      this.set('member', undefined);
      this.set('loading', false);
      this.set('didValidate', false);
    },

    actions: {

      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.validate().then(function (hash) {
          _this.set('didValidate', true);
          if (_ember['default'].get(hash, 'validations.isValid')) {
            _this.set('upload', true);
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      },

      success: function success(data) {
        var _this2 = this;

        this.resetController();
        this.get('toast').success(this.get('intl').t('file.upload.success'), '', { onclick: function onclick() {
            return _this2.transitionToRoute('language.file.detail', data[0].id);
          } });
      },

      failure: function failure(error) {
        this.resetController();
        this.send('error', error.responseJSON);
      }
    }
  });
});