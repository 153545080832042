define('edibroker/components/sort-link', ['exports', 'ember'], function (exports, _ember) {

  /**
   Create a link to update a sort property, displayed with an icon indicating current sorting state.
   The separator between the sort property and sort direction can be configured with the sortSep parameter
  
   Example:
   ```hbs
   {{sort-link label='My sorting link' sortBy='code' sort=filter.sort sortSep=':'}}
   ```
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    sort: '',
    sortSep: ',',

    actions: {
      sort: function sort() {
        var sort = this.get('sort');
        var sortSep = this.get('sortSep');
        var value = this.get('sortBy');
        if (sort.indexOf(value) === -1) {
          this.set('sort', value);
        } else if (sort.indexOf(sortSep) === -1) {
          this.set('sort', '' + value + sortSep + 'desc');
        } else {
          this.set('sort', value);
        }
      }
    }
  });
});