define('edibroker/controllers/application', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({

    showAbortModal: false,

    environment: _ember['default'].computed(function () {
      return 'enums.Environment.' + _edibrokerConfigEnvironment['default'].environment;
    })

  });
});