define('edibroker/validators/multi-format', ['exports', 'ember-cp-validations/validators/format'], function (exports, _emberCpValidationsValidatorsFormat) {

  var MultiFormat = _emberCpValidationsValidatorsFormat['default'].extend({

    validate: function validate(value, options, model, attribute) {
      var _this = this;

      if (value) {
        var errors = value.split(options.get('split')).map(function (val) {
          return _this._super(val, options, model, attribute);
        }).filter(function (error) {
          return typeof error === 'string';
        });
        return errors.length ? errors[0] : true;
      }
      return this._super(value, options, model, attribute);
    }
  });

  MultiFormat.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = MultiFormat;
});