define('edibroker/routes/language/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    currentUser: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        processed: this.fileModel(),
        sent: this.fileModel('sent'),
        received: this.fileModel('received'),
        stats: { received: 0, sent: 0 },
        alerts: this.get('store').query('info', { activeOnly: true })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.filterFiles();
        controller.fetchStats();
      });
    },

    resetController: function resetController(controller) {
      controller.set('rendered', false);
      controller.set('loading', {});
    },

    fileModel: function fileModel(option) {
      return {
        files: [],
        filter: {
          option: option,
          sort: 'lastEventDate,desc',
          page: 0,
          size: this.get('currentUser.preferences.pageSize') || 20
        }
      };
    }
  });
});