define('edibroker/controllers/download', ['exports', 'ember', 'edibroker/services/constants', 'edibroker/config/environment'], function (exports, _ember, _edibrokerServicesConstants, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),

    downloadInformation: _ember['default'].computed('model.subType', function () {
      return this.get('intl').t('download.' + this.get('model.subType'));
    }),

    downloaded: false,

    errorMessageKey: _ember['default'].computed('errorMessage', function () {
      var _this = this;

      return (0, _edibrokerServicesConstants.getErrorKey)(this.get('errorMessage'), function (value) {
        return _this.get('intl').exists(value);
      });
    }),

    actions: {
      download: function download() {
        var type = _ember['default'].String.pluralize(this.get('model.subType'));
        var queryParams = {};
        queryParams['token'] = this.get('model.value');
        queryParams = _ember['default'].$.param(queryParams);
        this.set('downloaded', true);
        window.open(_edibrokerConfigEnvironment['default'].APP.proxy + '/api/' + type + '/public/download?' + queryParams, '_blank');
      }
    }
  });
});