define('edibroker/components/download-link', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {

  /**
    Display a download link.
  
    Example:
    ```hbs
    {{download-link 'members' model}}
    ```
   */
  var DownloadLinkComponent = _ember['default'].Component.extend({
    tagName: '',
    subtype: undefined,
    showLabel: false,
    reloadModel: false,
    currentUser: _ember['default'].inject.service(),

    getUrl: function getUrl(type, subtype, id) {
      var url = _edibrokerConfigEnvironment['default'].APP.proxy + '/api/' + type + '/download/' + id;
      if (subtype) {
        url = url + '/' + subtype;
      }
      return url + ('?' + this.getQueryParams());
    },

    getQueryParams: function getQueryParams() {
      var queryParams = {};
      queryParams['authorization'] = this.get('currentUser').token();
      queryParams = _ember['default'].$.param(queryParams);
      return queryParams;
    },

    actions: {
      download: function download() {
        var _this = this;

        var type = this.get('type');
        var subtype = this.get('subtype');
        var id = this.get('document.id');
        window.open(this.getUrl(type, subtype, id), '_blank');
        if (this.get('reloadModel')) {
          _ember['default'].run.debounce(function () {
            return _this.get('document').reload();
          }, 1000);
        }
      }
    }

  });

  DownloadLinkComponent.reopenClass({
    positionalParams: ['type', 'document', 'title']
  });

  exports['default'] = DownloadLinkComponent;
});