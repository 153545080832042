define('edibroker/components/member/message-edit', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {

  /**
   * Form component that can be used to display and edit a message model. Message type field is
   * disabled unless the message has the <code>isNew</code> flag set Input
   * fields are rendered as a table row.
   *
   * ```hbs
   * {{member/message-edit message definedTypes=model.msgTypes isModel=isModelPage
                                          defaultAddress=model.address defaultSftp=model.sftpConf
                                          editSftp=(action 'editSftp' message)
                                          confirmDelete='confirmDelete'}}
   * ```
   *
   *
   *
   * @param definedTypes
   *          {Array} Array of the currently used message type. Used to compute available types and updated upon message
   *          creation or deletion
   * @param isModel
   *          {Boolean} Flag controlling if fields not present in model messages should be hidden or not
   * @param defaultAddress
   *          {String} Anomaly address to be displayed when message doesn't define one
   * @param defaultSftp
   *          {String} Sftp configuration to be displayed when message doesn't define one
   * @param editSftp
   *          {String} name of the action to be triggered when the SFTP edit button is clicked
   * @param confirmDelete
   *          {String} name of the action handling the message deletion confirmation. A callback is provided to the
   *          handler and must be executed to tigger the actuel deletion of the message.
   */
  var MessageEdit = _ember['default'].Component.extend({

    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    tagName: 'tr',

    didValidate: false,
    isProceeding: false,

    error: 'error',

    isEditMode: _ember['default'].computed.or('model.isNew', 'isEdit').readOnly(),

    definedTypes: [],
    availableTypes: _ember['default'].computed('definedTypes.[]', function () {
      var content = _edibrokerServicesConstants.MESSAGE_TYPES.slice(0);
      var defined = this.get('definedTypes');
      if (defined) {
        content.removeObjects(defined);
      }
      content.unshift('');
      return content;
    }),

    outFormats: _ember['default'].computed('model.type', function () {
      var formats = _edibrokerServicesConstants.MESSAGE_FORMATS.slice(0);
      formats.removeObject('ANY');
      if (this.get('model.type') === '811') {
        formats.removeObject('FLAT');
      }
      return formats;
    }),

    inFormats: _ember['default'].computed('model.type', function () {
      var formats = _edibrokerServicesConstants.MESSAGE_FORMATS.slice(0);
      if (this.get('model.type') === '811') {
        formats.removeObject('FLAT');
        formats.removeObject('ANY');
      }
      return formats;
    }),

    inVersions: _ember['default'].computed('model.type', function () {
      var type = this.get('model.type');
      if (type) {
        var versions = _edibrokerServicesConstants.VERSIONS_BY_MESSAGE[type];
        if (versions) {
          versions = versions.slice(0);
          if (versions.length > 1) {
            versions.pushObject('ANY');
          }
          this.clearCurrentVersion('inVersion', versions);
          return versions;
        }
      }
      return _edibrokerServicesConstants.MESSAGE_VERSIONS.slice(0);
    }),

    outVersions: _ember['default'].computed('inVersions', function () {
      var versions = this.get('inVersions').slice(0);
      versions.removeObject('ANY');
      this.clearCurrentVersion('outVersion', versions);
      return versions;
    }),

    clearCurrentVersion: function clearCurrentVersion(field, versions) {
      var current = this.get('model.' + field);
      if (current && _ember['default'].$.inArray(current, versions) === -1) {
        this.set('model.' + field, undefined);
      }
    },

    types: _ember['default'].computed('model.isNew', 'availableTypes', function () {
      return this.get('model.isNew') ? this.get('availableTypes') : _edibrokerServicesConstants.MESSAGE_TYPES;
    }),

    actions: {
      edit: function edit() {
        this.set('isEdit', true);
      },
      validate: function validate() {
        var _this = this;

        this.set('didValidate', true);
        var message = this.get('model');
        var create = message.get('isNew');
        message.validate().then(function (result) {
          if (_ember['default'].get(result, 'validations.isValid')) {
            _this.set('isProceeding', true);
            message.save().then(function () {
              _this.set('isProceeding', false);
              _this.set('isEdit', false);
              if (create) {
                _this.get('toast').success(_this.get('intl').t('member.messages.create-success'));
                _this.get('definedTypes').pushObject(_this.get('model.type'));
              } else {
                _this.get('toast').success(_this.get('intl').t('member.messages.edit-success'));
              }
            }, function (error) {
              _this.set('isProceeding', false);
              _this.sendAction('error', error);
            });
          } else {
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      },
      cancel: function cancel() {
        var message = this.get('model');
        if (message.get('isNew')) {
          this.sendAction('cancel');
        } else {
          this.set('isEdit', false);
        }
        message.rollbackAttributes();
      },
      confirmDelete: function confirmDelete() {
        var _this2 = this;

        this.sendAction('confirmDelete', function (callback) {
          _this2.send('deleteMsg', callback);
        });
      },
      deleteMsg: function deleteMsg(callback) {
        var _this3 = this;

        var msgType = this.get('model.type');
        this.get('model').destroyRecord().then(function () {
          callback();
          _this3.get('toast').success(_this3.get('intl').t('member.messages.delete-success'));
          _this3.get('definedTypes').removeObject(msgType);
        }, function (error) {
          callback();
          _this3.sendAction('error', error);
        });
      },
      editSftp: function editSftp() {
        this.sendAction('editSftp');
      }
    }

  });

  MessageEdit.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = MessageEdit;
});