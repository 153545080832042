define('edibroker/models/model', ['exports', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _emberData, _emberCpValidations, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;

  var Validations = (0, _emberCpValidations.buildValidations)({

    name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 250 }), (0, _emberCpValidations.validator)('unique-model-name', { debounce: 250 })]

  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {

    name: attr('string'),
    messages: hasMany('modelMessage'),
    msgTypes: attr(),

    shortName: _ember['default'].computed('name', function () {
      var name = this.get('name');
      return name.length > 40 ? name.substring(0, 40) + ' ...' : name;
    })

  });
});