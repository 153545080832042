define('edibroker/components/inputs/v-select-td', ['exports', 'edibroker/components/inputs/v-select'], function (exports, _edibrokerComponentsInputsVSelect) {

  /**
   * Validated select that can be used inside a table. Validation errors will be
   * displayed as input title when required
   */
  var ValidatedSelectTd = _edibrokerComponentsInputsVSelect['default'].extend({
    tagName: 'td'
  });

  ValidatedSelectTd.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedSelectTd;
});