define('edibroker/models/document', ['exports', 'ember-data', 'ember-cp-validations', 'edibroker/services/constants'], function (exports, _emberData, _emberCpValidations, _edibrokerServicesConstants) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('unique-document-name')],
    description: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    attachmentName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('file-extension', { 'extensions': _edibrokerServicesConstants.DOCUMENT_EXTENSIONS, 'message': 'error.validations.invalidDocumentExtension' })],
    attachmentSize: (0, _emberCpValidations.validator)('number', { lte: 10000000, allowString: true, messageKey: 'error.back.illegal.file-size' })
  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {
    name: attr('string'),
    description: attr('string'),
    attachmentName: attr('string'),
    attachmentMimeType: attr('string'),
    attachmentSize: attr('number')
  });
});