define('edibroker/routes/language/administration/message/detail/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    templateName: 'language/administration/message/create',
    controllerName: 'language/administration/message/create',
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isEditionPage', true);
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('loading', false);
        controller.set('isEditionPage', false);
        controller.set('didValidate', false);
      }
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.administration.message.detail');
      },

      success: function success() {
        this.get('toast').success(this.get('intl').t('message.edit-success'));
        this.transitionTo('language.administration.message.detail');
      }
    }
  });
});