define('edibroker/routes/language/administration/message/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    model: function model() {
      return this.store.createRecord('info');
    },

    resetController: function resetController(controller) {
      controller.set('loading', false);
      controller.set('didValidate', false);
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('language.administration.message');
      },

      success: function success() {
        this.get('toast').success(this.get('intl').t('message.create-success'));
        this.transitionTo('language.administration.message.detail', this.get('controller.model'));
      }
    }
  });
});