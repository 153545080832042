define('edibroker/components/pagination-size', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {

  /**
    Display a action bar containing the number of elements displayed and a select input to change the number of elements
    displayed on a single page.
  
    Example:
    ```hbs
    {{pagination-size filter=filter model=members}}
    ```
   */
  exports['default'] = _ember['default'].Component.extend({
    availableSizes: _edibrokerServicesConstants.PAGE_SIZES,

    firstElement: _ember['default'].computed('model.meta.{page,size}', function () {
      var page = this.get('model.meta.page'),
          size = this.get('model.meta.size');

      return page * size + 1;
    }),

    lastElement: _ember['default'].computed('model.meta.{page,size,total}', function () {
      var page = this.get('model.meta.page'),
          size = this.get('model.meta.size'),
          total = this.get('model.meta.total'),
          tmp = (page + 1) * size;

      return tmp > total ? total : tmp;
    }),

    totalElement: _ember['default'].computed.alias('model.meta.total'),

    hasResult: _ember['default'].computed('totalElement', function () {
      return this.get('totalElement') > 0;
    })
  });
});