define('edibroker/controllers/language/administration/model/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    rendered: false,
    loading: true,
    filter: _ember['default'].computed.alias('model.filter'),
    models: _ember['default'].computed.alias('model.models'),

    availableMessages: _edibrokerServicesConstants.MESSAGE_TYPES,

    filterChange: _ember['default'].observer('filter.{messagesIn,sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterModels();
      }
    }),

    nameChange: _ember['default'].observer('filter.nameStartsWith', function () {
      _ember['default'].run.debounce(this, 'filterModels', 250);
    }),

    filterModels: function filterModels() {
      var _this = this;

      this.store.query('model', this.get('filter')).then(function (models) {
        if (!models.get('length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('models', models);
        _this.set('loading', false);
      });
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      sort: function sort(value) {
        var sort = this.get('filter.sort');
        if (sort.indexOf(value) === -1) {
          this.set('filter.sort', value);
        } else if (sort.indexOf(',') === -1) {
          this.set('filter.sort', value + ',desc');
        } else {
          this.set('filter.sort', value);
        }
      }
    }
  });
});