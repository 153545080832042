define("edibroker/mirage/fixtures/users", ["exports"], function (exports) {
  exports["default"] = [{
    "id": 0,
    "user": 0,
    "login": "root",
    "civility": "MR",
    "firstName": "Equens",
    "lastName": "Worldline",
    "email": "dl-fr-netproassur@worldline.com",
    "locked": false,
    "preferences": {},
    "memberIds": [],
    "permissions": ["ACCESS@statistic", "MEMBER@read", "FILE@download", "DOCUMENT@create", "USER@create", "MEMBER@create", "USER@profile", "USER@updatePreference", "MESSAGE@update", "FILE@read", "USER@resetPassword", "MEMBER@update", "USER@delete", "MEMBER@delete", "FILE@retry", "USER@update", "USER@read", "CERTIFICATE@create", "MESSAGE@create", "ACCESS@dashboard", "USER@lock", "USER@updateSubcription", "MESSAGE@read", "CERTIFICATE@delete", "CERTIFICATE@read", "FILE@favorite", "FILE@unarchive", "USER@updatePersonal", "DOCUMENT@delete", "FILE@upload", "CERTIFICATE@update", "DOCUMENT@update", "MESSAGE@delete", "DOCUMENT@read", "MODEL@read", "MODEL@create", "MODEL@update", "MODEL@delete"],
    "userProfile": "NETPROASSUR"
  }, {
    "id": 1,
    "user": 1,
    "login": "special",
    "civility": "MR",
    "firstName": "Special",
    "lastName": "User",
    "email": "special@no-reply.com",
    "locked": false,
    "preferences": {},
    "memberIds": [0],
    "permissions": ["ACCESS@statistic", "FILE@download", "USER@updateSubcription", "ACCESS@dashboard", "USER@profile", "MESSAGE@read", "USER@updatePreference", "FILE@read", "FILE@favorite", "USER@resetPassword", "FILE@unarchive", "USER@updatePersonal", "FILE@upload", "DOCUMENT@read"],
    "userProfile": "MEMBER_SPECIAL_USER"
  }, {
    "id": 2,
    "user": 2,
    "login": "admin",
    "civility": "MR",
    "firstName": "Admin",
    "lastName": "User",
    "email": "admin@no-reply.com",
    "locked": false,
    "preferences": {},
    "memberIds": [1, 0],
    "permissions": ["ACCESS@statistic", "MEMBER@profile", "FILE@download", "USER@create", "USER@profile", "USER@updatePreference", "FILE@read", "USER@resetPassword", "MEMBER@update", "USER@delete", "USER@update", "USER@read", "CERTIFICATE@create", "ACCESS@dashboard", "USER@lock", "USER@updateSubcription", "MESSAGE@read", "CERTIFICATE@delete", "CERTIFICATE@read", "FILE@favorite", "FILE@unarchive", "USER@updatePersonal", "FILE@upload", "CERTIFICATE@update", "DOCUMENT@read"],
    "userProfile": "MEMBER_ADMIN"
  }, {
    "id": 3,
    "user": 3,
    "login": "basic",
    "civility": "MR",
    "firstName": "Basic",
    "lastName": "User",
    "email": "basic@no-reply.com",
    "locked": false,
    "preferences": {},
    "memberIds": [1, 2],
    "permissions": ["FILE@favorite", "USER@resetPassword", "USER@updatePersonal", "USER@updateSubcription", "ACCESS@dashboard", "USER@profile", "MESSAGE@read", "USER@updatePreference", "DOCUMENT@read", "FILE@read"],
    "userProfile": "MEMBER_BASIC_USER"
  }];
});