define('edibroker/components/inputs/s-select', ['exports', 'ember'], function (exports, _ember) {

  /**
    Select components
  
    ```hbs
    {{inputs/s-select content=elements action=(action (mut selected))}}
    ```
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    inputSequence: _ember['default'].inject.service(),

    content: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    action: _ember['default'].K,

    name: _ember['default'].computed.reads('id'),
    'class': _ember['default'].computed.reads('id'),
    disabled: false,

    _selection: _ember['default'].computed.reads('selection'),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
      if (!this.get('id')) {
        this.set('id', 'select-' + this.get('inputSequence').next());
      }
    },

    actions: {
      change: function change() {
        var selectedIndex = _ember['default'].$('#' + this.get('id'))[0].selectedIndex,
            content = this.get('content'),
            selection = content[selectedIndex],
            changeCallback = this.get('action');

        this.set('_selection', selection);
        changeCallback(selection);
      }
    }
  });
});