define('edibroker/mirage/fixtures/model-messages', ['exports'], function (exports) {
  exports['default'] = [{
    id: 0,
    type: '201',
    inVersion: 'V01',
    inFormat: 'XML',
    outVersion: 'V01',
    outFormat: 'FLAT'
  }, {
    id: 1,
    type: '202',
    inVersion: 'V01',
    inFormat: 'XML',
    outVersion: 'BB',
    outFormat: 'FLAT'
  }];
});