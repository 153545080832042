define('edibroker/routes/language/administration/user/detail', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model(params) {
      return this.store.findRecord('user', params.id);
    },
    afterModel: function afterModel(model, transition) {
      var isCurrentUser = model.get('id') === this.get('currentUser').get('user.id').toString();
      if (isCurrentUser) {
        this.transitionTo(transition.targetName.replace("administration.user.detail", "profile"));
      }
    }

  });
});