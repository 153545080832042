define('edibroker/validators/different', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var Different = _emberCpValidationsValidatorsBase['default'].extend({
    intl: _ember['default'].inject.service(),

    validate: function validate(value, options, model, attribute) {
      var on = _ember['default'].get(options, 'on');

      _ember['default'].assert('[validator:confirmation] [' + attribute + '] option \'on\' is required', _ember['default'].isPresent(on));

      if (_ember['default'].isEqual(value, _ember['default'].get(model, on))) {
        var messageKey = _ember['default'].get(options, 'messageKey') || 'error.validations.different';
        return this.get('intl').t(messageKey);
      }

      return true;
    }
  });

  Different.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = Different;
});