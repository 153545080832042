define('edibroker/components/user/preference-form', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {

  /**
    Display a the user preference form.
  
    Example:
    ```hbs
    {{user/preference-form model}}
    ```
   */
  var PreferenceForm = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    inputSequence: _ember['default'].inject.service(),

    tagName: 'form',
    classNameBindings: ['horizontal:form-horizontal'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('langId', 'lang-' + this.get('inputSequence').next());
      this.set('menuId', 'menu-' + this.get('inputSequence').next());
      this.set('sizeId', 'size-' + this.get('inputSequence').next());
      this._addObservers();
    },

    preferences: _ember['default'].computed.alias('model.preferences'),
    horizontal: _ember['default'].computed('mode', function () {
      return this.get('mode') === 'form-horizontal';
    }),

    availableSizes: _edibrokerServicesConstants.PAGE_SIZES,

    availableLanguages: _ember['default'].computed('intl.locales.[]', 'intl.currentLanguage', function () {
      var _this = this;

      _ember['default'].Logger.debug('Recomputing available languages as language is now ' + this.get('intl.currentLanguage'));
      return this.get('intl.locales').map(function (locale) {
        var lang = locale.indexOf('-') !== -1 ? locale.split('-')[0] : locale;
        return {
          value: lang,
          label: _this.get('intl').t('language.' + lang)
        };
      });
    }),

    availableMenuStates: _ember['default'].computed('intl.currentLanguage', function () {
      var _this2 = this;

      _ember['default'].Logger.debug('Recomputing available menu states as language is now ' + this.get('intl.currentLanguage'));
      return _edibrokerServicesConstants.MENU_STATES.map(function (state) {
        return {
          value: state,
          label: _this2.get('intl').t('user.settings.menu-' + state)
        };
      });
    }),

    selectedSize: _ember['default'].computed('preferences.pageSize', {
      get: function get() {
        var value = _ember['default'].get(this, 'preferences.pageSize');
        if (!value) {
          value = 20;
        }
        return value;
      },
      set: function set(key, value) {
        _ember['default'].set(this, 'preferences.pageSize', value);
        return value;
      }
    }),

    selectedState: _ember['default'].computed('preferences.menuState', 'intl.currentLanguage', {
      get: function get() {
        var value = _ember['default'].get(this, 'preferences.menuState');
        if (!value) {
          value = 'collapsed';
        }
        return {
          value: value,
          label: this.get('intl').t('user.settings.menu-' + value)
        };
      },
      set: function set(key, value) {
        _ember['default'].set(this, 'preferences.menuState', value.value);
        return value;
      }
    }),

    selectedLang: _ember['default'].computed('preferences.language', 'intl.currentLanguage', {
      get: function get() {
        var value = _ember['default'].get(this, 'preferences.language');
        if (!value) {
          value = this.get('intl.currentLanguage');
        }
        return {
          value: value,
          label: this.get('intl').t('language.' + value)
        };
      },
      set: function set(key, value) {
        _ember['default'].set(this, 'preferences.language', value.value);
        return value;
      }
    }),

    _addObservers: function _addObservers() {
      var _this3 = this;

      _edibrokerServicesConstants.USER_PREFERENCES.forEach(function (key) {
        _this3.addObserver('preferences.' + key, _this3, function () {
          if (this.get('preferences.' + key) !== undefined) {
            this._preferenceUpdate(key);
          }
        });
      });
    },

    _preferenceUpdate: function _preferenceUpdate(key) {
      (0, _edibrokerServicesConstants.updatePreference)(this.get('model.id'), key, this.get('preferences.' + key));
    }
  });

  PreferenceForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = PreferenceForm;
});