define("edibroker/templates/components/inputs/s-select", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "edibroker/templates/components/inputs/s-select.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'value');
          morphs[1] = dom.createAttrMorph(element0, 'selected');
          morphs[2] = dom.createMorphAt(element0, 1, 1);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["subexpr", "read-path", [["get", "item", ["loc", [null, [3, 35], [3, 39]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [3, 40], [3, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 23], [3, 57]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "is-equal", [["get", "item", ["loc", [null, [3, 79], [3, 83]]], 0, 0, 0, 0], ["get", "selection", ["loc", [null, [3, 84], [3, 93]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [3, 94], [3, 109]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [3, 111]]], 0, 0], 0, 0, 0, 0], ["inline", "read-path", [["get", "item", ["loc", [null, [4, 24], [4, 28]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [4, 29], [4, 44]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 12], [4, 46]]], 0, 0]],
        locals: ["item"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/components/inputs/s-select.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("select");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'id');
        morphs[1] = dom.createAttrMorph(element1, 'name');
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createAttrMorph(element1, 'disabled');
        morphs[4] = dom.createAttrMorph(element1, 'title');
        morphs[5] = dom.createElementMorph(element1);
        morphs[6] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "id", ["concat", [["get", "id", ["loc", [null, [1, 46], [1, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["concat", [["get", "name", ["loc", [null, [1, 60], [1, 64]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["get", "class", ["loc", [null, [1, 77], [1, 82]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 97], [1, 105]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "title", ["get", "title", ["loc", [null, [1, 116], [1, 121]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["change"], ["on", "change"], ["loc", [null, [1, 8], [1, 39]]], 0, 0], ["block", "each", [["get", "content", ["loc", [null, [2, 12], [2, 19]]], 0, 0, 0, 0]], ["key", "@identity"], 0, null, ["loc", [null, [2, 4], [6, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});