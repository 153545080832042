define("edibroker/templates/components/member/message-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 32
              },
              "end": {
                "line": 17,
                "column": 32
              }
            },
            "moduleName": "edibroker/templates/components/member/message-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "scope", "col");
            dom.setAttribute(el1, "rowspan", "2");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["member.model.address"], [], ["loc", [null, [16, 62], [16, 90]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 32
              },
              "end": {
                "line": 29,
                "column": 32
              }
            },
            "moduleName": "edibroker/templates/components/member/message-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "scope", "col");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["member.model.sftp"], [], ["loc", [null, [28, 52], [28, 77]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 32
                },
                "end": {
                  "line": 39,
                  "column": 32
                }
              },
              "moduleName": "edibroker/templates/components/member/message-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "member/message-edit", [["get", "message", ["loc", [null, [35, 58], [35, 65]]], 0, 0, 0, 0]], ["definedTypes", ["subexpr", "@mut", [["get", "model.msgTypes", ["loc", [null, [35, 79], [35, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "isModel", ["subexpr", "@mut", [["get", "isModelPage", ["loc", [null, [35, 102], [35, 113]]], 0, 0, 0, 0]], [], [], 0, 0], "defaultAddress", ["subexpr", "@mut", [["get", "model.address", ["loc", [null, [36, 55], [36, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "defaultSftp", ["subexpr", "@mut", [["get", "model.sftpConf", ["loc", [null, [36, 81], [36, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "editSftp", ["subexpr", "action", ["editSftp", ["get", "message", ["loc", [null, [37, 68], [37, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [37, 49], [37, 76]]], 0, 0], "confirmDelete", "confirmDelete"], ["loc", [null, [35, 36], [38, 71]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 28
              },
              "end": {
                "line": 40,
                "column": 28
              }
            },
            "moduleName": "edibroker/templates/components/member/message-list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["get", "message.isNew", ["loc", [null, [34, 42], [34, 55]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 32], [39, 43]]]]],
          locals: ["message"],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 28
              },
              "end": {
                "line": 43,
                "column": 28
              }
            },
            "moduleName": "edibroker/templates/components/member/message-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "member/message-edit", [["get", "newMsg", ["loc", [null, [42, 54], [42, 60]]], 0, 0, 0, 0]], ["definedTypes", ["subexpr", "@mut", [["get", "model.msgTypes", ["loc", [null, [42, 74], [42, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "isModel", ["subexpr", "@mut", [["get", "isModelPage", ["loc", [null, [42, 97], [42, 108]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 32], [42, 110]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 50,
              "column": 0
            }
          },
          "moduleName": "edibroker/templates/components/member/message-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-lg-12");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "box box-primary");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "box-header");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("h3");
          dom.setAttribute(el5, "class", "box-title");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "box-body");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("table");
          dom.setAttribute(el5, "class", "table table-bordered table-hover");
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("caption");
          dom.setAttribute(el6, "class", "sr-only");
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("thead");
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "col");
          dom.setAttribute(el8, "rowspan", "2");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "colgroup");
          dom.setAttribute(el8, "colspan", "2");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "colgroup");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("td");
          dom.setAttribute(el8, "class", "action");
          dom.setAttribute(el8, "rowspan", "2");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                            ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("tr");
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "col");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "col");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "col");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                                ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("th");
          dom.setAttribute(el8, "scope", "col");
          var el9 = dom.createComment("");
          dom.appendChild(el8, el9);
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("                            ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                        ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("tbody");
          var el7 = dom.createTextNode("\n");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("                        ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3, 1]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element4, [7]);
          var element6 = dom.childAt(element3, [3]);
          var element7 = dom.childAt(element2, [5]);
          var morphs = new Array(15);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(element4, 3, 3);
          morphs[5] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
          morphs[6] = dom.createAttrMorph(element5, 'colspan');
          morphs[7] = dom.createMorphAt(element5, 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
          morphs[9] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
          morphs[10] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
          morphs[11] = dom.createMorphAt(dom.childAt(element6, [7]), 0, 0);
          morphs[12] = dom.createMorphAt(element6, 9, 9);
          morphs[13] = dom.createMorphAt(element7, 1, 1);
          morphs[14] = dom.createMorphAt(element7, 2, 2);
          return morphs;
        },
        statements: [["inline", "member/message-actions", [["get", "model", ["loc", [null, [6, 45], [6, 50]]], 0, 0, 0, 0]], ["isModel", ["subexpr", "@mut", [["get", "isModelPage", ["loc", [null, [6, 59], [6, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 20], [6, 72]]], 0, 0], ["inline", "t", ["member.messages.title"], [], ["loc", [null, [7, 42], [7, 71]]], 0, 0], ["inline", "t", ["member.messages.title"], [], ["loc", [null, [11, 49], [11, 78]]], 0, 0], ["inline", "t", ["member.messages.type"], [], ["loc", [null, [14, 58], [14, 86]]], 0, 0], ["block", "unless", [["get", "isModelPage", ["loc", [null, [15, 42], [15, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 32], [17, 43]]]], ["inline", "t", ["member.messages.receive"], [], ["loc", [null, [18, 63], [18, 94]]], 0, 0], ["attribute", "colspan", ["subexpr", "unless", [["get", "isModelPage", ["loc", [null, [19, 70], [19, 81]]], 0, 0, 0, 0], 3, 2], [], ["loc", [null, [null, null], [19, 87]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["member.messages.send"], [], ["loc", [null, [19, 88], [19, 116]]], 0, 0], ["inline", "t", ["member.messages.format"], [], ["loc", [null, [23, 48], [23, 78]]], 0, 0], ["inline", "t", ["member.messages.version"], [], ["loc", [null, [24, 48], [24, 79]]], 0, 0], ["inline", "t", ["member.messages.format"], [], ["loc", [null, [25, 48], [25, 78]]], 0, 0], ["inline", "t", ["member.messages.version"], [], ["loc", [null, [26, 48], [26, 79]]], 0, 0], ["block", "unless", [["get", "isModelPage", ["loc", [null, [27, 42], [27, 53]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [27, 32], [29, 43]]]], ["block", "each", [["get", "sortedMessages", ["loc", [null, [33, 36], [33, 50]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [33, 28], [40, 37]]]], ["block", "if", [["get", "newMsg", ["loc", [null, [41, 34], [41, 40]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [41, 28], [43, 35]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "edibroker/templates/components/member/message-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "member/sftp-form", [["get", "sftpMessage", ["loc", [null, [51, 23], [51, 34]]], 0, 0, 0, 0]], ["success", "successSftp", "cancel", "cancelSftp", "title", ["subexpr", "@mut", [["get", "msgSftpTitle", ["loc", [null, [51, 83], [51, 95]]], 0, 0, 0, 0]], [], [], 0, 0], "modelType", "messages"], ["loc", [null, [51, 4], [51, 118]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 54,
              "column": 0
            },
            "end": {
              "line": 56,
              "column": 0
            }
          },
          "moduleName": "edibroker/templates/components/member/message-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["member.messages.delete-confirm"], [], ["loc", [null, [55, 7], [55, 45]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/components/member/message-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "showSftp", ["loc", [null, [1, 10], [1, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [52, 11]]]], ["block", "confirm-dialog", [], ["id", "deleteMsg", "proceed", "deleteMsg", "useSpinner", true, "isDelete", true, "isVisible", ["subexpr", "@mut", [["get", "showDeleteModal", ["loc", [null, [54, 94], [54, 109]]], 0, 0, 0, 0]], [], [], 0, 0], "title", ["subexpr", "t", ["member.messages.delete"], [], ["loc", [null, [54, 116], [54, 144]]], 0, 0]], 2, null, ["loc", [null, [54, 0], [56, 19]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});