define('edibroker/components/inputs/v-document', ['exports', 'edibroker/components/inputs/v-input', 'ember'], function (exports, _edibrokerComponentsInputsVInput, _ember) {

  /**
   * Validated file upload button
   */

  var ValidatedFile = _edibrokerComponentsInputsVInput['default'].extend({

    intl: _ember['default'].inject.service(),
    method: 'POST',

    filesArray: _ember['default'].A([]),

    empty: true,

    fileName: _ember['default'].computed('value', function () {
      var fileName = this.get('value');
      return fileName ? fileName : this.get('intl').t('common.file');
    }),

    actions: {
      updateDocumentName: function updateDocumentName(files) {
        if (_ember['default'].isEmpty(files)) {
          this.set('value', undefined);
          this.set('sizeValue', undefined);
          this.get('filesArray').clear();
          this.set('empty', true);
        } else {

          this.get('filesArray').clear();

          var array = Array.from(files);
          var filename = array[0].name;
          this.get('filesArray').pushObject(filename);
          this.set('value', filename);
          this.set('sizeValue', array[0].size);
          this.set('empty', false);
        }
      }
    }
  });

  ValidatedFile.reopenClass({
    positionalParams: ['model', 'attribute', 'sizeAttribute']
  });

  exports['default'] = ValidatedFile;
});