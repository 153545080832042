define('edibroker/mirage/config', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  exports['default'] = function () {
    var _this = this;

    // Authentication API
    this.post('/api/auth/login', function (schema, request) {
      var params = JSON.parse(request.requestBody);

      switch (params.username) {
        case 'root':
          // User with NETPROASSUR profile
          return new _emberCliMirage['default'].Response(200, {}, { "token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJyb290IiwiaXNzIjoiRXF1ZW5zIFdvcmxkbGluZSIsInVzci1kYXRhIjp7ImlkIjowLCJ1c2VyIjowLCJsb2dpbiI6InJvb3QiLCJmaXJzdE5hbWUiOiJFcXVlbnMiLCJsYXN0TmFtZSI6IldvcmxkbGluZSIsImVtYWlsIjoiZGwtZnItbmV0cHJvYXNzdXJAd29ybGRsaW5lLmNvbSIsInByZWZlcmVuY2VzIjp7fSwibWVtYmVycyI6W10sInBlcm1pc3Npb25zIjpbIkFDQ0VTU0BzdGF0aXN0aWMiLCJNRU1CRVJAcmVhZCIsIkZJTEVAZG93bmxvYWQiLCJET0NVTUVOVEBjcmVhdGUiLCJVU0VSQGNyZWF0ZSIsIk1FTUJFUkBjcmVhdGUiLCJVU0VSQHByb2ZpbGUiLCJVU0VSQHVwZGF0ZVByZWZlcmVuY2UiLCJNRVNTQUdFQHVwZGF0ZSIsIkZJTEVAcmVhZCIsIlVTRVJAcmVzZXRQYXNzd29yZCIsIk1FTUJFUkB1cGRhdGUiLCJVU0VSQGRlbGV0ZSIsIk1FTUJFUkBkZWxldGUiLCJGSUxFQHJldHJ5IiwiVVNFUkB1cGRhdGUiLCJVU0VSQHJlYWQiLCJDRVJUSUZJQ0FURUBjcmVhdGUiLCJNRVNTQUdFQGNyZWF0ZSIsIkFDQ0VTU0BkYXNoYm9hcmQiLCJVU0VSQGxvY2siLCJVU0VSQHVwZGF0ZVN1YmNyaXB0aW9uIiwiTUVTU0FHRUByZWFkIiwiQ0VSVElGSUNBVEVAZGVsZXRlIiwiQ0VSVElGSUNBVEVAcmVhZCIsIkZJTEVAZmF2b3JpdGUiLCJGSUxFQHVuYXJjaGl2ZSIsIlVTRVJAdXBkYXRlUGVyc29uYWwiLCJET0NVTUVOVEBkZWxldGUiLCJGSUxFQHVwbG9hZCIsIkNFUlRJRklDQVRFQHVwZGF0ZSIsIkRPQ1VNRU5UQHVwZGF0ZSIsIk1FU1NBR0VAZGVsZXRlIiwiRE9DVU1FTlRAcmVhZCIsIk1PREVMQHJlYWQiLCJNT0RFTEBjcmVhdGUiLCJNT0RFTEB1cGRhdGUiLCJNT0RFTEBkZWxldGUiXSwidXNlclByb2ZpbGUiOiJORVRQUk9BU1NVUiJ9fQ.joK08lTp32Rv39IsOIwkOyy3T1jImsUDQsJLe6fLrYc" });
        case 'admin':
          // User with MEMBER_ADMIN profile
          return new _emberCliMirage['default'].Response(200, {}, { "token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbiIsImlzcyI6IkVxdWVucyBXb3JsZGxpbmUiLCJ1c3ItZGF0YSI6eyJpZCI6MiwidXNlciI6MiwibG9naW4iOiJhZG1pbiIsImZpcnN0TmFtZSI6IkFkbWluIiwibGFzdE5hbWUiOiJVc2VyIiwiZW1haWwiOiJhZG1pbkBuby1yZXBseS5jb20iLCJwcmVmZXJlbmNlcyI6e30sIm1lbWJlcnMiOlsxLDBdLCJwZXJtaXNzaW9ucyI6WyJBQ0NFU1NAc3RhdGlzdGljIiwiTUVNQkVSQHByb2ZpbGUiLCJGSUxFQGRvd25sb2FkIiwiVVNFUkBjcmVhdGUiLCJVU0VSQHByb2ZpbGUiLCJVU0VSQHVwZGF0ZVByZWZlcmVuY2UiLCJGSUxFQHJlYWQiLCJVU0VSQHJlc2V0UGFzc3dvcmQiLCJNRU1CRVJAdXBkYXRlIiwiVVNFUkBkZWxldGUiLCJVU0VSQHVwZGF0ZSIsIlVTRVJAcmVhZCIsIkNFUlRJRklDQVRFQGNyZWF0ZSIsIkFDQ0VTU0BkYXNoYm9hcmQiLCJVU0VSQGxvY2siLCJVU0VSQHVwZGF0ZVN1YmNyaXB0aW9uIiwiTUVTU0FHRUByZWFkIiwiQ0VSVElGSUNBVEVAZGVsZXRlIiwiQ0VSVElGSUNBVEVAcmVhZCIsIkZJTEVAZmF2b3JpdGUiLCJGSUxFQHVuYXJjaGl2ZSIsIlVTRVJAdXBkYXRlUGVyc29uYWwiLCJGSUxFQHVwbG9hZCIsIkNFUlRJRklDQVRFQHVwZGF0ZSIsIkRPQ1VNRU5UQHJlYWQiLCJNT0RFTEByZWFkIl0sInVzZXJQcm9maWxlIjoiTUVNQkVSX0FETUlOIn19.L6PSK5kL_1kBP5EIDa98aK1lJY10Or_5U2FC4wTUWsQ" });
        case 'special':
          // User with MEMBER_SPECIAL_USER profile
          return new _emberCliMirage['default'].Response(200, {}, { "token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzcGVjaWFsIiwiaXNzIjoiRXF1ZW5zIFdvcmxkbGluZSIsInVzci1kYXRhIjp7ImlkIjoxLCJ1c2VyIjoxLCJsb2dpbiI6InNwZWNpYWwiLCJmaXJzdE5hbWUiOiJTcGVjaWFsIiwibGFzdE5hbWUiOiJVc2VyIiwiZW1haWwiOiJzcGVjaWFsQG5vLXJlcGx5LmNvbSIsInByZWZlcmVuY2VzIjp7fSwibWVtYmVycyI6WzBdLCJwZXJtaXNzaW9ucyI6WyJBQ0NFU1NAc3RhdGlzdGljIiwiRklMRUBkb3dubG9hZCIsIlVTRVJAdXBkYXRlU3ViY3JpcHRpb24iLCJBQ0NFU1NAZGFzaGJvYXJkIiwiVVNFUkBwcm9maWxlIiwiTUVTU0FHRUByZWFkIiwiVVNFUkB1cGRhdGVQcmVmZXJlbmNlIiwiRklMRUByZWFkIiwiRklMRUBmYXZvcml0ZSIsIlVTRVJAcmVzZXRQYXNzd29yZCIsIkZJTEVAdW5hcmNoaXZlIiwiVVNFUkB1cGRhdGVQZXJzb25hbCIsIkZJTEVAdXBsb2FkIiwiRE9DVU1FTlRAcmVhZCJdLCJ1c2VyUHJvZmlsZSI6Ik1FTUJFUl9TUEVDSUFMX1VTRVIifX0.AAcm9Bn33vMSWVddDcD-iRrRBMziRg-2xLBW5miathw" });
        case 'basic':
          // User with MEMBER_BASIC_USER profile
          return new _emberCliMirage['default'].Response(200, {}, { "token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiYXNpYyIsImlzcyI6IkVxdWVucyBXb3JsZGxpbmUiLCJ1c3ItZGF0YSI6eyJpZCI6MywidXNlciI6MywibG9naW4iOiJiYXNpYyIsImZpcnN0TmFtZSI6IkJhc2ljIiwibGFzdE5hbWUiOiJVc2VyIiwiZW1haWwiOiJiYXNpY0Buby1yZXBseS5jb20iLCJwcmVmZXJlbmNlcyI6e30sIm1lbWJlcnMiOlsxLDJdLCJwZXJtaXNzaW9ucyI6WyJGSUxFQGZhdm9yaXRlIiwiVVNFUkByZXNldFBhc3N3b3JkIiwiVVNFUkB1cGRhdGVQZXJzb25hbCIsIlVTRVJAdXBkYXRlU3ViY3JpcHRpb24iLCJBQ0NFU1NAZGFzaGJvYXJkIiwiVVNFUkBwcm9maWxlIiwiTUVTU0FHRUByZWFkIiwiVVNFUkB1cGRhdGVQcmVmZXJlbmNlIiwiRE9DVU1FTlRAcmVhZCIsIkZJTEVAcmVhZCJdLCJ1c2VyUHJvZmlsZSI6Ik1FTUJFUl9CQVNJQ19VU0VSIn19.QrDr0_NUOPhOmcUKhqFJQjgK3b0IopR6W2Tpz0TXHmw" });
        case 'locked':
          // Locked account
          return new _emberCliMirage['default'].Response(403, {}, { "message": "key:error.exception.LockedException" });
        case 'badcredentials':
          // Locked account
          return new _emberCliMirage['default'].Response(403, {}, { "message": "key:error.exception.BadCredentialsException" });
        case 'disabled':
          // Locked account
          return new _emberCliMirage['default'].Response(403, {}, { "message": "key:error.exception.DisabledException" });
        default:
          return new _emberCliMirage['default'].Response(403, {}, { "message": "key:error.exception.AuthenticationException" });
      }
    });
    this.post('/api/auth/refresh', function (schema, request) {
      return new _emberCliMirage['default'].Response(200, {}, JSON.parse(request.requestBody));
    });

    // Users API
    this.get('/api/users');
    this.post('/api/users');
    this.get('/api/users/:id');
    this.put('/api/users/:id');
    this.put('/api/users/:id/status', function (_ref, request) {
      var users = _ref.users;

      var id = request.params.id;
      var found = users.find(id);
      if (found) {
        var attrs = _this.normalizedRequestAttrs();
        var locked = attrs.status === 'LOCKED';
        users.update(id, { locked: locked });
        return new _emberCliMirage['default'].Response(204, {}, {});
      } else {
        return new _emberCliMirage['default'].Response(404, {}, "No user for id " + id);
      }
    });
    this.del('/api/users/:id');

    this.get('/api/users/public', function (_ref2, request) {
      var users = _ref2.users;

      var email = request.queryParams.email;
      if (email) {
        var found = users.where({ email: email }).models[0];
        if (found) {
          return found;
        } else {
          return new _emberCliMirage['default'].Response(404, {}, "No user for email " + email);
        }
      } else {
        return new _emberCliMirage['default'].Response(400, {}, "Missing parameter email");
      }
    });
    this.post('/api/users/public/password', function () {
      return new _emberCliMirage['default'].Response(200, {}, "Password set");
    });

    // Members API
    this.get('/api/members', function (_ref3, request) {
      var members = _ref3.members;
      var users = _ref3.users;

      var code = request.queryParams.code;
      if (code) {
        var member = members.where({ code: code }).models[0];
        return member ? member : new _emberCliMirage['default'].Response(404, {}, "Not found");
      }
      var userId = request.queryParams.user;
      if (userId) {
        return users.find(userId).members;
      }
      return members.all();
    });
    this.post('/api/members');
    this.get('/api/members/:id');
    this.put('/api/members/:id');
    this.del('/api/members/:id');
    this.put('/api/members/:id/model/:mid', function (schema, request) {
      var _request$params = request.params;
      var id = _request$params.id;
      var mid = _request$params.mid;

      var member = schema.members.find(id);
      var model = schema.models.find(mid);
      if (member && model) {
        var _ret = (function () {
          var msgIds = [];
          model.messages.models.forEach(function (msg) {
            var m = schema.db.messages.insert({ type: msg.attrs.type });
            msgIds.push(m.id);
          });
          member.update({ messageIds: msgIds });
          return {
            v: new _emberCliMirage['default'].Response(204, {}, {})
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        return new _emberCliMirage['default'].Response(404, {}, "Not found");
      }
    });

    // Messages API
    this.get('/api/messages/:id');
    this.post('/api/messages');
    this.put('/api/messages/:id');
    this.del('/api/messages/:id');

    // Models API
    this.get('/api/models', function (_ref4, request) {
      var models = _ref4.models;

      var name = request.queryParams.name;
      if (name) {
        var _ret2 = (function () {
          var result = models.where({ name: name });
          var id = request.queryParams.idsNotIn;
          return {
            v: id ? result.filter(function (model) {
              return model.id !== id;
            }) : result
          };
        })();

        if (typeof _ret2 === 'object') return _ret2.v;
      } else {
        return models.all();
      }
    });
    this.post('/api/models');
    this.get('/api/models/:id');
    this.put('/api/models/:id');
    this.del('/api/models/:id');

    // ModelMessages API
    this.post('/api/modelmessages', 'modelMessage');
    this.get('/api/modelmessages/:id', 'modelMessage');
    this.put('/api/modelmessages/:id', 'modelMessage');
    this.del('/api/modelmessages/:id', 'modelMessage');

    // Certificate API
    this.get('/api/certificates');
    this.post('/api/certificates');
    this.get('/api/certificates/:id');
    this.put('/api/certificates/:id');
    this.post('/api/certificates/:id/renew', 'certificate');
    this.del('/api/certificates/:id');

    // Document API
    this.get('/api/documents');
    this.post('/api/documents');
    this.get('/api/documents/:id');
    this.put('/api/documents/:id');
    this.del('/api/documents/:id');

    // File API
    this.get('/api/files');
    this.get('/api/files/stats/dashboard', function () {
      return new _emberCliMirage['default'].Response(200, {}, {});
    });

    // Info api
    this.get('/api/infos');

    /*
     * Shorthand cheatsheet:
     *
     * this.get('/posts');
     * this.post('/posts');
     * this.get('/posts/:id');
     * this.put('/posts/:id');
     * this.del('/posts/:id');
     *
     * http://www.ember-cli-mirage.com/docs/v0.2.x/shorthands/
     */
  };
});