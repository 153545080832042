define('edibroker/routes/language/document/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    model: function model() {
      return this.store.createRecord('document');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isCreatePage', true);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('loading', false);
        controller.set('didValidate', false);
        controller.set('editAttachment', false);
      }
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.document');
      },

      save: function save() {
        var _this = this;

        this.get('controller.model').save().then(function () {
          _this.get('toast').success(_this.get('intl').t('document.create-success'));
          _this.transitionTo('language.document.detail', _this.get('controller.model'));
        }, function (error) {
          _this.set('controller.loading', false);
          _this.send('error', error);
        });
      }
    }
  });
});