define('edibroker/components/member/message-actions', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {

  /**
   * Component controlling actions buttons for a member or model messages list.
   * Display a 'Add' button and an 'Import' button
   *
   *
   */
  var MessageActions = _ember['default'].Component.extend({
    classNames: ["box-tools", "pull-right"],

    store: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    modelNames: [],
    selectedModel: undefined,

    showImportForm: false,
    showImportModal: false,
    isImporting: false,
    isLoadingModels: false,
    confirmImport: _ember['default'].computed.notEmpty('model.messages').readOnly(),
    msgType: _ember['default'].computed('isModel', function () {
      return this.get('isModel') ? 'modelMessage' : 'message';
    }),

    isCreating: _ember['default'].computed('model.messages.@each.isNew', function () {
      return this.get('model.messages').filterBy('isNew').length > 0;
    }),

    isAllDefined: _ember['default'].computed('model.messages.[]', function () {
      return this.get('model.messages.length') === _edibrokerServicesConstants.MESSAGE_TYPES.length;
    }),

    preventNewMessage: _ember['default'].computed.or('isCreating', 'isAllDefined'),

    error: 'error',

    actions: {
      add: function add() {
        this.get('model.messages').pushObject(this.get('store').createRecord(this.get('msgType')));
      },
      toggleImport: function toggleImport() {
        var _this = this;

        this.set('isLoadingModels', true);
        this.get('store').findAll('model').then(function (models) {
          var names = models.map(function (model) {
            return {
              label: model.get('shortName'),
              value: model.get('id')
            };
          });
          names.unshift('');
          _this.set('modelNames', names);
          _this.set('showImportForm', true);
          _this.set('isLoadingModels', false);
        }, function (error) {
          _this.send('error', error);
          _this.set('isLoadingModels', false);
        });
      },
      submitImport: function submitImport() {
        if (!this.get('selectedModel')) {
          this.get('toast').error(this.get('intl').t('member.import.no-model-selected'));
        } else if (!_ember['default'].isEmpty(this.get('model.messages'))) {
          this.set('showImportModal', true);
        } else {
          this.send('doImport');
        }
      },
      doImport: function doImport(callback) {
        var _this2 = this;

        var complete = function complete() {
          if (callback) {
            callback.resolve();
          }
          _this2.set('isImporting', false);
          _this2.get('model').reload();
        };
        this.get('model.messages').then(function (messages) {
          messages.filter(function (msg) {
            return !!msg;
          }).forEach(function (msg) {
            return msg.unloadRecord();
          });
          _this2.set('model.messages', []);
          _this2.set('isImporting', true);
          _ember['default'].$.ajax({
            url: '/api/members/' + _this2.get('model.id') + '/model/' + _this2.get('selectedModel.value'),
            method: 'PUT'
          }).always(function () {
            return _ember['default'].run(complete);
          }).then(function () {
            return _ember['default'].run(function () {
              _this2.get('toast').success(_this2.get('intl').t('member.import.success'));
              _this2.set('showImportForm', false);
            });
          }, function (error) {
            return _ember['default'].run(function () {
              return _this2.sendAction('error', error.responseJSON);
            });
          });
        });
      },
      cancelImport: function cancelImport() {
        this.set('showImportForm', false);
      }
    }
  });

  MessageActions.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = MessageActions;
});