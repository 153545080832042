define('edibroker/components/optional-display', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Component that optionally display a value or a placeholder if missing
   */
  var OptionalDisplay = _ember['default'].Component.extend({

    tagName: '',
    isKo: false,
    missingClass: _ember['default'].computed('isKo', function () {
      return this.get('isKo') ? 'text-danger' : 'text-muted';
    }),
    displayAs: undefined,
    displayValue: _ember['default'].computed('displayAs', function () {
      var displayAs = this.get('displayAs');
      return displayAs ? displayAs : this.get('field');
    })

  });

  OptionalDisplay.reopenClass({
    positionalParams: ['field']
  });

  exports['default'] = OptionalDisplay;
});