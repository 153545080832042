define('edibroker/app', ['exports', 'ember', 'edibroker/resolver', 'ember-load-initializers', 'edibroker/config/environment'], function (exports, _ember, _edibrokerResolver, _emberLoadInitializers, _edibrokerConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _edibrokerConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _edibrokerConfigEnvironment['default'].podModulePrefix,
    Resolver: _edibrokerResolver['default'],

    ready: function ready() {
      var _this = this;

      _ember['default'].$.ajaxPrefilter(function (options, originalOptions, request) {
        var session = _this.__container__.lookup('service:session'),
            token = session.get('data.authenticated.token');

        if (token !== undefined) {
          request.setRequestHeader("X-Auth-Token", 'Bearer ' + token);
        }
      });
    }
  });

  (0, _emberLoadInitializers['default'])(App, _edibrokerConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});