define('edibroker/routes/language/administration/model/detail/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('language.administration.model.detail').set('isEditPage', false);
    },

    actions: {
      confirmDelete: function confirmDelete() {
        this.set('controller.showDeleteModal', true);
      },
      proceedDelete: function proceedDelete(defer) {
        var _this = this;

        this.get('controller.model').destroyRecord().then(function () {
          defer.resolve();
          _this.get('toast').success(_this.get('intl').t('model.delete-success'));
          _this.transitionTo('language.administration.model');
        }, function (error) {
          defer.resolve();
          _this.send('error', error);
        });
      }
    }
  });
});