define('edibroker/routes/language/administration/memberprofile/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    redirect: function redirect(model /*, transition*/) {
      if (_ember['default'].get(model, 'members.length') === 1) {
        this.transitionTo('language.administration.memberprofile.detail', _ember['default'].get(model, 'members.firstObject'));
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
      });
    },

    resetController: function resetController(controller) {
      controller.set('rendered', false);
    }

  });
});