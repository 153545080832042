define('edibroker/controllers/lost', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    username: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    errorMessageKey: _ember['default'].computed('errorMessage', function () {
      var errorMessage = this.get('errorMessage');
      var key = null;
      if (errorMessage) {
        if (errorMessage.indexOf('key:') === 0) {
          key = errorMessage.replace('key:', '');
          if (!this.get('intl').exists(key)) {
            _ember['default'].Logger.warn('Error key could not be found: ', key);
            key = 'error.exception.InternalServerErrorClientException';
          }
        } else {
          key = 'error.exception.InternalServerErrorClientException';
        }
      }
      return key;
    }),

    actions: {
      validation: function validation() {
        var _this = this;

        if (this.get('validations.isValid')) {
          _ember['default'].$.ajax({
            url: '/api/users/public/lost',
            method: 'POST',
            data: {
              username: this.get('username')
            }
          }).then(function () {
            _ember['default'].Logger.info('Password set');
            _this.get('toast').success(_this.get('intl').t('authentication.passwordLost-success'));
            _this.transitionToRoute('index');
          }).fail(function (response) {
            _ember['default'].Logger.error('Error while setting password', response);
            var message = _ember['default'].get(response, 'responseJSON.errors.message') || '';
            _this.set('errorMessage', message);
          });
        } else {
          this.set('errorMessage', 'key:error.back.missing.username');
        }
      }
    }
  });
});