define('edibroker/routes/language/administration/user/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),
    queryParams: {
      member: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var model = {
        filter: this._emptyFilter(),
        users: []
      };
      if (params.member) {
        model.filter.memberIdsIn = [params.member];
      }
      return _ember['default'].RSVP.hash(model);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var memberId = _ember['default'].get(model, 'filter.memberIdsIn.firstObject');
      if (memberId) {
        this.store.find('member', memberId).then(function (member) {
          return controller.get('selectedMembers').pushObject(member);
        });
      }
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.filterUsers();
      });
    },

    resetController: function resetController(controller) {
      controller.set('rendered', false);
      controller.set('loading', true);
      controller.set('selectedMembers', []);
      controller.set('member', null);
    },

    _emptyFilter: function _emptyFilter() {
      return {
        lastNameStartsWith: '',
        firstNameStartsWith: '',
        emailStartsWith: '',
        memberIdsIn: [],
        civilityIn: [],
        sort: '',
        page: 0,
        size: this.get('currentUser.preferences.pageSize') || 20
      };
    },

    actions: {
      reset: function reset() {
        this.set('controller.filter', this._emptyFilter());
      }
    }
  });
});