define('edibroker/components/sort-icon', ['exports', 'ember'], function (exports, _ember) {

  /**
    Display an icon indicating the sorting order of a grid.
  
    Example:
    ```hbs
    {{sort-icon propertyValue='code' sort=filter.sort}}
    ```
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNameBindings: [':fa', 'indicatorBoth:fa-sort', 'indicatorAsc:fa-sort-asc', 'indicatorDesc:fa-sort-desc'],

    propertyValue: '',
    sort: '',
    sortSep: ',',
    propertyName: _ember['default'].computed('sort', function () {
      var sort = this.get('sort');
      var sortSep = this.get('sortSep');
      if (sort.indexOf(sortSep) !== -1) {
        return sort.split(sortSep)[0];
      } else {
        return sort;
      }
    }),
    propertyDirection: _ember['default'].computed('sort', function () {
      var sort = this.get('sort');
      var sortSep = this.get('sortSep');
      if (sort.indexOf(sortSep) !== -1) {
        return sort.split(sortSep)[1];
      } else {
        return 'asc';
      }
    }),

    indicatorAsc: _ember['default'].computed('propertyName', 'propertyValue', 'propertyDirection', function () {
      return this.get('propertyName') === this.get('propertyValue') && this.get('propertyDirection') === 'asc';
    }),

    indicatorDesc: _ember['default'].computed('propertyName', 'propertyValue', 'propertyDirection', function () {
      return this.get('propertyName') === this.get('propertyValue') && this.get('propertyDirection') === 'desc';
    }),

    indicatorBoth: _ember['default'].computed('propertyName', 'propertyValue', function () {
      return this.get('propertyName') !== this.get('propertyValue');
    })
  });
});