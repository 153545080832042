define('edibroker/components/inputs/v-select-td-noenum', ['exports', 'ember', 'edibroker/components/inputs/v-input'], function (exports, _ember, _edibrokerComponentsInputsVInput) {

  /**
   * Validated selected to be used inside a table. Values should be provided from
   * the elements parameter. Validation errors will be displayed as input title
   * when required
   */
  var ValidatedSelectNoEnum = _edibrokerComponentsInputsVInput['default'].extend({

    tagName: 'td',

    selected: _ember['default'].computed.alias('value')

  });

  ValidatedSelectNoEnum.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedSelectNoEnum;
});