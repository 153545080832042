define('edibroker/components/member/member-list', ['exports', 'ember'], function (exports, _ember) {

  var MemberList = _ember['default'].Component.extend({
    classNames: ['row'],
    isProfile: false,
    sort: '',
    sortSep: ',',

    memberLink: _ember['default'].computed('isProfile', function () {
      return this.get('isProfile') ? 'language.administration.memberprofile.detail' : 'language.administration.member.detail';
    }),

    actions: {
      sort: function sort(value) {
        var sort = this.get('sort');
        var sortSep = this.get('sortSep');
        if (sort.indexOf(value) === -1) {
          this.set('sort', value);
        } else if (sort.indexOf(sortSep) === -1) {
          this.set('sort', '' + value + sortSep + 'desc');
        } else {
          this.set('sort', value);
        }
      }
    }
  });

  MemberList.reopenClass({
    positionalParams: ['members']
  });

  exports['default'] = MemberList;
});