define('edibroker/router', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _edibrokerConfigEnvironment['default'].locationType,
    rootURL: _edibrokerConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('download');
    this.route('login');
    this.route('lost');
    this.route('password');
    this.route('language', {
      path: '/:lang'
    }, function () {
      this.route('dashboard');
      this.route('profile', function () {
        this.route('edit');
        this.route('password');
      });
      this.route('file', function () {
        this.route('detail', {
          path: '/:id'
        });
        this.route('upload');
      });
      this.route('stats');
      this.route('document', function () {
        this.route('create');
        this.route('detail', {
          path: '/:id'
        }, function () {
          this.route('edit');
        });
      });
      this.route('administration', function () {
        this.route('member', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          }, function () {
            this.route('edit');
            this.route('editsftp');
          });
        });
        this.route('memberprofile', function () {
          this.route('detail', {
            path: '/:code'
          }, function () {
            this.route('edit');
            this.route('editsftp');
          });
        });
        this.route('user', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          }, function () {
            this.route('edit');
          });
        });
        this.route('model', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          }, function () {
            this.route('edit');
          });
        });
        this.route('accesstoken', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          });
        });
        this.route('certificate', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          });
        });
        this.route('message', function () {
          this.route('create');
          this.route('detail', {
            path: '/:id'
          }, function () {
            this.route('edit');
          });
        });
        this.route('configuration');
        this.route('reference', function () {
          this.route('upload');
        });
        this.route('dynamicfield', function () {
          this.route('upload');
        });
      });
    });
  });

  exports['default'] = Router;
});