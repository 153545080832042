define('edibroker/services/intl', ['exports', 'ember-intl/services/intl', 'moment'], function (exports, _emberIntlServicesIntl, _moment) {

  /**
   * Service used to set the intl service locale given a language.
   */
  exports['default'] = _emberIntlServicesIntl['default'].extend({
    availableLocale: {
      'fr': 'fr-fr',
      'en': 'en-us'
    },
    currentLanguage: 'fr',

    load: function load(language) {
      var lang = language;
      if (lang.indexOf('-') !== -1) {
        lang = lang.split('-')[0];
      }

      var locale = this.get('availableLocale')[lang] || 'fr-fr';
      this.set('currentLanguage', locale.split('-')[0]);
      this.setLocale(locale);
    },

    formatDateWithMoment: function formatDateWithMoment(date) {
      var format = this.t('common.date-format');
      return this.formatWithMoment(date, format);
    },

    formatDateTimeWithMoment: function formatDateTimeWithMoment(date) {
      var format = this.t('common.date-time-format');
      return this.formatWithMoment(date, format);
    },

    formatWithMoment: function formatWithMoment(date, format) {
      var lang = this.get('currentLanguage');
      return (0, _moment['default'])(date).locale(lang).format(format);
    }
  });
});