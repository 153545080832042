define('edibroker/components/inputs/p-select', ['exports', 'ember', 'edibroker/components/inputs/v-input'], function (exports, _ember, _edibrokerComponentsInputsVInput) {

  /**
    Power select input for auto complete selection.
  
    ```hbs
    {{#inputs/p-select filter 'codesIn' 'code' label=(t 'member.model.code-label') search=(action 'search') as |member|}}
      {{member.label}} ({{member.code}})
    {{/inputs/p-select}}
    ```
   */
  var PowerSelectComponent = _edibrokerComponentsInputsVInput['default'].extend({
    isMultiple: true,
    labelClass: "col-sm-2",
    inputClass: "col-sm-4",

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isMultiple')) {
        _ember['default'].defineProperty(this, 'multiValue', _ember['default'].computed.alias('selected'));
      } else {
        _ember['default'].defineProperty(this, 'singleValue', _ember['default'].computed.alias('selected'));
      }
    },

    multiValueChange: _ember['default'].observer('multiValue.[]', function () {
      var _this = this;

      var selected = this.get('multiValue');
      if (selected) {
        (function () {
          var sub = _this.get('sub');
          if (sub) {
            _this.set('value', selected.map(function (s) {
              return s.get(sub);
            }));
          } else {
            _this.set('value', selected);
          }
        })();
      }
    }),

    singleValueChange: _ember['default'].observer('singleValue', function () {
      var selected = this.get('singleValue');
      if (selected) {
        var sub = this.get('sub');
        if (sub) {
          this.set('value', selected.get(sub));
        } else {
          this.set('value', selected);
        }
      }
    }),

    actions: {
      search: function search(term) {
        return this.get('search')(term);
      },
      checkLength: function checkLength(text, select) {
        var limit = this.getWithDefault('limit', 3);
        if (select.searchText.length >= limit && text.length < limit) {
          return '';
        } else {
          return text.length >= limit;
        }
      }
    }
  });

  PowerSelectComponent.reopenClass({
    positionalParams: ['model', 'attribute', 'sub']
  });

  exports['default'] = PowerSelectComponent;
});