define('edibroker/mirage/serializers/application', ['exports', 'ember-cli-mirage', 'ember-cli-mirage/utils/inflector'], function (exports, _emberCliMirage, _emberCliMirageUtilsInflector) {
  exports['default'] = _emberCliMirage.RestSerializer.extend({
    serializeIds: 'always',
    keyForRelationshipIds: function keyForRelationshipIds(relationShip) {
      return relationShip;
    },
    normalize: function normalize(payload) {
      var type = Object.keys(payload)[0];
      var attrs = payload[type];
      var _registry$schema$_registry$type$class$prototype = this.registry.schema._registry[type]['class'].prototype;
      var belongsToAssociations = _registry$schema$_registry$type$class$prototype.belongsToAssociations;
      var hasManyAssociations = _registry$schema$_registry$type$class$prototype.hasManyAssociations;

      var jsonApiPayload = {
        data: {
          type: (0, _emberCliMirageUtilsInflector.pluralize)(type)
        }
      };

      Object.keys(attrs).forEach(function (key) {
        if (key === 'id') {
          // records id
          jsonApiPayload.data.id = attrs.id;
        } else if (belongsToAssociations.hasOwnProperty(key) || hasManyAssociations.hasOwnProperty(key)) {
          (function () {
            // relationship
            if (!jsonApiPayload.data.hasOwnProperty('relationships')) {
              jsonApiPayload.data.relationships = {};
            }

            var association = belongsToAssociations.hasOwnProperty(key) ? belongsToAssociations[key] : hasManyAssociations[key];
            var associationType = belongsToAssociations.hasOwnProperty(key) ? 'belongsTo' : 'hasMany';
            var associationModel = association.modelName;
            var relationshipObject = {};

            switch (associationType) {
              case 'belongsTo':
                relationshipObject.data = {
                  type: associationModel,
                  id: attrs[key]
                };
                break;
              case 'hasMany':
                relationshipObject.data = [];
                attrs[key].forEach(function (value) {
                  relationshipObject.data.push({
                    type: associationModel,
                    id: value
                  });
                });
                break;
            }

            jsonApiPayload.data.relationships[key] = relationshipObject;
          })();
        } else {
          // attribute
          if (!jsonApiPayload.data.hasOwnProperty('attributes')) {
            jsonApiPayload.data.attributes = {};
          }

          jsonApiPayload.data.attributes[(0, _emberCliMirageUtilsInflector.dasherize)(key)] = attrs[key];
        }
      });

      return jsonApiPayload;
    }
  });
});