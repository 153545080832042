define('edibroker/routes/language/administration/memberprofile', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model() {
      var filter = this.newFilter();
      return _ember['default'].RSVP.hash({
        filter: filter,
        members: this.store.query('member', filter)
      });
    },

    newFilter: function newFilter() {
      return {
        user: this.get('currentUser.user.id'),
        sort: '',
        page: 0,
        size: this.get('currentUser.preferences.pageSize') || 20
      };
    }
  });
});