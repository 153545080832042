define('edibroker/components/collapse-button', ['exports', 'ember'], function (exports, _ember) {

  /**
    Display a collapse button. This button should only be use in a box environnement.
  
    Example:
    ```hbs
    {{collapse-button}}
    ```
   */
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-box-tool'],
    attributeBindings: 'title',
    intl: _ember['default'].inject.service(),

    title: _ember['default'].computed('collapsed', function () {
      if (this.get('collapsed')) {
        return this.get('intl').t('common.show');
      } else {
        return this.get('intl').t('common.hide');
      }
    }),

    click: function click() {
      var parent = this.$().parents('.box').first();
      var content = parent.find('> .box-body, > .box-footer, > form  >.box-body, > form > .box-footer');
      this.toggleProperty('collapsed');
      if (!parent.hasClass("collapsed-box")) {
        content.slideUp(500, function () {
          parent.addClass("collapsed-box");
        });
      } else {
        content.slideDown(500, function () {
          parent.removeClass("collapsed-box");
        });
      }
    }
  });
});