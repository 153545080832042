define('edibroker/controllers/language/administration/user/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    rendered: false,
    loading: true,
    showDeleteModal: false,
    filter: _ember['default'].computed.alias('model.filter'),
    users: _ember['default'].computed.alias('model.users'),
    availableCivility: _edibrokerServicesConstants.CIVILITY,

    selectedMembers: [],
    queryParams: ['member'],
    member: null,

    filterChange: _ember['default'].observer('filter.{memberIdsIn.[],civilityIn.[],sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        _ember['default'].run.once(this, 'filterUsers');
      }
    }),

    textFieldChange: _ember['default'].observer('filter.{lastNameStartsWith,firstNameStartsWith,emailStartsWith', function () {
      _ember['default'].run.debounce(this, 'filterUsers', 250);
    }),

    filterUsers: function filterUsers() {
      var _this = this;

      this.store.query('user', this.get('filter')).then(function (users) {
        if (!_ember['default'].get(users, 'length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('users', users);
        _this.set('loading', false);
      })['catch'](function (errors) {
        _this.send('error', errors);
      });
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      sort: function sort(value) {
        var sort = this.get('filter.sort');
        if (sort.indexOf(value) === -1) {
          this.set('filter.sort', value);
        } else if (sort.indexOf(',') === -1) {
          this.set('filter.sort', value + ',desc');
        } else {
          this.set('filter.sort', value);
        }
      },

      error: function error() {
        this.set('loading', false);
        return true;
      },

      reset: function reset() {
        this.set('selectedMembers', []);
        this.set('member', null);
        return true;
      },

      toggleLock: function toggleLock(user, doLock) {
        var _this2 = this;

        var toggleStatus = doLock ? 'LOCKED' : 'UNLOCKED';
        var message = doLock ? 'user.lock-success' : 'user.unlock-success';
        (0, _edibrokerServicesConstants.updateUserStatus)(user.get('id'), toggleStatus, function () {
          _this2.get('toast').success(_this2.get('intl').t(message));
          user.reload();
        }, function (response) {
          _ember['default'].Logger.error('Error while toggling user lock ' + user.get('id') + ' ', response);
          _this2.send('error', response.responseJSON);
        });
      },

      askDelete: function askDelete(user) {
        this.set('userToDelete', user);
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        var user = this.get('userToDelete');
        this.set('userToDelete', null);
        user.destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('user.delete-success'));
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      }
    }
  });
});