define('edibroker/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'edibroker/services/constants'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this.get('intl').load(navigator.language || navigator.userLanguage);
      return this._loadSession();
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this._loadSession();
      this._super();
    },

    _loadSession: function _loadSession() {
      var transition = arguments.length <= 0 || arguments[0] === undefined ? _ember['default'].K : arguments[0];

      return this.get('currentUser').load(transition);
    },

    actions: {
      willTransition: function willTransition(transition) {
        var target = _ember['default'].get(transition, 'targetName');
        var currentRoute = this.get('controller.currentRouteName');
        var currentParams = _ember['default'].getOwner(this).lookup('router:main').router.state.params;
        var currentRouteParams = currentParams[currentRoute];
        if (Object.keys(currentRouteParams).length === 0) {
          // Check parent route params when current has none unless parent is language
          var parentRoute = currentRoute.replace(/(\.[^\.]+)\.[^\.]+$/, '$1');
          currentRouteParams = currentParams[parentRoute];
        }
        var route = _ember['default'].getOwner(this).lookup('route:language.index');

        if (target === 'language.index') {
          route.set('history', currentRoute);
          route.set('historyParams', currentRouteParams);
        } else {
          route.set('history', null);
          route.set('historyParams', null);
        }

        var status = (0, _edibrokerServicesConstants.getOrCreateStatus)(transition);
        var currentModel = this.controllerFor(currentRoute).get('model');
        if (currentModel && _ember['default'].get(currentModel, 'hasDirtyAttributes')) {
          status.shouldConfirm = true;
          status.resetCallbacks.push(function () {
            return currentModel.rollbackAttributes();
          });
        }

        if (status.shouldConfirm) {
          this.set('abortedTransition', transition);
          this.set('controller.showAbortModal', true);
          transition.abort();
        }
      },

      abortEdit: function abortEdit() {
        var transition = this.get('abortedTransition');
        var status = (0, _edibrokerServicesConstants.getOrCreateStatus)(transition);
        status.resetCallbacks.forEach(function (callback) {
          return callback();
        });
        status.shouldConfirm = false;
        transition.retry();
      },

      error: function error(err) {
        _ember['default'].Logger.debug("An error occurs: ", err);
        var error = _ember['default'].isArray(err.errors) ? err.errors[0] : err.errors;
        var message = this.get('intl').t('error.back.generic');
        if (error.message && error.message.indexOf('key:') === 0) {
          if (error.cause && error.cause.indexOf('key:') === 0) {
            message = this.get('intl').t(error.cause.replace('key:', ''));
          } else {
            message = this.get('intl').t(error.message.replace('key:', ''));
          }
        }
        this.get('toast').error(message);
        if (error.status) {
          var _status = parseInt(error.status);
          if (_status === 401) {
            this.get('currentUser').logout();
          }
          if (_status === 403) {
            this.transitionTo('index');
          }
        }
      }
    }
  });
});