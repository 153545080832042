define('edibroker/routes/language/profile/password', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('language.administration.user.detail').set('isPasswordPage', true);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('didValidate', false);
        this.controllerFor('language.administration.user.detail').set('isPasswordPage', false);
      }
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('language.profile');
      }
    }
  });
});