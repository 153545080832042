define('edibroker/components/inputs/period-picker', ['exports', 'ember-cli-daterangepicker/components/date-range-picker', 'ember', 'moment'], function (exports, _emberCliDaterangepickerComponentsDateRangePicker, _ember, _moment) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _emberCliDaterangepickerComponentsDateRangePicker['default'].extend({
    intl: _ember['default'].inject.service(),

    classNameBindings: ['showSuccess:has-success', 'showError:has-error'],

    autoApply: true,
    alwaysShowCalendars: true,
    firstDay: 1,
    labelClass: 'col-sm-3',
    inputClass: 'col-sm-7',
    label: 'common.date-range-picker.label',
    timePicker24Hour: true,
    applyClass: 'btn-primary',
    format: _ember['default'].computed('intl.currentLanguage', 'timePicker', function () {
      _ember['default'].Logger.debug('Computing date format for language ' + this.get('intl.currentLanguage'));
      return this.get('intl').t('common.date' + (this.get('timePicker') ? '-time' : '') + '-range-picker.format');
    }),
    serverFormat: _ember['default'].computed('timePicker', function () {
      return this.get('timePicker') ? 'YYYY-MM-DDTHH:mm:00' : 'YYYY-MM-DD';
    }),

    localeChanged: _ember['default'].observer('intl.currentLanguage', function () {
      this.setupPicker();
    }),

    daysOfWeek: _ember['default'].computed('intl.currentLanguage', function () {
      return _moment['default'].localeData(this.get('intl.currentLanguage')).weekdaysMin();
    }),
    monthNames: _ember['default'].computed('intl.currentLanguage', function () {
      return _moment['default'].localeData(this.get('intl.currentLanguage')).monthsShort();
    }),

    ranges: _ember['default'].computed('intl.currentLanguage', function () {
      var _ref;

      _ember['default'].Logger.debug('Computing date ranges for language ' + this.get('intl.currentLanguage'));
      var intl = this.get('intl');
      return _ref = {}, _defineProperty(_ref, intl.t('common.period.today'), [this.getStartOfRange('day'), this.getEndOfRange('day')]), _defineProperty(_ref, intl.t('common.period.yesterday'), [this.getStartOfRange('day', 'days', 1), this.getEndOfRange('day', 'days', 1)]), _defineProperty(_ref, intl.t('common.period.seven-days'), [this.getStartOfRange('day', 'days', 6), this.getEndOfRange('day')]), _defineProperty(_ref, intl.t('common.period.this-week'), [this.getStartOfRange('week'), this.getEndOfRange('week')]), _defineProperty(_ref, intl.t('common.period.last-week'), [this.getStartOfRange('week', 'weeks', 1), this.getEndOfRange('week', 'weeks', 1)]), _defineProperty(_ref, intl.t('common.period.thirty-days'), [this.getStartOfRange('day', 'days', 29), (0, _moment['default'])().endOf('day')]), _defineProperty(_ref, intl.t('common.period.this-month'), [this.getStartOfRange('month'), this.getEndOfRange('month')]), _defineProperty(_ref, intl.t('common.period.last-month'), [this.getStartOfRange('month', 'months', 1), this.getEndOfRange('month', 'months', 1)]), _defineProperty(_ref, intl.t('common.period.six-months'), [this.getStartOfRange('day', 'months', 6), this.getEndOfRange('day')]), _defineProperty(_ref, intl.t('common.period.twelve-months'), [this.getStartOfRange('day', 'months', 12), this.getEndOfRange('day')]), _defineProperty(_ref, intl.t('common.period.this-year'), [this.getStartOfRange('year'), this.getEndOfRange('year')]), _defineProperty(_ref, intl.t('common.period.last-year'), [this.getStartOfRange('year', 'years', 1), this.getEndOfRange('year', 'years', 1)]), _ref;
    }),

    getStartOfRange: function getStartOfRange(period, periods, value) {
      if (!_ember['default'].isBlank(periods) || !_ember['default'].isBlank(value)) {
        return (0, _moment['default'])().subtract(value, periods).startOf(period);
      } else {
        return (0, _moment['default'])().startOf(period);
      }
    },

    getEndOfRange: function getEndOfRange(period, periods, value) {
      if (!_ember['default'].isBlank(periods) || !_ember['default'].isBlank(value)) {
        return (0, _moment['default'])().subtract(value, periods).endOf(period);
      } else {
        return (0, _moment['default'])().endOf(period);
      }
    },
    customRangeLabel: _ember['default'].computed('intl.currentLanguage', function () {
      _ember['default'].Logger.debug('Computing custom date range label for language ' + this.get('intl.currentLanguage'));
      return this.get('intl').t('common.period.custom');
    }),
    applyLabel: _ember['default'].computed('intl.currentLanguage', function () {
      _ember['default'].Logger.debug('Computing custom apply label for language ' + this.get('intl.currentLanguage'));
      return this.get('intl').t('common.submit');
    }),
    cancelLabel: _ember['default'].computed('intl.currentLanguage', function () {
      _ember['default'].Logger.debug('Computing custom cancel label for language ' + this.get('intl.currentLanguage'));
      return this.get('intl').t('common.cancel');
    })

  });
});