define('edibroker/controllers/login', ['exports', 'ember', 'edibroker/services/constants', 'edibroker/config/environment'], function (exports, _ember, _edibrokerServicesConstants, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    showSpinner: false,

    errorMessageKey: _ember['default'].computed('errorMessage', function () {
      var _this = this;

      return (0, _edibrokerServicesConstants.getErrorKey)(this.get('errorMessage'), function (value) {
        return _this.get('intl').exists(value);
      }, 'error.exception.AuthenticationException');
    }),

    actions: {
      authenticate: function authenticate() {
        var _this2 = this;

        this.set('showSpinner', true);
        var credentials = this.getProperties('identification', 'password');
        this.set('password', '');

        this.get('session').authenticate(_edibrokerConfigEnvironment['default'].APP.authenticator, credentials)['catch'](function (reason) {
          _this2.set('errorMessage', reason.errors.message);
          _this2.set('showSpinner', false);
        });
      }
    }
  });
});