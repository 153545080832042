define('edibroker/services/page-title-list', ['exports', 'ember-page-title/services/page-title-list', 'edibroker/config/environment'], function (exports, _emberPageTitleServicesPageTitleList, _edibrokerConfigEnvironment) {

  function capitalize(key) {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  var defaults = {};
  ['separator', 'prepend', 'replace'].forEach(function (key) {
    if (_edibrokerConfigEnvironment['default'].pageTitle && _edibrokerConfigEnvironment['default'].pageTitle[key]) {
      defaults['default' + capitalize(key)] = _edibrokerConfigEnvironment['default'].pageTitle[key];
    }
  });

  exports['default'] = _emberPageTitleServicesPageTitleList['default'].extend(defaults);
});