define('edibroker/components/address-display', ['exports', 'ember'], function (exports, _ember) {

  var AddressDisplay = _ember['default'].Component.extend({
    tagName: '',

    addresses: _ember['default'].computed('address', function () {
      var address = this.get('address');
      return address ? address.split(';') : [];
    })
  });

  AddressDisplay.reopenClass({
    positionalParams: ['address']
  });

  exports['default'] = AddressDisplay;
});