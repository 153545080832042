define('edibroker/controllers/language/file/detail', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    ajax: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    stepSort: ['date:desc', 'step:desc'],
    sortedSteps: _ember['default'].computed.sort('model.steps', 'stepSort'),
    toast: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),
    showCancelModal: false,
    showDeleteModal: false,
    raison: '',
    isNetProassur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),
    isAdmin: _ember['default'].computed.alias('currentUser.isAdmin').readOnly(),

    actions: {
      makeReady: function makeReady() {
        var _this = this;

        this.get('ajax').post('/api/files/' + this.model.get('id') + '/unarchive').then(function () {
          _this.get('model').reload();
          _this.get('toast').success(_this.get('intl').t('file.detail.unarchived-success'));
        }, function (error) {
          return _this.send('error', error);
        });
      },

      reprocess: function reprocess() {
        var _this2 = this;

        this.get('ajax').post('/api/files/' + this.model.get('id') + '/retry').then(function () {
          _this2.get('model').reload();
          _this2.get('toast').success(_this2.get('intl').t('file.detail.reprocess-success'));
        }, function (error) {
          return _this2.send('error', error);
        });
      },

      cancel: function cancel() {
        this.set('showCancelModal', true);
      },

      'delete': function _delete() {
        this.set('showDeleteModal', true);
      },

      cancelDelete: function cancelDelete() {
        this.set('raison', '');
      },

      proceedCancel: function proceedCancel(defer) {
        var _this3 = this;

        this.get('ajax').post('/api/files/' + this.get('model.id') + '/cancel').then(function () {
          _this3.get('model').reload();
          _this3.get('toast').success(_this3.get('intl').t('file.cancel-success'));
          defer.resolve();
        }, function (error) {
          _this3.send('error', error.errors ? error.errors[0].detail : error);
          defer.resolve();
        });
      },

      proceedDelete: function proceedDelete(defer) {
        var _this4 = this;

        if (this.get('raison') === '') {
          this.get('toast').error(this.get('intl').t('file.delete-error-empty-raison'));
          defer.resolve();
        } else {
          _ember['default'].$.ajax({
            url: '/api/files/' + this.get('model.id') + '/delete',
            method: 'POST',
            data: JSON.stringify(this.get('raison')),
            dataType: 'json',
            contentType: 'application/json'
          }).then(function () {
            _this4.get('model').reload();
            _this4.get('toast').success(_this4.get('intl').t('file.delete-success'));
            defer.resolve();
          }).fail(function (error) {
            _this4.send('error', error.errors ? error.errors[0].detail : error);
            defer.resolve();
          });
        }
      }

    }

  });
});