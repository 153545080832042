define('edibroker/routes/language/administration/user/create', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    model: function model() {
      return this.store.createRecord('user');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },

    resetController: function resetController(controller) {
      controller.set('loading', false);
      controller.set('didValidate', false);
      controller.set('selectedMembers', []);
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.administration.user');
      },

      success: function success() {
        this.get('toast').success(this.get('intl').t('user.create-success'));
        this.transitionTo('language.administration.user.detail', this.get('controller.model'));
      }
    }
  });
});