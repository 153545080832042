define('edibroker/controllers/language', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    currentPath: _ember['default'].computed.alias('application.currentPath'),
    height: 0,
    hideSha: _edibrokerConfigEnvironment['default'].APP.hideSha,

    init: function init() {
      this._super();
      _ember['default'].run.schedule('afterRender', this, function () {
        this.send('updateHeight');
      });
    },

    minHeight: _ember['default'].computed('height', function () {
      return new _ember['default'].String.htmlSafe('min-height:' + this.get('height') + 'px');
    }),

    currentYear: _ember['default'].computed(function () {
      return new Date().getFullYear();
    }),

    version: _ember['default'].computed('hideSha', function () {
      var version = _edibrokerConfigEnvironment['default'].APP.version.split('+')[0];
      var sha = _edibrokerConfigEnvironment['default'].APP.version.split('+')[1];
      if (this.get('hideSha')) {
        return version;
      } else {
        return version + '+' + sha;
      }
    }),

    isProd: _ember['default'].computed(function () {
      return _edibrokerConfigEnvironment['default'].environment === 'production' || _edibrokerConfigEnvironment['default'].environment === 'pra';
    }),
    environment: _ember['default'].computed(function () {
      return 'enums.Environment.' + _edibrokerConfigEnvironment['default'].environment;
    }),

    actions: {
      updateHeight: function updateHeight() {
        var height = _ember['default'].$(window).height() - _ember['default'].$('.main-header').height() - _ember['default'].$('.main-footer').height() - 31;
        this.set('height', height);
      }
    }
  });
});