define('edibroker/routes/language/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    beforeModel: function beforeModel() {
      var _this = this;

      if (this.get('history')) {
        (function () {
          var params = _this.get('historyParams');
          if (params) {
            var models = Object.keys(params).map(function (key) {
              return params[key];
            });
            _this.transitionTo.apply(_this, [_this.get('history')].concat(_toConsumableArray(models)));
          } else {
            _this.transitionTo(_this.get('history'));
          }
        })();
      } else {
        this.transitionTo('language.dashboard');
      }
    }
  });
});