define('edibroker/routes/language/administration/member/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    model: function model() {
      return this.store.createRecord('member');
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('language.administration.member');
      },

      success: function success() {
        this.get('toast').success(this.get('intl').t('member.create-success'));
        this.transitionTo('language.administration.member.detail', this.get('controller.model'));
      },

      editSftp: function editSftp() {
        this.get('toast').success(this.get('intl').t('member.create-success'));
        this.transitionTo('language.administration.member.detail.editsftp', this.get('controller.model'));
      }

    }
  });
});