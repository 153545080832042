define('edibroker/helpers/size-display', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var units = ['octet(s)', 'Ko', 'Mo', 'Go', 'To'];

  /**
    This helper is used to display size in bytes.
  
    Example:
    ```hbs
    {{size-display myValue}}
    ```
  */
  exports['default'] = _ember['default'].Helper.extend({

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1);

      var value = _ref2[0];

      var l = 0,
          n = parseInt(value, 10) || 0;
      while (n >= 1024 && l < units.length) {
        n = n / 1024;
        l++;
      }
      return n.toFixed(n >= 10 || l < 1 ? 0 : 1) + ' ' + units[l];
    }
  });
});