define('edibroker/components/file-list', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    classNames: ['row'],

    availableSizes: _edibrokerServicesConstants.PAGE_SIZES,

    isAdvancedMode: false,

    isSearchPage: false,

    showDeleteModal: false,

    currentUser: _ember['default'].inject.service(),
    isNetProassur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),
    isAdmin: _ember['default'].computed.alias('currentUser.isAdmin').readOnly(),

    raison: '',

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    modeLabelKey: _ember['default'].computed('isAdvancedMode', function () {
      if (this.get('isAdvancedMode')) {
        return 'file.search.simple-mode';
      } else {
        return 'file.search.advanced-mode';
      }
    }),

    sortSeparator: _ember['default'].computed(function () {
      if (this.get('isSearchPage')) {
        return ',';
      } else {
        return ':';
      }
    }),

    sortCriteria: _ember['default'].computed.collect('fileSort'),
    fileSort: '',
    sortedFiles: _ember['default'].computed.sort('files', 'sortCriteria'),

    displayedFiles: _ember['default'].computed('files.[]', 'fileSort', 'sortedFiles.[]', function () {
      return !!this.get('fileSort') ? this.get('sortedFiles') : this.get('files');
    }),

    id: _ember['default'].computed('modalId', function () {
      return this.get('modalId') ? this.get('modalId') : 'delete';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].defineProperty(this, 'sortAlias', _ember['default'].computed.alias(this.get('isSearchPage') ? 'filter.sort' : 'fileSort'));
    },

    actions: {
      toggleAdvancedMode: function toggleAdvancedMode() {
        this.toggleProperty('isAdvancedMode');
      },

      askDelete: function askDelete(file) {
        this.set('fileToDelete', file);
        this.set('showDeleteModal', true);
      },

      cancelDelete: function cancelDelete() {
        this.set('raison', '');
      },

      proceedDelete: function proceedDelete(defer) {
        var _this = this;

        if (this.get('raison') === '') {
          this.get('toast').error(this.get('intl').t('file.delete-error-empty-raison'));
          defer.resolve();
        } else {
          _ember['default'].$.ajax({
            url: '/api/files/' + this.get('fileToDelete.id') + '/delete',
            method: 'POST',
            data: JSON.stringify(this.get('raison')),
            dataType: 'json',
            contentType: 'application/json'
          }).then(function () {
            _this.sendAction('reloadModel', _this.get('fileToDelete'));
            _this.get('toast').success(_this.get('intl').t('file.delete-success'));
            defer.resolve();
          }).fail(function (error) {
            _this.send('error', error.errors ? error.errors[0].detail : error);
            defer.resolve();
          });
        }
      }

    }
  });
});