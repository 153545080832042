define("edibroker/templates/language/profile/password", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/language/profile/password.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-lg-12");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "box box-primary");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "box-header");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        dom.setAttribute(el5, "class", "box-title");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4, "class", "form-horizontal");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "callout callout-info");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "box-body");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "overlay");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-refresh fa-spin");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element0, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 0, 0);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(element2, 5, 5);
        morphs[5] = dom.createMorphAt(element2, 7, 7);
        morphs[6] = dom.createAttrMorph(element3, 'style');
        return morphs;
      },
      statements: [["inline", "t", ["user.profile.password"], [], ["loc", [null, [5, 38], [5, 67]]], 0, 0], ["inline", "t", ["user.profile.password-rules-info"], [], ["loc", [null, [9, 23], [9, 63]]], 0, 0], ["inline", "inputs/v-input", [["get", "this", ["loc", [null, [12, 37], [12, 41]]], 0, 0, 0, 0], "oldPassword"], ["type", "password", "label", "authentication.oldPassword", "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [12, 119], [12, 130]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 20], [12, 132]]], 0, 0], ["inline", "inputs/v-input", [["get", "this", ["loc", [null, [13, 37], [13, 41]]], 0, 0, 0, 0], "newPassword"], ["type", "password", "label", "authentication.newPassword", "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [13, 119], [13, 130]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 20], [13, 132]]], 0, 0], ["inline", "inputs/v-input", [["get", "this", ["loc", [null, [14, 37], [14, 41]]], 0, 0, 0, 0], "confirmation"], ["type", "password", "label", "authentication.confirmation", "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [14, 121], [14, 132]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 20], [14, 134]]], 0, 0], ["inline", "inputs/v-submit", [["get", "this", ["loc", [null, [15, 38], [15, 42]]], 0, 0, 0, 0]], ["validate", "validate", "cancel", "cancel", "forceDirty", true], ["loc", [null, [15, 20], [15, 96]]], 0, 0], ["attribute", "style", ["get", "loadingStyle", ["loc", [null, [18, 41], [18, 53]]], 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});