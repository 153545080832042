define('edibroker/controllers/language/administration/user/detail/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),

    isProfilePage: false,
    showDeleteModal: false,
    showResetModal: false,

    actions: {
      toggleLock: function toggleLock(doLock) {
        var _this = this;

        var toggleStatus = doLock ? 'LOCKED' : 'UNLOCKED';
        var message = doLock ? 'user.lock-success' : 'user.unlock-success';
        (0, _edibrokerServicesConstants.updateUserStatus)(this.get('model.id'), toggleStatus, function () {
          _this.get('toast').success(_this.get('intl').t(message));
          _this.get('model').reload();
        }, function (response) {
          _ember['default'].Logger.error('Error while toggling user lock ' + _this.get('model.id') + ' ', response);
          _this.send('error', response.responseJSON);
        });
      },

      askResetPassword: function askResetPassword() {
        this.set('showResetModal', true);
      },

      doResetPassword: function doResetPassword(defer) {
        var _this2 = this;

        this.get('ajax').post('/api/users/public/lost', { data: { username: this.get('model.login') } }).then(function () {
          return _this2.get('toast').success(_this2.get('intl').t('user.password-reset-success'));
        }, function (error) {
          return _this2.send('error', error);
        })['finally'](defer.resolve());
      },

      askDelete: function askDelete() {
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        this.get('model').destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('user.delete-success'));
          _this3.transitionToRoute('language.administration.user');
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      }
    }
  });
});