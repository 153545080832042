define('edibroker/services/current-user', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {

  /**
   * Service used to retrieve current user informations.
   */
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    user: {},
    preferences: _ember['default'].computed.alias('user.preferences'),
    subscriptions: _ember['default'].computed.alias('user.subscriptions'),
    isNetProassur: _ember['default'].computed('user.userProfile', function () {
      return 'NETPROASSUR' === this.get('user.userProfile') || 'EDI_MANAGER' === this.get('user.userProfile');
    }),
    isAdmin: _ember['default'].computed('user.userProfile', function () {
      return 'NETPROASSUR' === this.get('user.userProfile') || 'MEMBER_ADMIN' === this.get('user.userProfile');
    }),

    /**
     * Retrieve the current user token.
     */
    token: function token() {
      if (this.get('session.isAuthenticated')) {
        return this.get('session.data.authenticated.token');
      }
      return '';
    },

    /**
     * Load the user if the session is authenticated. Will call `success` with the loaded user in parameter.
     */
    load: function load() {
      var success = arguments.length <= 0 || arguments[0] === undefined ? _ember['default'].K : arguments[0];

      if (this.get('session.isAuthenticated')) {
        var token = this.get('session.data.authenticated.token'),
            jwt = _ember['default'].getOwner(this).lookup(_edibrokerConfigEnvironment['default'].APP.authenticator),
            data = jwt.getTokenData(token);

        this._initCurrentUser(_ember['default'].Object.create(data['usr-data']));
        success(this.get('user'));
      }
    },

    /**
     * Refresh the user token if the session is authenticated.
     */
    refresh: function refresh() {
      if (this.get('session.isAuthenticated')) {
        _ember['default'].Logger.debug('Refreshing user token');

        var token = this.get('session.data.authenticated.token'),
            authenticator = _ember['default'].getOwner(this).lookup(_edibrokerConfigEnvironment['default'].APP.authenticator);

        authenticator.refreshAccessToken(token);
      }
    },

    /**
     * Invalidate the current session.
     */
    logout: function logout() {
      this.get('session').invalidate();
      _ember['default'].$.ajax({
        url: '/api/auth/logout',
        method: 'POST',
        data: {
          token: this.get('session.data.authenticated.token')
        }
      }).then(function () {}).fail(function (response) {
        console.log(response);
      });
    },

    /**
     * Checks if the current connected user has the given permission.
     */
    hasAccess: function hasAccess(permission) {
      if (this.get('session.isAuthenticated') && permission) {
        var permissions = this.get('user.permissions') || [];
        return permissions.indexOf(permission) > -1;
      } else {
        return false;
      }
    },

    hasMemberAccess: function hasMemberAccess(member) {
      return this.get('isNetProassur') || this.get('user.members').indexOf(parseInt(member.get('id'))) > -1;
    },

    _initCurrentUser: function _initCurrentUser(user) {
      this.set('user', user);
      var preferences = this.get('preferences');
      if (preferences) {
        var userLanguage = user.get('preferences.language');
        if (userLanguage) {
          this.get('intl').load(userLanguage);
        }
        var pageSize = user.get('preferences.pageSize');
        if (pageSize) {
          this.set('preferences.pageSize', parseInt(pageSize));
        }
      } else {
        this.set('preferences', {});
      }
      if (!this.get('subscriptions')) {
        this.set('subscriptions', {});
      }
    }
  });
});