define('edibroker/services/unicity-check', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Service used to check unicity of a model attribute
   */
  exports['default'] = _ember['default'].Service.extend({
    intl: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    getUnicityPromise: function getUnicityPromise(url, errorMsgKey, currentId) {
      var _this = this;

      var intl = this.get('intl');
      return new _ember['default'].RSVP.Promise(function (resolve) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.setRequestHeader('Accept', 'application/json');
        _this.get('session').authorize('authorizer:token', function (headerName, headerValue) {
          xhr.setRequestHeader(headerName, headerValue);
        });

        xhr.onreadystatechange = function () {
          if (this.readyState === this.DONE) {
            if (this.status === 200) {
              var retrieved = JSON.parse(this.responseText);
              if (parseInt(_ember['default'].get(retrieved, 'id')) === parseInt(currentId)) {
                resolve(true);
              } else {
                resolve(intl.t(errorMsgKey));
              }
            } else {
              resolve(true);
            }
          }
        };
        xhr.send();
      });
    }
  });
});