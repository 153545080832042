define('edibroker/controllers/password', ['exports', 'ember', 'edibroker/services/constants', 'ember-cp-validations'], function (exports, _ember, _edibrokerServicesConstants, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'error.back.missing.password'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])).*/,
      messageKey: 'error.back.illegal.password-rules'
    })],
    confirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      messageKey: 'error.back.illegal.password'
    })
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    password: '',
    confirmation: '',

    errorMessageKey: _ember['default'].computed('errorMessage', function () {
      var _this = this;

      return (0, _edibrokerServicesConstants.getErrorKey)(this.get('errorMessage'), function (value) {
        return _this.get('intl').exists(value);
      });
    }),

    actions: {
      validation: function validation() {
        var _this2 = this;

        if (this.get('validations.isValid')) {
          _ember['default'].$.ajax({
            url: '/api/users/public/password',
            method: 'POST',
            data: {
              password: this.get('password'),
              confirmation: this.get('confirmation'),
              token: this.get('model.value')
            }
          }).then(function () {
            _ember['default'].Logger.info('Password set');
            _this2.get('toast').success(_this2.get('intl').t('authentication.passwordInit-success'));
            _this2.transitionToRoute('index');
          }).fail(function (response) {
            _ember['default'].Logger.error('Error while setting password', response);
            var message = _ember['default'].get(response, 'responseJSON.errors.message') || '';
            _this2.set('errorMessage', message);
          });
        } else {
          var passwordMessage = this.get('validations.attrs.password.message');
          if (passwordMessage) {
            this.set('validationMessage', passwordMessage);
          } else {
            this.set('validationMessage', this.get('validations.attrs.confirmation.message'));
          }
        }
      }
    }
  });
});