define('edibroker/routes/language/profile', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    templateName: 'language/administration/user/detail',
    controllerName: 'language/administration/user/detail',

    currentUser: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('language.administration.user.detail').set('isProfilePage', true);
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.controllerFor('language.administration.user.detail').set('isProfilePage', false);
      }
    },

    model: function model() {
      var id = this.get('currentUser').get('user.id');
      return this.store.findRecord('user', id);
    }
  });
});