define('edibroker/controllers/language/administration/user/detail', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    isProfilePage: false,
    isEditPage: false,
    isPasswordPage: false,
    isNestedPage: _ember['default'].computed.or('isEditPage', 'isPasswordPage'),
    nestedPage: _ember['default'].computed('isEditPage', 'isPasswordPage', function () {
      if (this.get('isEditPage')) {
        return 'pages.administration.edit';
      }
      if (this.get('isPasswordPage')) {
        return 'pages.profile-password';
      }
      return '';
    }),
    availableSubscriptions: _edibrokerServicesConstants.MAIL_NOTIFICATIONS
  });
});