define('edibroker/controllers/language/administration/accesstoken/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    didValidate: false,
    loading: false,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {

      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.get('model').validate().then(function (result) {
          _this.set('didValidate', true);
          if (_ember['default'].get(result, 'validations.isValid')) {
            _this.send('save');
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      }

    }
  });
});