define('edibroker/controllers/language/administration/configuration', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({

    rendered: false,
    loading: false,
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    templateConfigurationUrl: _ember['default'].computed(function () {
      return _edibrokerConfigEnvironment['default'].APP.templateConfiguration;
    }),

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    isDirty: function isDirty(param) {
      return this.get('model.' + param) !== this.get('model.original_data.' + param);
    },

    getDirtyParams: function getDirtyParams() {
      var _this = this;

      var dirtyParams = {};
      this.get('model.params').filter(function (param) {
        return _this.isDirty(param);
      }).forEach(function (param) {
        dirtyParams[param] = _this.get('model.' + param);
      });
      return dirtyParams;
    },

    actions: {
      validate: function validate() {
        var _this2 = this;

        this.set('loading', true);
        this.get('model').validate().then(function (result) {
          _this2.set('didValidate', true);
          if (_ember['default'].get(result, 'validations.isValid')) {
            _this2.send('save');
          } else {
            _this2.set('loading', false);
            _this2.get('toast').error(_this2.get('intl').t('error.validations.hasError'));
          }
        });
      },
      save: function save() {
        var _this3 = this;

        var dirtyParams = this.getDirtyParams();
        if (_ember['default'].$.isEmptyObject(dirtyParams)) {
          this.get('toast').info(this.get('intl').t('configuration.no-update-info'));
          this.set('loading', false);
        } else {
          _ember['default'].$.ajax({
            url: '/api/configuration',
            method: 'POST',
            data: JSON.stringify(dirtyParams),
            dataType: 'json',
            contentType: 'application/json'
          }).then(function () {
            _this3.get('toast').success(_this3.get('intl').t('configuration.update-success'));
            Object.keys(dirtyParams).forEach(function (key) {
              _this3.set('model.original_data.' + key, dirtyParams[key]);
            });
            _this3.set('loading', false);
          }).fail(function () {
            _this3.get('toast').error(_this3.get('intl').t('configuration.update-error'));
            _this3.set('loading', false);
          });
        }
      },
      cancel: function cancel() {
        var _this4 = this;

        this.get('model.params').forEach(function (param) {
          return _this4.set('model.' + param, _this4.get('model.original_data.' + param));
        });
        this.get('toast').info(this.get('intl').t('configuration.reset-info'));
      },
      openTemplateConfiguration: function openTemplateConfiguration() {
        window.open(this.get('templateConfigurationUrl'));
      }
    }

  });
});