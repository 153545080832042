define('edibroker/components/dashboard-msg-chart', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

  var AmCharts = window.AmCharts;

  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    options: {},
    classNames: ['amchart', 'col-lg-10'],

    graphData: [],

    type: _ember['default'].computed('isOneDay', function () {
      return this.get('isOneDay') ? 'column' : 'smoothedLine';
    }),
    fillAlphas: _ember['default'].computed('isOneDay', function () {
      return this.get('isOneDay') ? 1 : 0;
    }),
    bulletSize: _ember['default'].computed('isOneDay', function () {
      return this.get('isOneDay') ? 0 : 8;
    }),
    period: _ember['default'].computed('isMonth', function () {
      return this.get('isMonth') ? 'MM' : 'DD';
    }),
    dateFormat: _ember['default'].computed('isMonth', function () {
      return this.get('isMonth') ? 'YYYY-MM' : 'YYYY-MM-DD';
    }),
    labelFormat: _ember['default'].computed('isMonth', function () {
      return this.get('isMonth') ? 'month-format' : 'day-format';
    }),

    dataChanged: _ember['default'].observer('graphData.[]', function () {
      var _this = this;

      var chart = this.get('chart');
      chart.graphs = [];
      var first = this.get('graphData.firstObject');
      if (first) {
        Object.keys(first).filter(function (k) {
          return k !== 'date';
        }).forEach(function (key) {
          return chart.addGraph({
            id: key,
            title: 'Message ' + key,
            valueField: key,
            lineThickness: 2,
            type: _this.get('type'),
            bullet: "round",
            bulletSize: _this.get('bulletSize'),
            fillAlphas: _this.get('fillAlphas'),
            balloonText: "[[title]] - [[date]] : [[value]]"
          });
        });
      }
      chart.dataProvider = this.get('graphData');
      var format = this.get('intl').t('common.' + this.get('labelFormat'));
      var lang = this.get('intl.currentLanguage');
      chart.categoryAxis.labelFunction = function (value, date) {
        return (0, _moment['default'])(date).locale(lang).format(format);
      };
      chart.categoryAxis.minPeriod = this.get('period');
      chart.dataDateFormat = this.get('dateFormat');
      chart.validateData();
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      _ember['default'].run(function () {
        var chart = AmCharts.makeChart(_this2.elementId, {
          type: "serial",
          theme: "light",
          categoryField: "date",
          categoryAxis: {
            parseDates: true,
            boldPeriodBeginning: false
          },
          valueAxes: [{
            minimum: 0
          }],
          legend: {
            divId: "chartLegend",
            enabled: true,
            useGraphSettings: true,
            marginLeft: 10
          }
        });
        var removeLink = function removeLink() {
          _ember['default'].run(function () {
            return _ember['default'].$("a[href='http://www.amcharts.com/javascript-charts/']").hide();
          });
        };
        chart.addListener("drawn", removeLink);
        _this2.set('chart', chart);
      });
    }

  });
});