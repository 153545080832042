define('edibroker/components/member/message-list', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Component that can be used to display and edit a list of messages
   *
   * ```hbs
   * {{member/message-list model isModelPage=isModelPage}}
   * ```
   */
  var MessageList = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    tagName: '',
    error: 'error',

    showSftp: false,
    sftpMessage: undefined,
    showDeleteModal: false,
    msgSftpTitle: _ember['default'].computed('sftpMessage', function () {
      return this.get('intl').t('member.sftp.msg-edit-title', { type: this.get('sftpMessage.type') });
    }),

    newMsg: _ember['default'].computed('model.messages.@each.isNew', function () {
      return this.get('model.messages').filterBy('isNew').get('firstObject');
    }),

    messageSort: ['type'],
    sortedMessages: _ember['default'].computed.sort('model.messages', 'messageSort'),

    actions: {
      editSftp: function editSftp(message) {
        this.set('sftpMessage', message);
        this.set('showSftp', true);
      },
      cancelSftp: function cancelSftp() {
        this.set('showSftp', false);
      },
      successSftp: function successSftp() {
        this.get('toast').success(this.get('intl').t('member.messages.edit-success'));
        this.set('showSftp', false);
      },
      confirmDelete: function confirmDelete(deleteCallback) {
        this.set('deleteAction', deleteCallback);
        this.set('showDeleteModal', true);
      },
      deleteMsg: function deleteMsg(callback) {
        var _this = this;

        var complete = function complete() {
          if (callback) {
            callback.resolve();
          }
          _this.set('showDeleteModal', false);
        };
        this.get('deleteAction')(complete);
      },
      error: function error(_error) {
        this.sendAction('error', _error);
      }

    }
  });

  MessageList.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = MessageList;
});