define('edibroker/components/inputs/v-submit', ['exports', 'ember'], function (exports, _ember) {

  /**
    Submit buttons for model with validations. Optional buttons next and clear will be displayed if corresponding actions
    are provided. Next button label can be customized using the nextLabel attribute.
  
    ```hbs
    {{inputs/v-submit model validate='validate' cancel='cancel' next='next' nextLabel='Custom Next Label' clear='clear'}}
    ```
   */
  var ValidatedSubmitComponent = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['form-group'],
    useNext: _ember['default'].computed.notEmpty('next').readOnly(),
    useClear: _ember['default'].computed.notEmpty('clear').readOnly(),

    forceDirty: false,
    isDirty: _ember['default'].computed.or('model.hasDirtyAttributes', 'forceDirty'),

    actions: {
      validate: function validate() {
        this.sendAction('validate');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      },

      next: function next() {
        this.sendAction('next');
      },

      clear: function clear() {
        this.sendAction('clear');
      }
    }
  });

  ValidatedSubmitComponent.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = ValidatedSubmitComponent;
});