define('edibroker/routes/language/administration/message/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model() {
      var filter = this.emptyFilter();
      return _ember['default'].RSVP.hash({
        filter: filter,
        messages: []
      });
    },

    emptyFilter: function emptyFilter() {
      return {
        sort: '',
        page: 0,
        size: this.get('currentUser.preferences.pageSize') || 20
      };
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.filterMessages();
      });
    },

    resetController: function resetController(controller) {
      controller.set('rendered', false);
      controller.set('loading', false);
    }

  });
});