define('edibroker/controllers/language/profile/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    didValidate: false,
    loading: false,

    isAdminUser: _ember['default'].computed('model.userProfile', function () {
      var profile = this.get('model.userProfile');
      return profile === 'MEMBER_ADMIN' || profile === 'NETPROASSUR';
    }),

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.get('model').validate().then(function (model) {
          _this.set('didValidate', true);
          if (_ember['default'].get(model, 'validations.isValid')) {
            _this.send('save');
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      }
    }
  });
});