define('edibroker/models/member', ['exports', 'ember', 'ember-data', 'ember-cp-validations', 'edibroker/mixins/sftp-model'], function (exports, _ember, _emberData, _emberCpValidations, _edibrokerMixinsSftpModel) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var Validations = (0, _emberCpValidations.buildValidations)(_ember['default'].merge({

    code: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { regex: /^[0-9A-Za-z]{1,8}$/, messageKey: 'error.validations.invalidEdiCode' }), (0, _emberCpValidations.validator)('unique-edi-code', { disabled: _ember['default'].computed.not('model.isNew'), debounce: 250 })],

    label: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 250 })],

    type: (0, _emberCpValidations.validator)('presence', true),

    status: (0, _emberCpValidations.validator)('presence', true),

    address: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('multi-format', { type: 'email', split: ';', messageKey: 'error.validations.multipleEmail' })],

    language: (0, _emberCpValidations.validator)('presence', true)

  }, _edibrokerMixinsSftpModel.SftpValidations));

  exports.Validations = Validations;
  exports['default'] = _emberData['default'].Model.extend(_edibrokerMixinsSftpModel['default'], Validations, {

    code: attr('string'),
    label: attr('string'),
    type: attr('string'),
    status: attr('string'),
    protocols: attr(),
    address: attr('string'),
    language: attr('string'),
    messages: hasMany('message'),
    msgTypes: attr(),

    shortLabel: _ember['default'].computed('label', function () {
      var name = this.get('label');
      return name.length > 40 ? name.substring(0, 40) + ' ...' : name;
    }),

    isDeleted: _ember['default'].computed('status', function () {
      return this.get('status') === 'DELETED';
    })

  });
});