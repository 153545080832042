define('edibroker/controllers/language/administration/memberprofile/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    filter: _ember['default'].computed.alias('model.filter'),
    members: _ember['default'].computed.alias('model.members'),
    currentUser: _ember['default'].inject.service(),
    loading: false,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    filterChange: _ember['default'].observer('filter.{sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterMembers();
      }
    }),

    filterMembers: function filterMembers() {
      var _this = this;

      this.store.query('member', this.get('filter')).then(function (members) {
        if (!members.get('length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('members', members);
        _this.set('loading', false);
      });
    }
  });
});