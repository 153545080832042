define('edibroker/controllers/language/administration/reference/index', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    filter: _ember['default'].computed.alias('model.filter'),
    loading: true,
    references: _ember['default'].computed.alias('model.references'),
    current: _ember['default'].computed.alias('model.current'),
    rendered: false,
    showActivateModal: false,
    showDeleteModal: false,

    filterChange: _ember['default'].observer('filter.{sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterReferences();
      }
    }),

    filterReferences: function filterReferences() {
      var _this = this;

      this.store.unloadAll('reference');
      this.store.query('reference', this.get('filter')).then(function (references) {
        if (!_ember['default'].get(references, 'length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('references', references);
        _this.set('loading', false);
      })['catch'](function (errors) {
        _this.send('error', errors);
      });
    },

    getDownloadUrl: function getDownloadUrl(referenceId) {
      var url = _edibrokerConfigEnvironment['default'].APP.proxy + '/api/references/' + referenceId + '/download';
      return url + ('?' + this.getQueryParams());
    },

    getQueryParams: function getQueryParams() {
      var queryParams = {};
      queryParams['authorization'] = this.get('currentUser').token();
      queryParams = _ember['default'].$.param(queryParams);
      return queryParams;
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      askActivate: function askActivate(reference) {
        this.set('referenceToActivate', reference);
        this.set('showActivateModal', true);
      },

      askDelete: function askDelete(reference) {
        this.set('referenceToDelete', reference);
        this.set('showDeleteModal', true);
      },

      proceedActivate: function proceedActivate(defer) {
        var _this2 = this;

        var reference = this.get('referenceToActivate');
        this.set('referenceToActivate', null);
        _ember['default'].$.ajax({
          url: '/api/references/' + reference.get('id') + '/activate',
          method: 'POST'
        }).then(function () {
          defer.resolve();
          _this2.get('toast').success(_this2.get('intl').t('reference.activate-success'));
          _this2.store.queryRecord('reference', {}).then(function (current) {
            return _this2.set('current', current);
          });
          _this2.filterReferences();
        }).fail(function (error) {
          defer.resolve();
          _this2.send('error', error.responseJSON);
        });
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        var reference = this.get('referenceToDelete');
        this.set('referenceToDelete', null);
        reference.destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('reference.delete-success'));
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      },

      proceedDownload: function proceedDownload(referenceId) {
        window.open(this.getDownloadUrl(referenceId), '_blank');
      }

    }
  });
});