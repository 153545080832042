define('edibroker/components/left-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentUser: _ember['default'].inject.service(),
    currentPath: '',
    isNetProAssur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),

    isAdministration: _ember['default'].computed('currentPath', function () {
      return this.get('currentPath').indexOf('language.administration') === 0;
    }),

    showAdminMenu: _ember['default'].computed('currentUser', function () {
      var user = this.get('currentUser');
      return user.hasAccess('MEMBER@read') || user.hasAccess('MEMBER@profile') || user.hasAccess('USER@read') || user.hasAccess('MODEL@read') || user.hasAccess('CERTIFICATE@read') || user.hasAccess('CONFIGURATION@management') || user.hasAccess('MESSAGE@read') || user.hasAccess('REFERENCETABLE@read') || user.hasAccess('ACCESSTOKEN@read');
    }),

    isFile: _ember['default'].computed('currentPath', function () {
      return this.get('currentPath').indexOf('language.file') === 0;
    }),

    isDashboard: _ember['default'].computed('currentPath', function () {
      return this.get('currentPath') === 'language.dashboard';
    })
  });
});