define('edibroker/components/inputs/v-textarea', ['exports', 'edibroker/components/inputs/v-input'], function (exports, _edibrokerComponentsInputsVInput) {

  /**
   * Validated input displaying a textarea
   */
  var ValidatedTextArea = _edibrokerComponentsInputsVInput['default'].extend({});

  ValidatedTextArea.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedTextArea;
});