define('edibroker/components/inputs/v-select', ['exports', 'ember', 'edibroker/components/inputs/v-input', 'edibroker/services/constants'], function (exports, _ember, _edibrokerComponentsInputsVInput, _edibrokerServicesConstants) {

  /**
    Input with validation components
  
    ```hbs
    {{inputs/v-select model 'userProfile' label='model.user.userProfile' type='UserProfile'}}
    ```
   */
  var ValidatedSelectComponent = _edibrokerComponentsInputsVInput['default'].extend({
    intl: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    enumValues: _ember['default'].computed('type', function () {
      return (0, _edibrokerServicesConstants.getEnumConstants)(this.get('type'), this.get('currentUser.isNetProassur'));
    }),

    elements: _ember['default'].computed('type', 'enumValues.[]', 'intl.currentLanguage', function () {
      var _this = this;

      _ember['default'].Logger.debug('Recomputing available options as language is now ' + this.get('intl.currentLanguage'));
      var type = this.get('type');
      var content = this.get('enumValues').map(function (value) {
        return {
          value: value,
          label: _this.get('intl').t('enums.' + type + '.' + value)
        };
      });
      content.unshift({
        value: null,
        label: ''
      });
      return content;
    }),

    selected: _ember['default'].computed('value', 'intl.currentLanguage', {
      get: function get() {
        var type = this.get('type');
        var value = _ember['default'].get(this, 'value');
        var label = '';
        if (value) {
          label = this.get('intl').t('enums.' + type + '.' + value);
        }
        return {
          value: value,
          label: label
        };
      },
      set: function set(key, value) {
        _ember['default'].set(this, 'value', value.value);
        return value;
      }
    })
  });

  ValidatedSelectComponent.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedSelectComponent;
});