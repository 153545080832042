define('edibroker/routes/language/administration/model/detail/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Update breadcrumb displayed by parent route
      this.controllerFor('language.administration.model.detail').set('isEditPage', true);
    },

    resetController: function resetController(controller, isExiting /* , transition */) {
      if (isExiting) {
        controller.set('loading', false);
      }
    },

    actions: {

      success: function success() {
        this.get('toast').success(this.get('intl').t('model.edit-success'));
        this.transitionTo('language.administration.model.detail');
      },

      cancel: function cancel() {
        this.transitionTo('language.administration.model.detail');
      }
    }
  });
});