define('edibroker/routes/language/administration/member/detail', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    intl: _ember['default'].inject.service(),
    currentUser: _ember['default'].inject.service(),

    model: function model(params) {
      return this.store.findRecord('member', params.id);
    },

    afterModel: function afterModel(member) {
      var error = false;
      if (member.get('status') === 'DELETED') {
        error = 'error.back.state.deleted-member';
      }
      if (!this.get('currentUser').hasMemberAccess(member)) {
        error = 'error.exception.AccessDeniedException';
      }
      if (error) {
        this.get('toast').error(this.get('intl').t(error));
        this.replaceWith('language.dashboard');
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isModelPage', false);
      controller.set('isProfilePage', false);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').updateTransitionStatus(transition, this.get('routeName'));
        return true;
      }
    }
  });
});