define('edibroker/models/dynamicfield', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({
    creationDate: (0, _emberCpValidations.validator)('presence', true),
    version: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {
    activationDate: attr('string'),
    creationDate: attr('string'),
    version: attr('number')
  });
});