define('edibroker/components/member/model-form', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Form component that can be used to create or edit a model
   *
   * ```hbs
   * {{model-form member success='successAction' cancel='cancelAction' error='errorAction'}}
   * ```
   *
   * @param success
   *          {String} name of the action to be triggered when submitting the form
   * @param cancel
   *          {String} name of the action to be triggered when canceling the form
   * @param error
   *          {String} name of the action to be triggered when an error occurs
   */
  var ModelForm = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    classNames: ['box', 'box-primary'],
    didValidate: false,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    validate: function validate(actionName) {
      var _this = this;

      this.set('loading', true);
      this.get('model').validate().then(function (result) {
        _this.set('didValidate', true);
        if (_ember['default'].get(result, 'validations.isValid')) {
          _this.send('save', actionName);
        } else {
          _this.set('loading', false);
          _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
        }
      });
    },

    save: function save(actionName) {
      var _this2 = this;

      this.get('model').save().then(function () {
        _this2.sendAction(actionName);
      }, function (error) {
        _this2.set('loading', false);
        _this2.sendAction('error', error);
      });
    },

    actions: {
      validate: function validate() {
        this.validate('success');
      },
      save: function save(actionName) {
        this.save(actionName);
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.sendAction('cancel');
      }
    }
  });

  ModelForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = ModelForm;
});