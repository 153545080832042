define('edibroker/routes/language/administration/user/detail/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    controllerName: 'language/administration/user/create',
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('language.administration.user.detail').set('isEditPage', true);
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        this.controllerFor('language.administration.user.detail').set('isEditPage', false);
      }
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('language.administration.user.detail');
      },

      success: function success() {
        this.get('toast').success(this.get('intl').t('user.edit-success'));
        this.transitionTo('language.administration.user.detail');
        this.get('controller.model.members').forEach(function (member) {
          return member.reload();
        });
        this.get('controller.model.members').map(function (member) {
          return member.get('messages');
        }).reduce(function (a, b) {
          return a.concat(b);
        }, []).forEach(function (message) {
          return message.reload();
        });
      }
    }
  });
});