define('edibroker/components/inputs/file-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _emberUploader['default'].FileField.extend({
    multiple: true,
    method: 'POST',
    success: _ember['default'].K,
    failure: _ember['default'].K,
    updateLabel: _ember['default'].K,
    start: false,
    array: _ember['default'].A([]),
    files: _ember['default'].A([]),

    // Override the default implementation as it does not trigger 'filesDidChange' when files is empty.
    change: function change(event) {
      var _this = this;

      this.set('_files', event.target.files);
      var j = Array.from(this.get('_files'));
      j.forEach(function (e) {
        return _this.get('array').pushObject(e);
      });
      this.get('updateLabel')(this.get("_files"));
    },

    startUpload: _ember['default'].observer('_files.[]', 'start', function () {
      var _this2 = this;

      if (!_ember['default'].isEmpty(this.get('_files')) && this.get('start')) {

        var uploader = _emberUploader['default'].Uploader.create({
          url: this.get('url'),
          method: this.get('method')
        });

        var args = undefined;
        if (this.get("filesUpload")) {
          this.get('files').pushObject(this.get('array'));
          args = this.get('files');
        } else {
          args = [this.get('_files')[0]];
        }
        var data = this.get('data');
        if (data) {
          args.push({ data: data });
        }

        uploader.upload.apply(uploader, _toConsumableArray(args)).then(function (data) {
          _this2.get('files').clear();
          _this2.get('array').clear();
          _this2.get('updateLabel')(_this2.get("files"));
          _this2.$().val('');

          _this2.get('success')(data);
        }, function (error) {
          _this2.$().val('');
          _this2.get('failure')(error);
        });
      }
    })
  });
});