define('edibroker/controllers/language/administration/accesstoken/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    rendered: false,
    loading: true,
    showDeleteModal: false,
    showExtendModal: false,
    showRenewalModal: false,

    filter: _ember['default'].computed.alias('model.filter'),
    accessTokens: _ember['default'].computed.alias('model.accessTokens'),

    filterChange: _ember['default'].observer('filter.{memberIdsIn.[],sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterAccessTokens();
      }
    }),

    filterAccessTokens: function filterAccessTokens() {
      var _this = this;

      this.store.query('access-token', this.get('filter')).then(function (accessTokens) {
        if (!_ember['default'].get(accessTokens, 'length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('accessTokens', accessTokens);
        _this.set('loading', false);
      })['catch'](function (errors) {
        _this.send('error', errors);
      });
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    updateToken: function updateToken(tokenKey, action, successMsg, defer) {
      var _this2 = this;

      var accessToken = this.get(tokenKey);
      this.set(tokenKey, null);
      _ember['default'].$.ajax({
        url: '/api/accesstokens/' + accessToken.get('id') + '/' + action,
        method: 'POST'
      }).then(function () {
        defer.resolve();
        _this2.get('toast').success(_this2.get('intl').t(successMsg));
        accessToken.reload();
      }).fail(function (error) {
        defer.resolve();
        _this2.send('error', error.responseJSON);
      });
    },

    actions: {

      reset: function reset() {
        this.set('selectedMembers', []);
        return true;
      },

      askDelete: function askDelete(accessToken) {
        this.set('accessTokenToDelete', accessToken);
        this.set('showDeleteModal', true);
      },

      askExtend: function askExtend(accessToken) {
        this.set('accessTokenToExtend', accessToken);
        this.set('showExtendModal', true);
      },

      askRenewal: function askRenewal(accessToken) {
        this.set('accessTokenToRenew', accessToken);
        this.set('showRenewalModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        var accessToken = this.get('accessTokenToDelete');
        this.set('accessTokenToDelete', null);
        accessToken.destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('accesstoken.delete-success'));
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      },

      proceedExtend: function proceedExtend(defer) {
        this.updateToken('accessTokenToExtend', 'extend', 'accesstoken.extend-success', defer);
      },

      proceedRenewal: function proceedRenewal(defer) {
        this.updateToken('accessTokenToRenew', 'renew', 'accesstoken.renewal-success', defer);
      }
    }

  });
});