define('edibroker/routes/language/profile/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    templateName: 'language/administration/user/detail/index',
    controllerName: 'language/administration/user/detail/index',

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isProfilePage', true);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isProfilePage', false);
      }
    }
  });
});