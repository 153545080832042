define('edibroker/models/base-message', ['exports', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _emberData, _emberCpValidations, _ember) {
  var attr = _emberData['default'].attr;

  var inValidatorOptions = {
    presence: true,
    disabled: _ember['default'].computed.alias('model.isOutOnly').readOnly(),
    messageKey: 'error.validations.inConfigurationIncomplete'
  };

  var outValidatorOptions = {
    presence: true,
    disabled: _ember['default'].computed.alias('model.isInOnly').readOnly(),
    messageKey: 'error.validations.outConfigurationIncomplete'
  };

  exports.outValidatorOptions = outValidatorOptions;
  var Validations = (0, _emberCpValidations.buildValidations)({

    type: (0, _emberCpValidations.validator)('presence', true),
    inFormat: (0, _emberCpValidations.validator)('presence', inValidatorOptions),
    inVersion: (0, _emberCpValidations.validator)('presence', inValidatorOptions),
    outFormat: (0, _emberCpValidations.validator)('presence', outValidatorOptions),
    outVersion: (0, _emberCpValidations.validator)('presence', outValidatorOptions)
  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {

    type: attr('string'),
    inVersion: attr('string'),
    inFormat: attr('string'),
    outVersion: attr('string'),
    outFormat: attr('string'),

    isIn: _ember['default'].computed.or('inFormat', 'inVersion').readOnly(),
    isOut: _ember['default'].computed.or('outFormat', 'outVersion').readOnly(),
    isInOnly: _ember['default'].computed('isIn', 'isOut', function () {
      return this.get('isIn') && !this.get('isOut');
    }).readOnly(),
    isOutOnly: _ember['default'].computed('isIn', 'isOut', function () {
      return this.get('isOut') && !this.get('isIn');
    }).readOnly()
  });
});