define('edibroker/controllers/language/administration/message/detail/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    showDeleteModal: false,

    actions: {

      askDelete: function askDelete() {
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this = this;

        this.get('model').destroyRecord().then(function () {
          defer.resolve();
          _this.get('toast').success(_this.get('intl').t('message.delete-success'));
          _this.transitionToRoute('language.administration.message');
        }, function (error) {
          defer.resolve();
          _this.send('error', error);
        });
      }
    }
  });
});