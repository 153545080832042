define('edibroker/routes/language/stats', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model() {
      var filter = this.emptyFilter();
      return _ember['default'].RSVP.hash({
        filter: filter,
        stats: {
          deposit: { count: 0, messages: 0, size: 0 },
          withdraw: { count: 0, messages: 0, size: 0 },
          messages: [],
          total: {
            OK_FLAT: 0,
            OK_XML: 0,
            KO_FLAT: 0,
            KO_XML: 0,
            warnings_FLAT: 0,
            warnings_XML: 0,
            total_FLAT: 0,
            total_XML: 0
          }
        }
      });
    },

    emptyFilter: function emptyFilter() {
      return {
        senderIdsIn: [],
        receiverIdsIn: [],
        depositStart: undefined,
        depositEnd: undefined,
        conversionDateStart: undefined,
        conversionDateEnd: undefined,
        lastReadyStart: undefined,
        lastReadyEnd: undefined,
        lastWithdrawStart: undefined,
        lastWithdrawEnd: undefined,
        msgTypesIn: [],
        statusesIn: [],
        inputProtocolsIn: [],
        outputProtocolsIn: [],
        inputVersionsIn: [],
        outputVersionsIn: [],
        inputFormatsIn: [],
        outputFormatsIn: []
      };
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.fetchStats();
      });
    },

    resetController: function resetController(controller) {
      controller.set('rendered', false);
      controller.set('loading', true);
      controller.set('selectedSenders', []);
      controller.set('selectedReceivers', []);
    },

    actions: {
      reset: function reset() {
        this.set('controller.filter', this.emptyFilter());
      }
    }

  });
});