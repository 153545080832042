define("edibroker/templates/components/user/preference-table", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 8
          }
        },
        "moduleName": "edibroker/templates/components/user/preference-table.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table table-bordered table-hover");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("caption");
        dom.setAttribute(el2, "class", "sr-only");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "scope", "col");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "scope", "col");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("em");
        dom.setAttribute(el5, "class", "small text-muted");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("em");
        dom.setAttribute(el5, "class", "small text-muted");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("em");
        dom.setAttribute(el5, "class", "small text-muted");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element2, [3]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element2, [5]);
        var element8 = dom.childAt(element7, [3]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element4, 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [2]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(element6, 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element6, [2]), 0, 0);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
        morphs[10] = dom.createMorphAt(element8, 0, 0);
        morphs[11] = dom.createMorphAt(dom.childAt(element8, [2]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["user.settings.title"], [], ["loc", [null, [2, 29], [2, 56]]], 0, 0], ["inline", "t", ["user.settings.key"], [], ["loc", [null, [5, 28], [5, 53]]], 0, 0], ["inline", "t", ["user.settings.value"], [], ["loc", [null, [6, 28], [6, 55]]], 0, 0], ["inline", "t", ["user.settings.language"], [], ["loc", [null, [11, 16], [11, 46]]], 0, 0], ["content", "preference.language.value", ["loc", [null, [12, 16], [12, 45]]], 0, 0, 0, 0], ["inline", "if", [["get", "preference.language.isDefault", ["loc", [null, [12, 80], [12, 109]]], 0, 0, 0, 0], ["subexpr", "t", ["user.settings.default"], [], ["loc", [null, [12, 110], [12, 137]]], 0, 0]], [], ["loc", [null, [12, 75], [12, 139]]], 0, 0], ["inline", "t", ["user.settings.size"], [], ["loc", [null, [15, 16], [15, 42]]], 0, 0], ["content", "preference.pageSize.value", ["loc", [null, [16, 16], [16, 45]]], 0, 0, 0, 0], ["inline", "if", [["get", "preference.pageSize.isDefault", ["loc", [null, [16, 80], [16, 109]]], 0, 0, 0, 0], ["subexpr", "t", ["user.settings.default"], [], ["loc", [null, [16, 110], [16, 137]]], 0, 0]], [], ["loc", [null, [16, 75], [16, 139]]], 0, 0], ["inline", "t", ["user.settings.menu"], [], ["loc", [null, [19, 16], [19, 42]]], 0, 0], ["content", "preference.menuState.value", ["loc", [null, [20, 16], [20, 46]]], 0, 0, 0, 0], ["inline", "if", [["get", "preference.menuState.isDefault", ["loc", [null, [20, 81], [20, 111]]], 0, 0, 0, 0], ["subexpr", "t", ["user.settings.default"], [], ["loc", [null, [20, 112], [20, 139]]], 0, 0]], [], ["loc", [null, [20, 76], [20, 141]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});