define("edibroker/templates/components/sort-link", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/components/sort-link.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["element", "action", ["sort"], [], ["loc", [null, [1, 12], [1, 29]]], 0, 0], ["content", "label", ["loc", [null, [1, 30], [1, 39]]], 0, 0, 0, 0], ["inline", "sort-icon", [], ["propertyValue", ["subexpr", "@mut", [["get", "sortBy", ["loc", [null, [2, 26], [2, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "sort", ["subexpr", "@mut", [["get", "sort", ["loc", [null, [2, 38], [2, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "sortSep", ["subexpr", "@mut", [["get", "sortSep", ["loc", [null, [2, 51], [2, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 60]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});