define('edibroker/services/constants', ['exports', 'ember'], function (exports, _ember) {
  exports.formatSftpConf = formatSftpConf;
  exports.getOrCreateStatus = getOrCreateStatus;
  exports.updatePreference = updatePreference;
  exports.updateSubscription = updateSubscription;
  exports.updateSubscriptionMessages = updateSubscriptionMessages;
  exports.updateUserStatus = updateUserStatus;
  exports.getErrorKey = getErrorKey;
  exports.getEnumConstants = getEnumConstants;

  /**
   * Constants for mail notifications.
   */
  var MAIL_NOTIFICATIONS = ['uploadAcknowledged', 'processingAcknowledged', 'retrievalAcknowledged', 'waning', 'sftpWarning', 'sftpError'];

  exports.MAIL_NOTIFICATIONS = MAIL_NOTIFICATIONS;
  /**
   * Constants for user preferences.
   */
  var USER_PREFERENCES = ['pageSize', 'menuState', 'language'];

  exports.USER_PREFERENCES = USER_PREFERENCES;
  /**
   * Constants for available page size.
   */
  var PAGE_SIZES = [10, 20, 50, 100];

  exports.PAGE_SIZES = PAGE_SIZES;
  /**
   * Constants for available menu states.
   */
  var MENU_STATES = ['collapsed', 'expanded'];

  exports.MENU_STATES = MENU_STATES;
  /**
   * Constants for enumeration Civility
   */
  var CIVILITY = ['MR', 'MRS'];

  exports.CIVILITY = CIVILITY;
  /**
   * Constants for enumeration UserProfile
   */
  var USER_PROFILES = ['NETPROASSUR', 'MEMBER_ADMIN', 'EDI_MANAGER', 'MEMBER_SPECIAL_USER', 'MEMBER_BASIC_USER'];

  exports.USER_PROFILES = USER_PROFILES;
  /**
   * Constants for message types.
   */
  var MESSAGE_TYPES = ['500', '502', '503', '504', '506', '508', '509', '602', '603', '606', '608', '609', '4DI', '801', '802', '803', '811'];

  exports.MESSAGE_TYPES = MESSAGE_TYPES;
  /**
   * Constants for enumeration MemberType
   */
  var MEMBER_TYPES = ['INSURANCE', 'BROKER'];

  exports.MEMBER_TYPES = MEMBER_TYPES;
  /**
   * Constants for enumeration MemberStatus
   */
  var MEMBER_STATUSES = ['ENABLED', 'DISABLED'];

  exports.MEMBER_STATUSES = MEMBER_STATUSES;
  /**
   * Constants for enumeration AnomalyLang
   */
  var ANOMALY_LANGUAGES = ['FR', 'EN'];

  exports.ANOMALY_LANGUAGES = ANOMALY_LANGUAGES;
  /**
   * Constants for enumeration MessageFormat
   */
  var MESSAGE_FORMATS = ['XML', 'FLAT', 'ANY'];

  exports.MESSAGE_FORMATS = MESSAGE_FORMATS;
  /**
   * Constants for enumeration MessageFormat
   */
  var MESSAGE_VERSIONS = ['BB', 'V01', 'V02', 'V03', 'ANY'];

  exports.MESSAGE_VERSIONS = MESSAGE_VERSIONS;
  /**
   * Constants for enumeration ProtocolType
   */
  var PROTOCOL_TYPES = ['SFTP', 'WEBSERVICE', 'UPLOAD', 'SFTP_PUSH'];

  exports.PROTOCOL_TYPES = PROTOCOL_TYPES;
  /**
   * Constants for Sftp Push type
   */
  var SFTP_PUSH_TYPES = ['NONE', 'DEFAULT', 'SPECIFIC'];

  exports.SFTP_PUSH_TYPES = SFTP_PUSH_TYPES;
  /**
   * Constants for enumeration IdentificationStatus
   */

  var IDENTIFICATION_STATUSES = ['ENABLED', 'DISABLED'];
  exports.IDENTIFICATION_STATUSES = IDENTIFICATION_STATUSES;
  /**
   * Constants for enumeration InfoType
   */
  var INFO_TYPES = ['SUCCESS', 'INFO', 'WARNING', 'DANGER'];
  exports.INFO_TYPES = INFO_TYPES;
  /**
   * Constants for valid document extensions
   */
  var DOCUMENT_EXTENSIONS = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'xml', 'xsd', 'txt', 'zip', 'xlsm'];

  exports.DOCUMENT_EXTENSIONS = DOCUMENT_EXTENSIONS;
  /**
   * Constants for enumeration TransferType
   */
  var TRANSFER_TYPES = ['RECEIVED', 'SENT'];

  exports.TRANSFER_TYPES = TRANSFER_TYPES;
  /**
   * Constants for enumeration FileStatus
   */
  var FILE_STATUSES = ['OK', 'KO', 'IN_PROGRESS', 'DELETED'];

  exports.FILE_STATUSES = FILE_STATUSES;
  /**
   * Constants for enumeration FileSearchStatus
   */
  var FILE_SEARCH_STATUSES = ['OK', 'KO', 'IN_PROGRESS', 'DELETED'];

  exports.FILE_SEARCH_STATUSES = FILE_SEARCH_STATUSES;
  /**
   * Constants for valid uploaded file extensions
   */
  var FILE_UPLOAD_EXTENSIONS = ['txt', 'xml'];

  exports.FILE_UPLOAD_EXTENSIONS = FILE_UPLOAD_EXTENSIONS;
  var VERSIONS_BY_MESSAGE = {
    '500': ['V01', 'V02'],
    '502': ['BB', 'V02'],
    '503': ['V01', 'V02'],
    '504': ['BB'],
    '506': ['V01', 'V02'],
    '508': ['V01', 'V02'],
    '509': ['V01', 'V02'],
    '602': ['V02'],
    '603': ['V02'],
    '606': ['V02'],
    '608': ['V02'],
    '609': ['V02'],
    '4DI': ['BB'],
    '801': ['V02'],
    '802': ['V02'],
    '803': ['V02'],
    '811': ['V03']
  };

  exports.VERSIONS_BY_MESSAGE = VERSIONS_BY_MESSAGE;
  /**
   * Function to format SFTP configuration in the form below :
   * <code>[username@]host:port[/targetDir]</code>
   *
   * @param {Object}
   *          model the model holding the SFTP configuration
   */

  function formatSftpConf(model) {
    if (model && model.get('sftpHost')) {
      var user = model.get('sftpUser');
      var value = user ? user + '@' : '';
      value += model.get('sftpHost') + ':' + (model.get('sftpPort') || 22);
      var target = model.get('sftpTarget');
      value += target ? '/' + target : '';
      return value;
    } else {
      return '';
    }
  }

  /**
   * Function to retrieve or initialize a status object from a transition.
   *
   * @param {Object}
   *          transition the transition holding the status
   */

  function getOrCreateStatus(transition) {
    var status = _ember['default'].get(transition, 'data.transitionStatus');
    if (!status) {
      status = {
        shouldConfirm: false,
        resetCallbacks: []
      };
      _ember['default'].set(transition, 'data.transitionStatus', status);
    }
    return status;
  }

  /**
   * Function to update a specific user preference.
   *
   * @param {Number}
   *          id the user identifier
   * @param {String}
   *          key the preference key to update
   * @param {String}
   *          value the new value
   */

  function updatePreference(id, key, value) {
    var data = { key: key, value: value };
    _ember['default'].$.ajax({
      url: '/api/users/' + id + '/preferences',
      method: 'POST',
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json'
    }).then(function () {
      _ember['default'].Logger.info(key + ' preference updated');
    }).fail(function (response) {
      _ember['default'].Logger.error(key + ' preference update failed: ', response);
    });
  }

  /**
   * Function to update a specific mail notification subscription.
   *
   * @param {Number}
   *          id the user identifier
   * @param {String}
   *          notification the notification key to update
   * @param {Boolean}
   *          subscribed the new subscription state
   */

  function updateSubscription(id, notification, subscribed, messagesSubscriptions) {
    var data = { notification: notification, subscribed: subscribed, messagesSubscriptions: messagesSubscriptions };
    _ember['default'].$.ajax({
      url: '/api/users/' + id + '/subscriptions',
      method: 'POST',
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json'
    }).then(function () {
      _ember['default'].Logger.info(notification + ' subscription updated');
    }).fail(function (response) {
      _ember['default'].Logger.error(notification + ' subscription update failed: ', response);
    });
  }

  /**
   * Function to update a specific mail notification subscription to messages types.
   *
   * @param {Number}
   *          id the user identifier
   * @param {String}
   *          notification the notification key to update
   * @param {Boolean}
   *          subscribed the new subscription state
   * @param {Array}
   *          List of messages to subscribe to
   */

  function updateSubscriptionMessages(id, notification, subscribed, messagesSubscriptions) {
    var data = { notification: notification, subscribed: subscribed, messagesSubscriptions: messagesSubscriptions };
    _ember['default'].$.ajax({
      url: '/api/users/' + id + '/subscription//messages',
      method: 'POST',
      data: JSON.stringify(data),
      dataType: 'json',
      contentType: 'application/json'
    }).then(function () {
      _ember['default'].Logger.info(notification + ' subscription\' messages updated');
    }).fail(function (response) {
      _ember['default'].Logger.error(notification + ' subscription\' messages update failed: ', response);
    });
  }

  /**
   * Function to update the user status
   *
   * @param {Number}
   *          id the user identifier
   * @param {String}
   *          status the new status
   * @param {Function}
   *          success the success callback
   * @param {Function}
   *          failure the failure callback
   */

  function updateUserStatus(id, status, success, failure) {
    _ember['default'].$.ajax({
      url: '/api/users/' + id + '/status',
      method: 'PUT',
      data: {
        status: status
      }
    }).then(function () {
      return success();
    }).fail(function (response) {
      return failure(response);
    });
  }

  /**
   * Function to retrieve the message key from a given base.
   *
   * @param {String}
   *          base the base
   * @param {Function}
   *          exists the function to check if the resulting key exists in a catalog
   * @param {String}
   *          defaultValue to return if the resulting key does not exist or if the base does not contain a key.
   */

  function getErrorKey(base, exists) {
    var defaultValue = arguments.length <= 2 || arguments[2] === undefined ? 'error.back.generic' : arguments[2];

    var key = undefined;
    if (base.indexOf('key:') === 0) {
      key = base.replace('key:', '');
      if (!exists(key)) {
        _ember['default'].Logger.warn('Error key could not be found: ', key);
        key = defaultValue;
      }
    } else {
      key = defaultValue;
    }
    return key;
  }

  /**
   * Function retrieving all the constants of a given enumeration.
   *
   * @param {String}
   *          type the enumeration type to retrieve
   */

  function getEnumConstants(type) {
    var isNetProassur = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

    // WARNING ! Do not forget to clone the returned array to ensure we don't modify the constant.
    switch (type) {
      case 'AnomalyLang':
        return ANOMALY_LANGUAGES.slice(0);
      case 'Civility':
        return CIVILITY.slice(0);
      case 'InfoType':
        return INFO_TYPES.slice(0);
      case 'FileStatus':
        return FILE_STATUSES.slice(0);
      case 'FileSearchStatus':
        return FILE_SEARCH_STATUSES.slice(0);
      case 'MemberType':
        return MEMBER_TYPES.slice(0);
      case 'MemberStatus':
        return MEMBER_STATUSES.slice(0);
      case 'MessageFormat':
        return MESSAGE_FORMATS.slice(0);
      case 'MessageVersion':
        return MESSAGE_VERSIONS.slice(0);
      case 'ProtocolType':
        return PROTOCOL_TYPES.slice(0);
      case 'SftpPushType':
        return SFTP_PUSH_TYPES.slice(0);
      case 'UserProfile':
        var profiles = USER_PROFILES.slice(0);
        if (!isNetProassur) {
          profiles.removeObject('NETPROASSUR');
          profiles.removeObject('EDI_MANAGER');
        }
        return profiles;
      case 'TransferType':
        return TRANSFER_TYPES.slice(0);
      default:
        return [];
    }
  }

  /**
   * Service for application constants.
   */
  exports['default'] = _ember['default'].Service.extend({});
});