define('edibroker/components/confirm-dialog', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Popup modal that can be used as a confirm dialog. The useSpinner flag can be
   * used to display a spinning icon inside the submit button while handling the
   * request. The isDelete flag can be set to use btn-danger class on the submit
   * button. The modal visibility is controlled with the isVisible parameter.
   * 
   * Exemple
   * 
   * ```hbs 
   * {{#confirm-dialog proceed='confirm' isVisible=controller.isVisible useSpinner=true title='Modal title'}}
   *    <p>Modal body</p>
   * {{/confirm-dialog}
   * ```
   * 
   * When using the useSpinner flag, a callback is provided to the action handler.
   * Use the <code>resolve()</code> function when done to hide the modal (as in
   * success case) or the <code>reject()</code> function to keep the modal
   * displayed (as in error case).
   * 
   * Example
   * 
   * ```js 
   * { 
   *    actions: { 
   *      confirm: function(defer) { 
   *        if(...success...) {
   *          defer.resolve(); 
   *        } else {
   *          defer.reject(); 
   *        }
   *      }
   *    }
   *  }
   * ```
   * 
   * @param proceed
   *          {String} action name to be sent on submit click. Default to `action`
   * @param close
   *          {String} action name to be sent on cancel click.
   * @param title
   *          {String} modal title.
   * @param isVisible
   *          {boolean} control the modal display.
   * @param useSpinner
   *          {boolean} control whether a spinning icon should be used on
   *          submitting or not. default to false.
   * @param isDelete
   *          {boolean} control whether the submit button should use btn-danger or
   *          btn-primary class. default to false.
   */
  exports['default'] = _ember['default'].Component.extend({

    isVisible: false,

    useSpinner: false,

    isProceeding: false,

    isDelete: false,

    actions: {
      close: function close() {
        this.toggleProperty('isVisible');
        this.sendAction('close');
        this.set('isProceeding', false);
      },
      proceed: function proceed() {
        if (this.get('isProceeding')) {
          return;
        }

        if (this.get('useSpinner')) {
          this.toggleProperty('isProceeding');
          this.sendAction('proceed', this.defer());
        } else {
          this.toggleProperty('isVisible');
          this.sendAction('proceed');
        }
      }
    },

    defer: function defer() {
      var _this = this;

      return {
        resolve: function resolve() {
          _this.set('isProceeding', false);
          _this.set('isVisible', false);
        },
        reject: function reject() {
          _this.set('isProceeding', false);
          _this.set('isVisible', true);
        }
      };
    }
  });
});