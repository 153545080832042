define('edibroker/controllers/language/administration/certificate/detail', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    file: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    upload: false,
    didValidate: false,
    showUpdateModal: false,
    showDeleteModal: false,
    showRenewalModal: false,

    label: _ember['default'].computed(function () {
      return this.get('intl').t('common.file');
    }),

    updateUrl: _ember['default'].computed('model.id', function () {
      return '/api/certificates/' + this.get('model.id');
    }),

    resetValidation: function resetValidation() {
      this.set('file', undefined);
      this.set('didValidate', false);
    },

    actions: {

      success: function success(data) {
        var defer = this.get('uploadDefer');
        this.set('uploadDefer', null);
        if (defer) {
          defer.resolve();
        }
        this.set('upload', false);
        this.resetValidation();
        this.get('toast').success(this.get('intl').t('certificate.edit-success'));
        this.transitionToRoute('language.administration.certificate.detail', data.id);
      },

      failure: function failure(error) {
        var defer = this.get('uploadDefer');
        this.set('uploadDefer', null);
        if (defer) {
          defer.resolve();
        }
        this.set('upload', false);
        this.resetValidation();
        this.send('error', error.responseJSON);
      },

      askUpdate: function askUpdate() {
        this.set('showUpdateModal', true);
      },

      proceedUpdate: function proceedUpdate(defer) {
        var _this = this;

        this.set('didValidate', true);
        this.validate().then(function () {
          if (_this.get('validations.isValid')) {
            _this.set('upload', true);
            _this.set('uploadDefer', defer);
          } else {
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
            defer.reject();
          }
        });
      },

      cancelUpdate: function cancelUpdate() {
        this.resetValidation();
      },

      askDelete: function askDelete() {
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this2 = this;

        this.get('model').destroyRecord().then(function () {
          defer.resolve();
          _this2.get('toast').success(_this2.get('intl').t('certificate.delete-success'));
          _this2.transitionToRoute('language.administration.certificate');
        }, function (error) {
          defer.resolve();
          _this2.send('error', error);
        });
      },

      askRenewal: function askRenewal() {
        this.set('showRenewalModal', true);
      },

      proceedRenewal: function proceedRenewal(defer) {
        var _this3 = this;

        _ember['default'].$.ajax({
          url: '/api/certificates/' + this.get('model.id') + '/renew',
          method: 'POST'
        }).then(function (data) {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('certificate.renewal-success'));
          _this3.transitionToRoute('language.administration.certificate.detail', data.id);
        }).fail(function (error) {
          _this3.resetValidation();
          defer.resolve();
          _this3.send('error', error.responseJSON);
        });
      }
    }
  });
});