define("edibroker/templates/components/inputs/member-autocomplete", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "edibroker/templates/components/inputs/member-autocomplete.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "member-display", [["get", "member", ["loc", [null, [11, 21], [11, 27]]], 0, 0, 0, 0]], ["noLink", true], ["loc", [null, [11, 4], [11, 41]]], 0, 0]],
        locals: ["member"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/components/inputs/member-autocomplete.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "inputs/p-select", [["get", "model", ["loc", [null, [1, 19], [1, 24]]], 0, 0, 0, 0], ["get", "attribute", ["loc", [null, [1, 25], [1, 34]]], 0, 0, 0, 0], ["get", "subAttribute", ["loc", [null, [1, 35], [1, 47]]], 0, 0, 0, 0]], ["label", ["subexpr", "@mut", [["get", "label", ["loc", [null, [2, 10], [2, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "search", ["subexpr", "action", ["searchMembers"], [], ["loc", [null, [3, 11], [3, 35]]], 0, 0], "selected", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [4, 13], [4, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "didValidate", ["subexpr", "@mut", [["get", "didValidate", ["loc", [null, [5, 16], [5, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "isMultiple", ["subexpr", "@mut", [["get", "isMultiple", ["loc", [null, [6, 15], [6, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "labelClass", ["subexpr", "@mut", [["get", "labelClass", ["loc", [null, [7, 15], [7, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "inputClass", ["subexpr", "@mut", [["get", "inputClass", ["loc", [null, [8, 15], [8, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [12, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});