define('edibroker/serializers/model', ['exports', 'ember-data', 'edibroker/serializers/application'], function (exports, _emberData, _edibrokerSerializersApplication) {
  exports['default'] = _edibrokerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      messages: {
        deserialize: 'ids',
        serialize: false
      },
      msgTypes: {
        serialize: false
      }
    }
  });
});