define('edibroker/mixins/unauthenticated-route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Mixin.create(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated')) {
        transition.abort();
        return this.transitionTo('language', this.get('intl.currentLanguage'));
      }
      return this._super.apply(this, arguments);
    }
  });
});