define('edibroker/helpers/date-display', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /**
    This helper is used to display a date.
  
    Example:
    ```hbs
    {{date-display myValue}}
    ```
  */
  exports['default'] = _ember['default'].Helper.extend({
    intl: _ember['default'].inject.service(),

    onLanguageChange: _ember['default'].observer('intl.currentLanguage', function () {
      this.recompute();
    }),

    compute: function compute(_ref, _ref3) {
      var _ref2 = _slicedToArray(_ref, 1);

      var value = _ref2[0];
      var withTime = _ref3.withTime;

      _ember['default'].Logger.debug('Displaying date ' + value + ' in language ' + this.get('intl.currentLanguage'));
      if (value) {
        var intl = this.get('intl');
        return withTime ? intl.formatDateTimeWithMoment(value) : intl.formatDateWithMoment(value);
      } else {
        return "";
      }
    }
  });
});