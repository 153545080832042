define('edibroker/controllers/language/document/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    didValidate: false,
    editAttachment: false,

    method: _ember['default'].computed('isCreatePage', function () {
      return this.get('isCreatePage') ? 'POST' : 'PUT';
    }),

    url: _ember['default'].computed('isCreatePage', function () {
      if (this.get('isCreatePage')) {
        return '/api/documents/upload';
      } else {
        return '/api/documents/upload/' + this.model.id;
      }
    }),
    label: _ember['default'].computed('model.attachmentName', function () {
      var fileName = this.get('model.attachmentName');
      return fileName ? fileName : this.get('intl').t('common.file');
    }),
    uploadAttachemnt: _ember['default'].computed.or('editAttachment', 'isCreatePage'),

    data: _ember['default'].computed('model.{name,description}', function () {
      var serialized = this.get('model').serialize();
      return new Blob([JSON.stringify(serialized)], { type: 'application/json;charset=UTF-8' });
    }),

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {

      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.get('model').validate().then(function (result) {
          _this.set('didValidate', true);
          if (_ember['default'].get(result, 'validations.isValid')) {
            if (_this.get('uploadAttachemnt')) {
              _this.set('upload', true);
            } else {
              _this.send('save');
            }
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      },

      success: function success(data) {
        this.set('upload', false);
        var model = this.get('model');
        var creating = model.get('isNew');
        model.rollbackAttributes();
        if (creating) {
          this.get('toast').success(this.get('intl').t('document.create-success'));
          this.transitionToRoute('language.document.detail', data.id);
        } else {
          model.reload();
          this.get('toast').success(this.get('intl').t('document.edit-success'));
          this.transitionToRoute('language.document.detail', model);
        }
      },

      failure: function failure(error) {
        this.set('upload', false);
        this.send('error', error.responseJSON);
        this.set('loading', false);
        this.set('model.attachmentName', undefined);
      }
    },

    editAttachmentChanged: _ember['default'].observer('editAttachment', function () {
      if (this.get('editAttachment')) {
        this.set('fileName', this.get('model.attachmentName'));
        this.set('model.attachmentName', undefined);
      } else {
        this.set('model.attachmentName', this.get('fileName'));
      }
    })
  });
});