define('edibroker/routes/language/administration/model/detail', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    templateName: 'language.administration.member.detail',
    controllerName: 'language.administration.member.detail',

    intl: _ember['default'].inject.service(),

    model: function model(params) {
      return this.store.findRecord('model', params.id);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isModelPage', true);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').updateTransitionStatus(transition, this.get('routeName'));
        return true;
      }
    }
  });
});