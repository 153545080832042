define('edibroker/components/inputs/v-input', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Input with validation components. When an edit action is provided, the input
   * is displayed as readonly, and an edit button is displayed next to the control
   *
   * ```hbs
   * {{inputs/v-input model 'lastName' label='model.user.lastName'}}
   * ```
   */
  var ValidatedInputComponent = _ember['default'].Component.extend({
    inputSequence: _ember['default'].inject.service(),

    tagName: 'div',
    classNames: ['form-group'],
    classNameBindings: ['showErrorMessage:has-error', 'showWarningMessage:has-warning', 'isValid:has-success', 'showErrorMessageSize:has-error', 'showWarningMessageSize:has-warning', 'isValidSize:has-success'],

    labelClass: 'col-sm-2',
    inputClass: 'col-sm-4',

    type: 'text',
    disabled: false,
    didValidate: false,
    readonly: false,

    hasContent: _ember['default'].computed('value', function () {
      var value = this.get('value');
      if (value && value.hasOwnProperty('content')) {
        return !_ember['default'].isEmpty(_ember['default'].get(value, 'content'));
      } else {
        return !_ember['default'].isEmpty(value);
      }
    }).readOnly(),

    hasSize: _ember['default'].computed('sizeValue', function () {
      var value = this.get('sizeValue');
      if (value && value.hasOwnProperty('content')) {
        return !_ember['default'].isEmpty(_ember['default'].get(value, 'content'));
      } else {
        return !_ember['default'].isEmpty(value);
      }
    }).readOnly(),
    isValid: _ember['default'].computed.and('hasContent', 'validation.isTruelyValid', 'hasSize').readOnly(),
    shouldDisplayValidations: _ember['default'].computed.or('didValidate', 'hasContent', 'hasSize').readOnly(),
    showErrorMessage: _ember['default'].computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: _ember['default'].computed.and('shouldDisplayValidations', 'validation.warning').readOnly(),

    isValidSize: _ember['default'].computed.and('hasSize', 'validationSize.isTruelyValid').readOnly(),
    shouldDisplayValidationsSize: _ember['default'].computed.or('didValidate', 'hasSize').readOnly(),
    showErrorMessageSize: _ember['default'].computed.and('shouldDisplayValidations', 'validationSize.isInvalid').readOnly(),
    showWarningMessageSize: _ember['default'].computed.and('shouldDisplayValidations', 'validationSize.warning').readOnly(),

    useEditButton: _ember['default'].computed('edit', function () {
      return !!this.get('edit');
    }).readOnly(),
    shouldBeReadOnly: _ember['default'].computed.or('readonly', 'useEditButton').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('inputId')) {
        this.set('inputId', 'input-' + this.get('inputSequence').next());
      }

      var attribute = this.get('attribute');
      var sizeAttribute = this.get('sizeAttribute');
      _ember['default'].defineProperty(this, 'value', _ember['default'].computed.alias('model.' + attribute));
      _ember['default'].defineProperty(this, 'sizeValue', _ember['default'].computed.alias('model.' + sizeAttribute));
      _ember['default'].defineProperty(this, 'validation', _ember['default'].computed.readOnly('model.validations.attrs.' + attribute));
      _ember['default'].defineProperty(this, 'validationSize', _ember['default'].computed.readOnly('model.validations.attrs.' + sizeAttribute));
    },

    actions: {
      edit: function edit() {
        this.sendAction('edit');
      }
    }
  });

  ValidatedInputComponent.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedInputComponent;
});