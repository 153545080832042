define('edibroker/models/file', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({

    intl: _ember['default'].inject.service(),

    name: attr('string'),
    msgType: attr('string'),
    msgCount: attr('number'),
    processedCount: attr('number'),
    status: attr('string'),
    step: attr('string'),
    rule: attr('string'),
    depositDate: attr('string'),
    firstReadyDate: attr('string'),
    lastReadyDate: attr('string'),
    firstWithdrawDate: attr('string'),
    lastWithdrawDate: attr('string'),
    senderCode: attr('string'),
    senderLabel: attr('string'),
    receiverCode: attr('string'),
    receiverLabel: attr('string'),
    inputProtocol: attr('string'),
    outputProtocol: attr('string'),
    inputVersion: attr('string'),
    outputVersion: attr('string'),
    inputFormat: attr('string'),
    outputFormat: attr('string'),
    purged: attr('boolean'),
    cancellable: attr('boolean'),
    steps: attr(),

    isOk: _ember['default'].computed('status', function () {
      return this.get('status') === 'OK';
    }),
    isKo: _ember['default'].computed('status', function () {
      return this.get('status') === 'KO';
    }),
    isDeletable: _ember['default'].computed('status', function () {
      return this.get('status') !== 'DELETED';
    }),
    isDeleted: _ember['default'].computed('status', function () {
      return this.get('status') === 'DELETED';
    }),
    isFinal: _ember['default'].computed.or('isOk', 'isKo'),
    statusLabel: _ember['default'].computed('status', function () {
      return this.get('intl').t('enums.FileStatus.' + this.get('status'), { step: this.get('step'), errMsg: this.get('rule') });
    })

  });
});