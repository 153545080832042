define('edibroker/routes/language/administration/accesstoken/create', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    model: function model() {
      return this.store.createRecord('access-token');
    },

    resetController: function resetController(controller) {
      controller.set('loading', false);
      controller.set('didValidate', false);
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.administration.accesstoken');
      },

      save: function save() {
        var _this = this;

        this.get('controller.model').save().then(function () {
          _this.get('toast').success(_this.get('intl').t('accesstoken.create-success'));
          _this.transitionTo('language.administration.accesstoken.detail', _this.get('controller.model'));
        }, function (error) {
          _this.set('controller.loading', false);
          _this.send('error', error);
        });
      }
    }
  });
});