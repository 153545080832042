define('edibroker/components/inputs/v-file', ['exports', 'edibroker/components/inputs/v-input', 'ember'], function (exports, _edibrokerComponentsInputsVInput, _ember) {

  /**
   * Validated file upload button
   */

  var ValidatedFile = _edibrokerComponentsInputsVInput['default'].extend({

    intl: _ember['default'].inject.service(),
    method: 'POST',

    filesArray: _ember['default'].A([]),

    empty: true,

    fileName: _ember['default'].computed('value', function () {
      var fileName = this.get('value');
      return fileName ? fileName : this.get('intl').t('common.file');
    }),

    actions: {
      updateFileName: function updateFileName(files) {
        if (_ember['default'].isEmpty(files)) {
          this.set('value', undefined);
          this.set('sizeValue', undefined);
          this.get('filesArray').clear();
          this.set('empty', true);
        } else {

          var filesnames = '';
          var array = Array.from(files);

          for (var j = 0; j < array.length; j++) {
            if (!this.get('filesArray').includes(array[j].name)) {
              this.get('filesArray').pushObject(array[j].name);
            }
          }

          for (var i = 0; i < array.length; i++) {
            filesnames += array[i].name;
          }
          this.set('value', filesnames);
          this.set('sizeValue', array[0].size);
          this.set('empty', false);
        }
      }
    }
  });

  ValidatedFile.reopenClass({
    positionalParams: ['model', 'attribute', 'sizeAttribute']
  });

  exports['default'] = ValidatedFile;
});