define('edibroker/components/export-button', ['exports', 'ember'], function (exports, _ember) {

  /**
    Display an export button.
  
    Example:
    ```hbs
    {{export-button 'members' filter=filter}}
    ```
   */
  var ExportButtonComponent = _ember['default'].Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-primary'],
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    option: undefined,
    labelKey: undefined,

    customLabel: _ember['default'].computed('labelKey', function () {
      return !!this.get('labelKey');
    }),

    click: function click() {
      var language = this.get('intl.currentLanguage');
      var type = this.get('type');
      var filter = this.get('filter');
      var option = this.get('option');
      if (option) {
        filter['option'] = option;
      }
      //filter['authorization'] = this.get('currentUser').token();
      var queryParams = _ember['default'].$.param(filter, true);
      filter['option'] = undefined;
      filter['authorization'] = undefined;

      _ember['default'].$.ajax({
        url: '/api/' + type + '/export/' + language + '?' + queryParams,
        method: 'GET'
      }).then(function (response) {
        var blob = new Blob(['﻿' + response], { type: 'text/csv' });
        var _url = window.URL.createObjectURL(blob);
        window.open(_url, "_blank").focus(); // window.open + focus
      }).fail(function (error) {
        console.log(error);
      });
    }
  });

  ExportButtonComponent.reopenClass({
    positionalParams: ['type', 'filter']
  });

  exports['default'] = ExportButtonComponent;
});