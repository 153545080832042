define('edibroker/routes/language/document/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        filter: this.emptyFilter(),
        documents: []
      });
    },

    emptyFilter: function emptyFilter() {
      return {
        nameStartsWith: '',
        sort: '',
        page: 0,
        size: this.get('currentUser.preferences.pageSize') || 20
      };
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.filterDocuments();
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('rendered', false);
        controller.set('loading', true);
      }
    },

    actions: {
      reset: function reset() {
        this.set('controller.filter', this.emptyFilter());
      }
    }

  });
});