define('edibroker/routes/language/administration/memberprofile/detail/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    templateName: 'language/administration/member/detail/index',
    controllerName: 'language/administration/member/detail/index',

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isProfilePage', true);
      // Update breadcrumb displayed in parent route
      this.controllerFor('language.administration.memberprofile.detail').set('isEditPage', false);
    }

  });
});