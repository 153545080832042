define('edibroker/routes/language/administration/configuration', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-cp-validations'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberCpValidations) {

  var ValidationParams = {
    number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    })],
    string: [(0, _emberCpValidations.validator)('presence', true)]
  };

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return this.get('ajax').request('/api/configuration').then(function (data) {
        var model = {};
        var labels = {};
        var params = _ember['default'].A();
        var validators = {};
        data.forEach(function (param) {
          model[param.key] = param.value;
          labels[param.key] = 'configuration.model.' + param.key;
          validators[param.key] = ValidationParams[param.type];
          params.addObject(param.key);
        });
        model.params = params;
        model.labels = labels;
        model.original_data = model;
        return _ember['default'].Object.extend((0, _emberCpValidations.buildValidations)(validators)).create(_ember['default'].getOwner(_this).ownerInjection(), model);
      });
    },

    resetController: function resetController(controller) {
      controller.set('loading', false);
      controller.set('didValidate', false);
    }

  });
});