define('edibroker/mirage/fixtures/models', ['exports'], function (exports) {
  exports['default'] = [{
    id: 0,
    name: 'First Model Example',
    messageIds: [0, 1],
    msgTypes: ['201', '202']
  }, {
    id: 1,
    name: 'Other Model Example'
  }];
});