define('edibroker/mirage/fixtures/documents', ['exports'], function (exports) {
  exports['default'] = [{
    id: 0,
    name: 'attachment 1',
    descritption: 'attachment description 1',
    attachmentName: 'file.xml',
    attachmentMimeType: 'mime type',
    attachmentSize: 666
  }, {
    id: 1,
    name: 'attachment 2',
    descritption: 'attachment description 2',
    attachmentName: 'file.xml',
    attachmentMimeType: 'mime type',
    attachmentSize: 666
  }, {
    id: 2,
    name: 'attachment 3',
    descritption: 'attachment description 3',
    attachmentName: 'file.xml',
    attachmentMimeType: 'mime type',
    attachmentSize: 666
  }];
});