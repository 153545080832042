define('edibroker/controllers/language/administration/certificate/create', ['exports', 'ember', 'ember-cp-validations', 'edibroker/services/constants'], function (exports, _ember, _emberCpValidations, _edibrokerServicesConstants) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    file: (0, _emberCpValidations.validator)('presence', { presence: true, disabled: _ember['default'].computed.alias('model.isGenerate') })
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    didValidate: false,
    loading: false,
    isGenerate: true,
    upload: false,

    certProtocols: _ember['default'].computed(function () {
      var protocols = _edibrokerServicesConstants.PROTOCOL_TYPES.slice(0);
      protocols.removeObjects(['UPLOAD', 'SFTP_PUSH']);
      return protocols;
    }),

    label: _ember['default'].computed(function () {
      return this.get('intl').t('common.file');
    }),

    data: _ember['default'].computed('model.{protocol,member}', function () {
      var serialized = this.get('model').serialize();
      return new Blob([JSON.stringify(serialized)], { type: 'application/json;charset=UTF-8' });
    }),

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      switchType: function switchType() {
        this.toggleProperty('isGenerate');
        this.set('didValidate', false);
        this.set('file', undefined);
      },

      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        _ember['default'].RSVP.hash({
          model: this.get('model').validate(),
          controller: this.validate()
        }).then(function (hash) {
          _this.set('didValidate', true);
          if (_ember['default'].get(hash.model, 'validations.isValid') && _ember['default'].get(hash.controller, 'validations.isValid')) {
            if (_this.get('isGenerate')) {
              _this.send('save');
            } else {
              _this.set('upload', true);
            }
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      },

      success: function success(data) {
        this.set('upload', false);
        this.get('model').rollbackAttributes();
        this.get('toast').success(this.get('intl').t('certificate.create-success'));
        this.transitionToRoute('language.administration.certificate.detail', data.id);
      },

      failure: function failure(error) {
        this.set('didValidate', false);
        this.set('file', undefined);
        this.set('loading', false);
        this.set('upload', false);
        this.send('error', error.responseJSON);
      }
    }
  });
});