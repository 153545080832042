define('edibroker/models/filter', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    label: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 50 })]
  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {
    label: attr('string'),
    user: belongsTo('user'),
    filterContent: attr('string')
  });
});