define('edibroker/models/user', ['exports', 'ember-data', 'ember-cp-validations', 'ember'], function (exports, _emberData, _emberCpValidations, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var Validations = (0, _emberCpValidations.buildValidations)({
    civility: (0, _emberCpValidations.validator)('presence', true),
    // Display field as valid if filled, but ignore if missing
    firstName: (0, _emberCpValidations.validator)('presence', { presence: true, disabled: _ember['default'].computed.empty('model.firstName'), ignoreBlank: true }),
    lastName: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' }), (0, _emberCpValidations.validator)('unique-email')],
    userProfile: (0, _emberCpValidations.validator)('presence', true),
    members: (0, _emberCpValidations.validator)('member-presence', { presence: true, dependentKeys: ['userProfile'] })

  });

  exports.Validations = Validations;
  exports['default'] = _emberData['default'].Model.extend(Validations, {
    login: attr('string'),
    civility: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    userProfile: attr('string'),
    members: hasMany('member'),
    locked: attr('boolean'),
    preferences: attr(),
    subscriptions: attr(),
    updateAnomalyAddress: attr(),
    messagesSubscriptions: attr()
  });
});