define('edibroker/controllers/language/administration/accesstoken/detail', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    showDeleteModal: false,
    showExtendModal: false,
    showRenewalModal: false,

    updateToken: function updateToken(action, successMsg, defer) {
      var _this = this;

      _ember['default'].$.ajax({
        url: '/api/accesstokens/' + this.get('model.id') + '/' + action,
        method: 'POST'
      }).then(function () {
        defer.resolve();
        _this.get('model').reload();
        _this.get('toast').success(_this.get('intl').t(successMsg));
      }).fail(function (error) {
        defer.resolve();
        _this.send('error', error.responseJSON);
      });
    },

    actions: {

      askDelete: function askDelete() {
        this.set('showDeleteModal', true);
      },

      askExtend: function askExtend() {
        this.set('showExtendModal', true);
      },

      askRenewal: function askRenewal() {
        this.set('showRenewalModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this2 = this;

        this.get('model').destroyRecord().then(function () {
          defer.resolve();
          _this2.get('toast').success(_this2.get('intl').t('accesstoken.delete-success'));
          _this2.transitionToRoute('language.administration.accesstoken');
        }, function (error) {
          defer.resolve();
          _this2.send('error', error);
        });
      },

      proceedExtend: function proceedExtend(defer) {
        this.updateToken('extend', 'accesstoken.extend-success', defer);
      },

      proceedRenewal: function proceedRenewal(defer) {
        this.updateToken('renew', 'accesstoken.renewal-success', defer);
      }
    }

  });
});