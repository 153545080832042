define('edibroker/components/has-access', ['exports', 'ember'], function (exports, _ember) {

  /**
    Simple component that will avoid the rendering of a specific block if the current user does not have the correct permission.
    
    Example:
    ```hbs
    {{#has-access 'MEMBER@read'}}
        ...
    {{/has-access}}
    ```
  */
  var HasAccessComponent = _ember['default'].Component.extend({
    tagName: '',
    currentUser: _ember['default'].inject.service(),

    hasAccess: _ember['default'].computed('permission', function () {
      return this.get('currentUser').hasAccess(this.get('permission'));
    })
  });

  HasAccessComponent.reopenClass({
    positionalParams: ['permission']
  });

  exports['default'] = HasAccessComponent;
});