define('edibroker/mirage/fixtures/messages', ['exports'], function (exports) {
  exports['default'] = [{
    id: 0,
    type: '500',
    inVersion: 'V01',
    inFormat: 'XML',
    outVersion: 'BB',
    outFormat: 'FLAT',
    sftpType: 'NONE'
  }, {
    id: 1,
    type: '202',
    address: 'message.anomaly@mail.fr',
    sftpUser: 'user',
    sftpHost: 'sftp.host',
    sftpTarget: 'message',
    sftpType: 'SPECIFIC'
  }];
});