define('edibroker/validators/unique-edi-code', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var UniqueEdiCode = _emberCpValidationsValidatorsBase['default'].extend({
    unicityCheck: _ember['default'].inject.service(),

    validate: function validate(value, options) /*, attribute*/{
      var model = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      return this.get('unicityCheck').getUnicityPromise('/api/members?code=' + value, 'error.validations.ediCodeUniqueness', _ember['default'].get(model, 'id'));
    }
  });

  UniqueEdiCode.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = UniqueEdiCode;
});