define('edibroker/validators/file-extension', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var DocumentExtensionValidator = _emberCpValidationsValidatorsBase['default'].extend({
    store: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    validate: function validate(value, args) {
      var match = value.match(/\.([^\.]+)$/);
      if (match && _ember['default'].$.inArray(match[1].toLowerCase(), args.get('extensions')) > -1) {
        return true;
      } else {
        return this.get('intl').t(args.message);
      }
    }
  });

  DocumentExtensionValidator.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = DocumentExtensionValidator;
});