define('edibroker/models/info', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;

  var Validations = (0, _emberCpValidations.buildValidations)({

    title: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    message: (0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }),
    type: (0, _emberCpValidations.validator)('presence', true),
    start: (0, _emberCpValidations.validator)('presence', true),
    end: (0, _emberCpValidations.validator)('presence', true),
    validity: (0, _emberCpValidations.validator)('dependent', { on: ['start', 'end'] })

  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {
    title: attr('string'),
    message: attr('string'),
    type: attr('string'),
    start: attr('string'),
    end: attr('string')
  });
});