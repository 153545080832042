define('edibroker/components/user/edit-form', ['exports', 'ember', 'edibroker/components/member/model-form'], function (exports, _ember, _edibrokerComponentsMemberModelForm) {

  /**
   * Form component that can be used to create or edit a user, reusing basic behavior defined by model-form component.
   *
   * ```hbs
   * {{user/edit-form member success='successAction' cancel='cancelAction' error='errorAction'}}
   * ```
   *
   * @param success
   *          {String} name of the action to be triggered when submitting the form
   * @param cancel
   *          {String} name of the action to be triggered when canceling the form
   * @param error
   *          {String} name of the action to be triggered when an error occurs
   */
  var UserForm = _edibrokerComponentsMemberModelForm['default'].extend({
    currentUser: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    canDefineMembers: _ember['default'].computed('model.userProfile', function () {
      return this.get('model.userProfile') && this.get('model.userProfile') !== 'NETPROASSUR' && this.get('model.userProfile') !== 'EDI_MANAGER' && (this.get('currentUser.isNetProassur') || this.get('currentUser.user.members').length > 1);
    }),

    disableProfileModification: _ember['default'].computed('model.id', function () {
      var modelId = this.get('model.id');
      var currentUserId = this.get('currentUser.user.id');
      if (!_ember['default'].isBlank(modelId) && !_ember['default'].isBlank(currentUserId) && this.get('model.userProfile') !== 'EDI_MANAGER') {
        return _ember['default'].isEqual(modelId.toString(), currentUserId.toString());
      } else {
        return false;
      }
    }),

    initialMembers: [],
    askUpdateAnomalyAddress: false,
    updateAnomalyAddress: _ember['default'].computed.alias('model.updateAnomalyAddress'),
    membersChanged: _ember['default'].computed('initialMembers.[]', 'model.members.[]', function () {
      var selected = this.get('model.members').mapBy('id');
      var initial = this.get('initialMembers').mapBy('id');
      return selected.length !== initial.length || selected.filter(function (id) {
        return _ember['default'].$.inArray(id, initial) === -1;
      }).length > 0;
    }),

    mailAsAnomalyAddress: _ember['default'].observer('model.email', 'model.members.[]', function () {
      var email = this.get('model').changedAttributes().email;
      this.set('askUpdateAnomalyAddress', false);
      if (email !== undefined && email[0] !== undefined) {
        var hasTheSameAddress = this.get('model.members').map(function (member) {
          return member.get('address');
        }).some(function (mail) {
          return mail === email[0];
        });

        hasTheSameAddress = hasTheSameAddress || this.get('model.members').map(function (member) {
          return member.get('messages').toArray();
        }).reduce(function (a, b) {
          return a.concat(b);
        }, []).map(function (message) {
          return message.get('address');
        }).some(function (mail) {
          return mail === email[0];
        });

        this.set('askUpdateAnomalyAddress', hasTheSameAddress);
      }
    }),

    save: function save() {
      if (this.get('model.userProfile') === 'NETPROASSUR') {
        // Backend will ignore members for NP admin, we need to cache an empty array
        this.set('model.members', []);
      }
      this._super.apply(this, arguments);
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('model.isNew') && !this.get('currentUser.isNetProassur')) {
        // load members
        this.get('currentUser.user.members').forEach(function (id) {
          _this.get('store').findRecord('member', id).then(function (member) {
            _this.get('model.members').pushObject(member);
            _this.set('selectedMembers', _this.get('model.members').toArray());
          });
        });
      } else {
        this.set('selectedMembers', this.get('model.members').toArray());
      }
      this.get('model.members').then(function (members) {
        return _this.set('initialMembers', members.slice(0));
      });
    },
    actions: {
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.set('model.members', this.get('initialMembers'));
        this.sendAction('cancel');
      }
    }
  });

  UserForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = UserForm;
});