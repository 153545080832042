define('edibroker/models/certificate', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)({
    member: (0, _emberCpValidations.validator)('presence', true),
    protocol: (0, _emberCpValidations.validator)('presence', true)
  });

  exports['default'] = _emberData['default'].Model.extend(Validations, {
    member: belongsTo('member'),
    protocol: attr('string'),
    expirationDate: attr('string'),
    algorithm: attr('string'),
    status: attr('string'),
    isExpired: attr('boolean')
  });
});