define('edibroker/components/user/subscription-form', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {

  /**
   Display a the user subscription form.
  
   Example:
   ```hbs
   {{user/subscription-form model}}
   ```
   */
  var SubscriptionForm = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    tagName: 'form',
    classNameBindings: ['horizontal:form-horizontal'],

    visible: false,

    hrzvisibilities: _ember['default'].A(),
    vrtvisibilities: _ember['default'].A(),

    currentModalPrefix: '',
    currentModalKey: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('messagesFilter', this.getMessagesFilter());

      this.set('hrzvisibilities', this.getHrzVisibilities());
      this.set('vrtvisibilities', this.getVrtVisibilities());

      this._addObservers();
    },

    getVrtVisibilities: function getVrtVisibilities() {
      var a = _ember['default'].A();
      for (var i = 0; i < this.get('availableSubscriptions').length; i++) {
        var key = this.get('availableSubscriptions').get(i).key;
        a.pushObject(key);
        a[key] = false;
      }
      return a;
    },

    getHrzVisibilities: function getHrzVisibilities() {
      var a = _ember['default'].A();
      for (var i = 0; i < this.get('availableSubscriptions').length; i++) {
        var key = this.get('availableSubscriptions').get(i).key;
        a.pushObject(key);
        a[key] = false;
      }
      return a;
    },

    subscriptions: _ember['default'].computed.alias('model.subscriptions'),

    messagesFilter: undefined,

    messagesSubscriptions: _ember['default'].computed.alias('model.messagesSubscriptions'),

    availableMessages: _edibrokerServicesConstants.MESSAGE_TYPES,

    availableSubscriptions: _ember['default'].computed(function () {
      var _component = this;
      return _edibrokerServicesConstants.MAIL_NOTIFICATIONS.map(function (key) {
        var value = _ember['default'].computed('subscriptions.' + key, {
          get: function get() {
            return _component.get('subscriptions.' + key);
          },
          set: function set(_, value) {
            _component.set('subscriptions.' + key, value);
            return value;
          }
        });
        return { key: key, value: value };
      });
    }),

    getMessagesFilter: function getMessagesFilter() {
      var map = _ember['default'].A();
      for (var i = 0; i < this.get('availableSubscriptions').length; i++) {
        var availableSubscription = this.get('availableSubscriptions').get(i).key;
        var a = _ember['default'].A();
        var key = this.get('availableSubscriptions').get(i).key;
        if (Object.keys(this.get('messagesSubscriptions')).includes(availableSubscription)) {
          Array.prototype.push.apply(a, this.get('messagesSubscriptions')[availableSubscription]);
        }
        map.pushObject(key);
        map[key] = a;
      }
      return map;
    },

    horizontal: _ember['default'].computed('mode', function () {
      return this.get('mode') === 'form-horizontal';
    }),

    _addObservers: function _addObservers() {
      var _this = this;

      _edibrokerServicesConstants.MAIL_NOTIFICATIONS.forEach(function (key) {
        _this.addObserver('subscriptions.' + key, _this, function () {
          if (this.get('subscriptions.' + key) !== undefined) {
            this._subscriptionUpdate(key);
          }
        });
      });
    },

    actions: {
      openMyModal: function openMyModal(prefix, key) {

        $("#" + prefix + key).appendTo("body");

        var checkBox = document.getElementById(prefix + "chk-" + key);
        var powerSelect = document.getElementById(prefix + "power-" + key);
        if (!Object.keys(this.get('messagesSubscriptions')).includes(key) || this.get('messagesSubscriptions')[key].length === 0 || this.get('messagesSubscriptions')[key][0] === null) {
          checkBox.checked = true;
          powerSelect.style.visibility = "hidden";
        } else {
          checkBox.checked = false;
          powerSelect.style.visibility = "visible";
        }
        if (checkBox.checked === true) {
          powerSelect.style.visibility = "hidden";
        }

        _ember['default'].set(this.get(prefix + 'visibilities'), key, true);
        this.set('currentModalPrefix', prefix);
        this.set('currentModalKey', key);
      },

      validate: function validate() {
        var prefix = this.get('currentModalPrefix');
        var array = prefix + 'visibilities';
        var key = this.get('currentModalKey');
        _ember['default'].set(this.get(array), key, false);

        if (this.get('messagesFilter')[key].length === 0) {
          (0, _edibrokerServicesConstants.updateSubscription)(this.get('model.id'), key, this.get('subscriptions.' + key), []);
        } else {
          (0, _edibrokerServicesConstants.updateSubscription)(this.get('model.id'), key, this.get('subscriptions.' + key), this.get('messagesFilter')[key]);
        }
        _ember['default'].set(this.get('messagesSubscriptions'), key, this.get('messagesFilter')[key]);
      },

      cancelSave: function cancelSave() {
        var prefix = this.get('currentModalPrefix');
        var array = prefix + 'visibilities';
        var key = this.get('currentModalKey');
        _ember['default'].set(this.get(array), key, false);

        var A = _ember['default'].A();
        if (Object.keys(this.get('messagesSubscriptions')).includes(key) && this.get('messagesSubscriptions')[key][0] == null || !Object.keys(this.get('messagesSubscriptions')).includes(key)) {
          //if(this.get('messagesSubscriptions')[key][0]==null){
          var checkBox = document.getElementById(prefix + "chk-" + key);
          if (checkBox.checked === false) {
            checkBox.checked = true;
            document.getElementById(prefix + "power-" + key).style.visibility = "hidden";
          }
        } else {
          //A.pushObject(this.get('messagesSubscriptions')[key]);
          Array.prototype.push.apply(A, this.get('messagesSubscriptions')[key]);
        }
        _ember['default'].set(this.get('messagesFilter'), key, A);
      },

      chexboxallmessages: function chexboxallmessages(key, isChecked) {
        var prefix;
        if (this.get('horizontal')) {
          prefix = "hrz";
        } else {
          prefix = "vrt";
        }
        if (isChecked) {
          _ember['default'].set(this.get('messagesFilter'), key, []);
          document.getElementById(prefix + "power-" + key).style.visibility = "hidden";
        } else {
          _ember['default'].set(this.get('messagesFilter'), key, []);
          document.getElementById(prefix + "power-" + key).style.visibility = "visible";
        }
      }

    },

    _subscriptionUpdate: function _subscriptionUpdate(key) {
      (0, _edibrokerServicesConstants.updateSubscription)(this.get('model.id'), key, this.get('subscriptions.' + key));
    }

  });

  SubscriptionForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = SubscriptionForm;
});