define("edibroker/templates/language", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "edibroker/templates/language.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "wrapper");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-wrapper");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("footer");
        dom.setAttribute(el2, "class", "main-footer");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "pull-right hidden-xs");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createTextNode("Version");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" - ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" : ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "mailto:contact@edicourtage.fr");
        var el5 = dom.createTextNode("contact@edicourtage.fr");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" –\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" : ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "mailto:support@edicourtage.fr");
        var el5 = dom.createTextNode("support@edicourtage.fr");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" –\n            Tél : 01 70 60 41 33\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [7]);
        var element2 = dom.childAt(element0, [9]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element3, [5]);
        var element5 = dom.childAt(element2, [5]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(element0, 5, 5);
        morphs[3] = dom.createAttrMorph(element1, 'style');
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        morphs[5] = dom.createMorphAt(element3, 3, 3);
        morphs[6] = dom.createAttrMorph(element4, 'class');
        morphs[7] = dom.createMorphAt(element4, 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[9] = dom.createMorphAt(element5, 1, 1);
        morphs[10] = dom.createMorphAt(element5, 5, 5);
        return morphs;
      },
      statements: [["content", "side-bar", ["loc", [null, [2, 4], [2, 16]]], 0, 0, 0, 0], ["content", "header-bar", ["loc", [null, [3, 4], [3, 18]]], 0, 0, 0, 0], ["inline", "left-bar", [], ["currentPath", ["subexpr", "@mut", [["get", "currentPath", ["loc", [null, [4, 27], [4, 38]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 40]]], 0, 0], ["attribute", "style", ["get", "minHeight", ["loc", [null, [5, 41], [5, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "outlet", ["loc", [null, [6, 8], [6, 18]]], 0, 0, 0, 0], ["content", "version", ["loc", [null, [10, 27], [10, 38]]], 0, 0, 0, 0], ["attribute", "class", ["concat", [["subexpr", "unless", [["get", "isProd", ["loc", [null, [10, 60], [10, 66]]], 0, 0, 0, 0], "text-danger"], [], ["loc", [null, [10, 51], [10, 82]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["get", "environment", ["loc", [null, [10, 88], [10, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 84], [10, 101]]], 0, 0], ["inline", "t", ["application.footer.title"], ["currentYear", ["subexpr", "@mut", [["get", "currentYear", ["loc", [null, [13, 55], [13, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 12], [13, 68]]], 0, 0], ["inline", "t", ["application.footer.email-header"], [], ["loc", [null, [16, 12], [16, 51]]], 0, 0], ["inline", "t", ["application.footer.support-header"], [], ["loc", [null, [17, 12], [17, 53]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});