define('edibroker/controllers/language/dashboard', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({

    currentUser: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),
    isNetProAssur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),
    isAdmin: _ember['default'].computed.alias('currentUser.isAdmin').readOnly(),
    isMultiMember: _ember['default'].computed.gt('currentUser.user.members.length', 1),
    showMemberSelect: _ember['default'].computed.or('isNetProAssur', 'isMultiMember'),
    startDate: (0, _moment['default'])().format('YYYY-MM-DD'),
    endDate: (0, _moment['default'])().format('YYYY-MM-DD'),
    processed: _ember['default'].computed.alias('model.processed'),
    sent: _ember['default'].computed.alias('model.sent'),
    received: _ember['default'].computed.alias('model.received'),
    stats: _ember['default'].computed.alias('model.stats'),
    selectedMembers: [],
    memberCodesIn: [],

    isOneDayPeriod: _ember['default'].computed('startDate', 'endDate', function () {
      return this.get('startDate') === this.get('endDate');
    }),

    isMonthPeriod: _ember['default'].computed('startDate', 'endDate', function () {
      var start = (0, _moment['default'])(this.get('startDate'), 'YYYY-MM-DD');
      var end = (0, _moment['default'])(this.get('endDate'), 'YYYY-MM-DD');
      return end.diff(start, 'd') > 31;
    }),

    graphData: _ember['default'].computed.alias('stats.data'),

    loading: {},
    loadingStyle: _ember['default'].computed('loading.stats', function () {
      if (this.get('loading.stats')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    memberFilterChange: _ember['default'].observer('memberCodesIn', function () {
      this.fetchStats();
      if (this.get('isNetProAssur')) {
        this.triggerFilter('processed');
      } else {
        this.triggerFilter('sent');
        this.triggerFilter('received');
      }
    }),

    processedFilterChange: _ember['default'].observer('processed.filter.size', function () {
      this.triggerFilter('processed');
    }),

    sentFilterChange: _ember['default'].observer('sent.filter.size', function () {
      this.triggerFilter('sent');
    }),

    receivedFilterChange: _ember['default'].observer('received.filter.size', function () {
      this.triggerFilter('received');
    }),

    triggerFilter: function triggerFilter(type) {
      if (this.get('rendered')) {
        this.doFilterFiles(type);
      }
    },

    filterFiles: function filterFiles() {
      if (this.get('isNetProAssur')) {
        this.doFilterFiles('processed');
      } else {
        this.doFilterFiles('sent');
        this.doFilterFiles('received');
      }
    },

    doFilterFiles: function doFilterFiles(type) {
      var _this = this;

      this.set('loading.' + type, true);
      this.set(type + '.filter.memberCodesIn', this.get("memberCodesIn"));
      this.store.query('file', this.get(type + '.filter')).then(function (files) {
        _this.set(type + '.files', files);
        _this.set('loading.' + type, false);
      });
    },

    fetchStats: function fetchStats() {
      var _this2 = this;

      this.set('loading.stats', true);
      this.get('ajax').request('/api/files/stats/dashboard?from=' + this.get('startDate') + '&to=' + this.get('endDate') + '&memberCodesIn=' + this.get('memberCodesIn')).then(function (stats) {
        return _this2.set('stats', stats);
      }, function (error) {
        return _this2.send('error', error);
      })['finally'](function () {
        return _this2.set('loading.stats', false);
      });
    },

    actions: {

      reloadModel: function reloadModel(file) {
        file.reload();
      },

      selectPeriod: function selectPeriod() {
        this.fetchStats();
      }
    }

  });
});