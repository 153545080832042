define('edibroker/components/info-message', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Component that can be used to display an informative message above the dashboard page
   */
  var InfoMessage = _ember['default'].Component.extend({

    classNames: ['alert', 'alert-dismissable', 'fade', 'in'],
    classNameBindings: ['alertType'],

    alertType: _ember['default'].computed('info.type', function () {
      return 'alert-' + this.get('info.type').toLowerCase();
    })

  });

  InfoMessage.reopenClass({
    positionalParams: ['info']
  });

  exports['default'] = InfoMessage;
});