define('edibroker/mixins/sftp-model', ['exports', 'ember', 'ember-cp-validations', 'ember-data', 'edibroker/services/constants'], function (exports, _ember, _emberCpValidations, _emberData, _edibrokerServicesConstants) {
  var attr = _emberData['default'].attr;

  var sftpHostMissingOptions = {
    presence: false,
    isWarning: true,
    disabled: _ember['default'].computed.notEmpty('model.sftpHost'),
    messageKey: 'error.validations.sftpHostUndefined'
  };

  var SftpValidations = {

    sftpHost: (0, _emberCpValidations.validator)('format', { regex: /^[0-9a-zA-Z.\-]+$/, allowBlank: true }),

    sftpPort: {
      dependentKeys: ['sftpHost'],
      validators: [(0, _emberCpValidations.validator)('number', { allowString: true, allowBlank: true, disabled: _ember['default'].computed.empty('model.sftpHost') }), (0, _emberCpValidations.validator)('presence', sftpHostMissingOptions)]
    },

    sftpUser: {
      dependentKeys: ['sftpHost'],
      validators: [(0, _emberCpValidations.validator)('format', { allowBlank: true, regex: /^\S+$/, disabled: _ember['default'].computed.empty('model.sftpHost') }), (0, _emberCpValidations.validator)('presence', sftpHostMissingOptions)]
    },

    sftpPassword: {
      dependentKeys: ['sftpHost'],
      validators: [(0, _emberCpValidations.validator)('format', { allowBlank: true, regex: /^\S+$/, disabled: _ember['default'].computed.empty('model.sftpHost') }), (0, _emberCpValidations.validator)('presence', sftpHostMissingOptions)]
    },

    sftpTarget: {
      dependentKeys: ['sftpHost'],
      validators: [(0, _emberCpValidations.validator)('format', { allowBlank: true, regex: /^\S+$/, disabled: _ember['default'].computed.empty('model.sftpHost') }), (0, _emberCpValidations.validator)('presence', sftpHostMissingOptions)]
    },

    sftpKeyFile: {
      dependentKeys: ['sftpHost'],
      validators: [(0, _emberCpValidations.validator)('presence', sftpHostMissingOptions)]
    },

    sftpConf: (0, _emberCpValidations.validator)('dependent', { on: ['sftpHost', 'sftpPort', 'sftpUser', 'sftpPassword', 'sftpTarget'] })

  };

  exports.SftpValidations = SftpValidations;
  exports['default'] = _ember['default'].Mixin.create({

    sftpHost: attr('string'),
    sftpPort: attr('number'),
    sftpUser: attr('string'),
    sftpPassword: attr('string'),
    sftpTarget: attr('string'),
    sftpKey: attr('string'),
    sftpKeyFile: attr('string'),
    sftpKeyDate: attr('string'),

    sftpConf: _ember['default'].computed('sftpHost', 'sftpPort', 'sftpUser', 'sftpTarget', function () {
      return (0, _edibrokerServicesConstants.formatSftpConf)(this);
    })
  });
});