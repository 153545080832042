define('edibroker/routes/download', ['exports', 'ember', 'edibroker/mixins/unauthenticated-route'], function (exports, _ember, _edibrokerMixinsUnauthenticatedRoute) {
  exports['default'] = _ember['default'].Route.extend(_edibrokerMixinsUnauthenticatedRoute['default'], {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),
    queryParams: {
      token: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel(transition) {
      var token = transition.queryParams.token;
      if (!token) {
        this.get('toast').error(this.get('intl').t('error.back.missing.token'));
        this.transitionTo('index');
      }
    },

    model: function model(params) {
      return this.store.queryRecord('token', params);
    },

    resetController: function resetController(controller) {
      controller.set('errorMessage', null);
    },

    actions: {
      error: function error() {
        this.transitionTo('index');
        return true;
      }
    }
  });
});