define('edibroker/routes/language/document/detail/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    templateName: 'language/document/create',
    controllerName: 'language/document/create',
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isCreatePage', false);
      controller.set('fileName', model.get('attachmentName'));
    },

    resetController: function resetController(controller) {
      controller.set('loading', false);
      controller.set('didValidate', false);
      controller.set('editAttachment', false);
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.document.detail');
      },

      save: function save() {
        var _this = this;

        this.get('controller.model').save().then(function () {
          _this.get('toast').success(_this.get('intl').t('document.edit-success'));
          _this.transitionTo('language.document.detail');
        }, function (error) {
          _this.set('controller.loading', false);
          _this.send('error', error);
        });
      }
    }
  });
});