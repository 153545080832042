define('edibroker/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('language', this.get('intl.currentLanguage'));
      } else {
        this.transitionTo('login');
      }
    }
  });
});