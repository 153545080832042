define('edibroker/helpers/user-subscription-messages', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /**
   This helper is used to display subscription messages.
  
   */
  exports['default'] = _ember['default'].Helper.extend({
    intl: _ember['default'].inject.service(),

    onLanguageChange: _ember['default'].observer('intl.currentLanguage', function () {
      this.recompute();
    }),

    onMessageSubscriptionsChange: _ember['default'].observer('user.messagesSubscriptions', function () {
      this.recompute();
    }),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1);

      var subscriptions = _ref2[0];

      if (subscriptions === undefined || subscriptions[0] === null) {
        return [this.get('intl').t('user.subscriptions.messages.all-messages')];
      } else {
        return subscriptions;
      }

      /*Ember.Logger.debug(`Computing user subscription for ${subscription} in language ${this.get('intl.currentLanguage')}`);
       if (subscriptions && subscriptions[subscription]) {
        return this.get('intl').t('user.subscriptions.subscribed');
      } else {
        return this.get('intl').t('user.subscriptions.non-subscribed');
      } */
    }
  });
});