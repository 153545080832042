define('edibroker/controllers/language/administration/dynamicfield/index', ['exports', 'ember', 'edibroker/config/environment'], function (exports, _ember, _edibrokerConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    filter: _ember['default'].computed.alias('model.filter'),
    loading: true,
    dynamicfields: _ember['default'].computed.alias('model.dynamicfields'),
    current: _ember['default'].computed.alias('model.current'),
    rendered: false,
    showActivateModal: false,
    showDeleteModal: false,
    selectedInsurers: [],

    ajax: _ember['default'].inject.service(),
    isNetProassur: _ember['default'].computed.alias('currentUser.isNetProassur').readOnly(),
    isBroker: _ember['default'].computed.gt('currentUser.user.members', function () {
      return this.get('currentUser').user.members[0].equals('BROKER');
    }),
    isNetProassurOrBroker: _ember['default'].computed.or('isNetProassur', 'isBroker'),

    filterChange: _ember['default'].observer('filter.{sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterDynamicfields();
      }
    }),

    filterDynamicfields: function filterDynamicfields() {
      var _this = this;

      this.store.unloadAll('dynamicfield');
      this.store.query('dynamicfield', this.get('filter')).then(function (dynamicfields) {
        if (!_ember['default'].get(dynamicfields, 'length') && _this.get('filter.page')) {
          _this.set('filter.page', 0);
        }
        _this.set('dynamicfields', dynamicfields);
        _this.set('loading', false);
      })['catch'](function (errors) {
        _this.send('error', errors);
      });
    },

    getDownloadUrl: function getDownloadUrl(dynamicfieldId) {
      var url = _edibrokerConfigEnvironment['default'].APP.proxy + '/api/dynamicfields/' + dynamicfieldId + '/download';
      return url + ('?' + this.getQueryParams());
    },

    getQueryParams: function getQueryParams() {
      var queryParams = {};

      var insurers = this.get('selectedInsurers').map(function (r) {
        return r.get('code');
      });

      queryParams['listInsurers'] = insurers.toString();

      queryParams['authorization'] = this.get('currentUser').token();

      queryParams = _ember['default'].$.param(queryParams);
      return queryParams;
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      askActivate: function askActivate(dynamicfield) {
        this.set('dynamicfieldsToActivate', dynamicfield);
        this.set('showActivateModal', true);
      },

      askDelete: function askDelete(dynamicfield) {
        this.set('dynamicfieldsToDelete', dynamicfield);
        this.set('showDeleteModal', true);
      },

      proceedActivate: function proceedActivate(defer) {
        var _this2 = this;

        var dynamicfield = this.get('dynamicfieldsToActivate');
        this.set('dynamicfieldsToActivate', null);
        _ember['default'].$.ajax({
          url: '/api/dynamicfields/' + dynamicfield.get('id') + '/activate',
          method: 'POST'
        }).then(function () {
          defer.resolve();
          _this2.get('toast').success(_this2.get('intl').t('dynamicfields.activate-success'));
          _this2.store.queryRecord('dynamicfield', {}).then(function (current) {
            return _this2.set('current', current);
          });
          _this2.filterDynamicfields();
        }).fail(function (error) {
          defer.resolve();
          _this2.send('error', error.responseJSON);
        });
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        var dynamicfield = this.get('dynamicfieldsToDelete');
        this.set('dynamicfieldsToDelete', null);
        dynamicfield.destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('dynamicfields.delete-success'));
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      },

      proceedDownload: function proceedDownload(dynamicfieldsId) {
        window.open(this.getDownloadUrl(dynamicfieldsId), '_blank');
      }

    }
  });
});