define('edibroker/components/header-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    connectedUser: _ember['default'].computed.alias('currentUser.user'),
    userPreferences: _ember['default'].computed.alias('connectedUser.preferences'),
    currentLanguage: _ember['default'].computed('intl.currentLanguage', function () {
      return 'language.' + this.get('intl.currentLanguage');
    }),

    didInsertElement: function didInsertElement() {
      var userPref = this.get('userPreferences.menuState');
      if (userPref === 'expanded') {
        // Default is collapsed menu, toggle the collapse class to expand it.
        _ember['default'].$('.ember-application').toggleClass('sidebar-collapse');
      }
    },

    actions: {
      logout: function logout() {
        this.get('currentUser').logout();
      }
    }
  });
});