define('edibroker/models/message', ['exports', 'ember-data', 'ember', 'ember-cp-validations', 'edibroker/models/base-message', 'edibroker/mixins/sftp-model'], function (exports, _emberData, _ember, _emberCpValidations, _edibrokerModelsBaseMessage, _edibrokerMixinsSftpModel) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var Validations = (0, _emberCpValidations.buildValidations)(_ember['default'].merge({

    address: (0, _emberCpValidations.validator)('multi-format', { type: 'email', split: ';', messageKey: 'error.validations.multipleEmail', allowBlank: true }),
    sftpType: (0, _emberCpValidations.validator)('presence', _edibrokerModelsBaseMessage.outValidatorOptions)

  }, _edibrokerMixinsSftpModel.SftpValidations));

  exports['default'] = _edibrokerModelsBaseMessage['default'].extend(_edibrokerMixinsSftpModel['default'], Validations, {

    member: belongsTo('member'),
    address: attr('string'),
    sftpType: attr('string'),

    // Override base property to check sftp type presence
    isOut: _ember['default'].computed.or('outFormat', 'outVersion', 'sftpType').readOnly(),
    isDefaultSftp: _ember['default'].computed('sftpType', function () {
      return this.get('sftpType') === 'DEFAULT';
    }).readOnly(),
    isSpecificSftp: _ember['default'].computed('sftpType', function () {
      return this.get('sftpType') === 'SPECIFIC';
    }).readOnly()

  });
});