define('edibroker/components/inputs/member-autocomplete', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Simple component for autocomplete search on members.
   *
   * This takes three positional parameters:
   * - `model` the model which this input will update
   * - `attribute` the attribute of the model to update
   * - `subAttribute` (optional) the attribute of the retrieve element to use (default is using the complete element).
   *
   * This have five attribute parameters:
   * - `label` the label of the input
   * - `selected` the already selected values
   * - `didValidate` is a validation has been done (needed when using this input in a form with validation)
   * - `isMultiple` if this input is for multiple selection or single selection (default is `true`)
   * - `isFileSearch` if this input is used for searching file (default is `false`)
   *
   */
  var MemberAutocompleteComponent = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    model: {},
    attribute: '',
    subAttribute: null,

    label: '',
    selected: [],
    didValidate: false,
    isMultiple: true,
    isFileSearch: false,
    labelClass: "col-sm-2",
    inputClass: "col-sm-4",

    option: _ember['default'].computed('isFileSearch', function () {
      if (this.get('isFileSearch')) {
        return 'file';
      } else {
        return this.get('memberstype');
      }
    }),

    actions: {

      searchMembers: function searchMembers(term) {
        return this.get('store').query('member', { query: term, option: this.get('option') });
      }
    }
  });

  MemberAutocompleteComponent.reopenClass({
    positionalParams: ['model', 'attribute', 'subAttribute']
  });

  exports['default'] = MemberAutocompleteComponent;
});