define('edibroker/routes/language/profile/edit', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('language.administration.user.detail').set('isEditPage', true);
      controller.set('selectedMembers', model.get('members').toArray());
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.controllerFor('language.administration.user.detail').set('isEditPage', false);
        controller.set('didValidate', false);
        controller.set('selectedMembers', []);
      }
    },

    actions: {
      cancel: function cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('language.profile');
      },

      save: function save() {
        var _this = this;

        this.get('controller.model').save().then(function () {
          _this.get('toast').success(_this.get('intl').t('user.profile.edit-success'));
          _this.transitionTo('language.profile');
        }, function (error) {
          _this.set('controller.loading', false);
          _this.send('error', error);
        });
      }
    }
  });
});