define('edibroker/routes/language/administration/accesstoken/detail', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    model: function model(params) {
      return this.store.findRecord('access-token', params.id);
    },

    resetController: function resetController(controller) {
      controller.set('showDeleteModal', false);
      controller.set('showExtendModal', false);
      controller.set('showRenewalModal', false);
    }
  });
});