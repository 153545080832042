define('edibroker/controllers/language/stats', ['exports', 'ember', 'edibroker/services/constants', 'lodash'], function (exports, _ember, _edibrokerServicesConstants, _lodash) {
  exports['default'] = _ember['default'].Controller.extend({

    ajax: _ember['default'].inject.service(),

    availableFileStatuses: _edibrokerServicesConstants.FILE_SEARCH_STATUSES,
    availableProtocolTypes: _edibrokerServicesConstants.PROTOCOL_TYPES,
    availableMessageFormats: _edibrokerServicesConstants.MESSAGE_FORMATS,
    availableMessageTypes: _edibrokerServicesConstants.MESSAGE_TYPES,
    availableMessageVersions: _edibrokerServicesConstants.MESSAGE_VERSIONS,

    inProtocols: _ember['default'].computed(function () {
      var protocols = _edibrokerServicesConstants.PROTOCOL_TYPES.slice(0);
      protocols.removeObject('SFTP_PUSH');
      return protocols;
    }),

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    selectedSenders: [],
    selectedReceivers: [],

    filter: _ember['default'].computed.alias('model.filter'),
    deposit: _ember['default'].computed.alias('model.stats.deposit'),
    withdraw: _ember['default'].computed.alias('model.stats.withdraw'),
    total: _ember['default'].computed.alias('model.stats.total'),

    messages: _ember['default'].computed.alias('model.stats.messages'),

    sortCriteria: _ember['default'].computed.collect('msgSort'),
    msgSort: 'type',
    sortedMessages: _ember['default'].computed.sort('messages', 'sortCriteria'),

    filterChange: _ember['default'].observer('filter.{senderIdsIn.[],receiverIdsIn.[],depositStart,depositEnd,conversionDateStart,conversionDateEnd,lastReadyStart,lastReadyEnd,lastWithdrawStart,lastWithdrawEnd,msgTypesIn.[],statusesIn.[],inputProtocolsIn.[],outputProtocolsIn.[],inputVersionsIn.[],outputVersionsIn.[],inputFormatsIn.[],outputFormatsIn.[]}', function () {
      var _this = this;

      if (this.get('rendered')) {
        _ember['default'].run.once(function () {
          return _this.fetchStats();
        });
      }
    }),

    fetchStats: function fetchStats() {
      var _this2 = this;

      this.set('loading', true);
      var params = _lodash['default'].transform(this.get('filter'), function (result, value, key) {
        if (value instanceof Array) {
          result[key] = value.join(',');
        } else {
          result[key] = value;
        }
      });
      params = _ember['default'].$.param(params);
      this.get('ajax').request('/api/files/stats?' + params).then(function (stats) {
        return _this2.set('model.stats', stats);
      }, function (error) {
        return _this2.send('error', error);
      })['finally'](function () {
        return _this2.set('loading', false);
      });
    },

    actions: {
      cancelDepositDate: function cancelDepositDate() {
        this.set('filter.depositStart', undefined);
        this.set('filter.depositEnd', undefined);
      },

      cancelConversionDate: function cancelConversionDate() {
        this.set('filter.conversionDateStart', undefined);
        this.set('filter.conversionDateEnd', undefined);
      },

      cancelLastAvailDate: function cancelLastAvailDate() {
        this.set('filter.lastReadyStart', undefined);
        this.set('filter.lastReadyEnd', undefined);
      },

      cancelLastRetrievalDate: function cancelLastRetrievalDate() {
        this.set('filter.lastWithdrawStart', undefined);
        this.set('filter.lastWithdrawEnd', undefined);
      },

      reset: function reset() {
        this.set('selectedSenders', []);
        this.set('selectedReceivers', []);
        return true;
      }
    }

  });
});