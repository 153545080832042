define('edibroker/routes/language', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    intl: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var lang = transition.params['language']['lang'];
      if (lang !== 'fr' && lang !== 'en') {
        this.transitionTo('language', 'fr');
      }
    },

    model: function model(params) {
      this.get('intl').load(params.lang);
      return this.get('intl.currentLanguage');
    }
  });
});