define('edibroker/components/pagination-bar', ['exports', 'ember'], function (exports, _ember) {

  /**
    Display a action bar containing a page switcher.
  
    Example:
    ```hbs
    {{pagination-bar filter=filter model=members}}
    ```
   */
  exports['default'] = _ember['default'].Component.extend({
    currentPage: _ember['default'].computed('model.meta.page', function () {
      if (this.get('model.meta.page')) {
        return this.get('model.meta.page') + 1;
      } else {
        return 1;
      }
    }),

    maxPage: _ember['default'].computed('model.meta.{total,size}', function () {
      return Math.ceil(this.get('model.meta.total') / this.get('model.meta.size'));
    }),

    hasPrevious: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') > 1;
    }),

    hasNext: _ember['default'].computed('currentPage', 'maxPage', function () {
      return this.get('currentPage') < this.get('maxPage');
    }),

    firstPages: _ember['default'].computed('currentPage', function () {
      var firstPages = [],
          maxRange = Math.min(3, this.get('currentPage'));

      for (var i = 1; i < maxRange; i++) {
        firstPages.push(i);
      }
      return firstPages;
    }),

    previousPages: _ember['default'].computed('firstPages', 'currentPage', function () {
      var previousPages = [],
          firstPages = this.get('firstPages'),
          current = this.get('currentPage'),
          minRange = Math.max(1, current - 1);

      for (var i = minRange; i < current; i++) {
        if (firstPages.indexOf(i) === -1) {
          previousPages.push(i);
        }
      }
      return previousPages;
    }),

    nextPages: _ember['default'].computed('lastPages', 'maxPage', 'currentPage', function () {
      var nextPages = [],
          lastPages = this.get('lastPages'),
          current = this.get('currentPage'),
          maxRange = Math.min(this.get('maxPage'), current + 1);

      for (var i = current + 1; i <= maxRange; i++) {
        if (lastPages.indexOf(i) === -1) {
          nextPages.push(i);
        }
      }
      return nextPages;
    }),

    lastPages: _ember['default'].computed('maxPage', 'currentPage', function () {
      var lastPages = [],
          current = this.get('currentPage'),
          last = this.get('maxPage');

      if (current < last - 1) {
        lastPages.push(last - 1);
      }
      if (current < last) {
        lastPages.push(last);
      }
      return lastPages;
    }),

    hasPreviousAnonymousPages: _ember['default'].computed('previousPages', function () {
      var previousPages = this.get('previousPages');
      return previousPages.length > 0 && previousPages[0] > 3;
    }),

    hasNextAnonymousPages: _ember['default'].computed('nextPages', 'maxPage', function () {
      var nextPages = this.get('nextPages'),
          maxPage = this.get('maxPage');

      return nextPages.length > 0 && nextPages[nextPages.length - 1] < maxPage - 2;
    }),

    actions: {
      previousPage: function previousPage() {
        if (this.get('hasPrevious')) {
          this.decrementProperty('filter.page');
        }
      },

      nextPage: function nextPage() {
        if (this.get('hasNext')) {
          this.incrementProperty('filter.page');
        }
      },

      selectPage: function selectPage(page) {
        this.set('filter.page', page - 1);
      }
    }
  });
});