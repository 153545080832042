define('edibroker/components/inputs/v-input-td', ['exports', 'edibroker/components/inputs/v-input'], function (exports, _edibrokerComponentsInputsVInput) {

  /**
   * Validated input to be shown in a table as a cell. Validation error is
   * displayed as input title when required
   */
  var ValidatedInputTd = _edibrokerComponentsInputsVInput['default'].extend({
    tagName: 'td'
  });

  ValidatedInputTd.reopenClass({
    positionalParams: ['model', 'attribute']
  });

  exports['default'] = ValidatedInputTd;
});