define('edibroker/validators/unique-email', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var UniqueEmail = _emberCpValidationsValidatorsBase['default'].extend({
    unicityCheck: _ember['default'].inject.service(),

    validate: function validate(value, options) /*, attribute*/{
      var model = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      return this.get('unicityCheck').getUnicityPromise('/api/users/public?email=' + value, 'error.validations.emailUniqueness', _ember['default'].get(model, 'id'));
    }
  });

  UniqueEmail.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = UniqueEmail;
});