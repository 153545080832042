define('edibroker/components/member/sftp-form', ['exports', 'edibroker/components/member/model-form', 'ember'], function (exports, _edibrokerComponentsMemberModelForm, _ember) {

  /**
   * Form component that can be used to edit a SFTP configuration, reusing basic behavior defined by model-form component.
   *
   * ```hbs
   * {{member/sftp-form member success='successAction' cancel='cancelAction'}}
   * ```
   *
   * @param success
   *          {String} name of the action to be triggered when submitting the form
   * @param cancel
   *          {String} name of the action to be triggered when canceling the form
   * @param error
   *          {String} name of the action to be triggered when an error occurs. Defaults to error
   */
  var SftpForm = _edibrokerComponentsMemberModelForm['default'].extend({

    intl: _ember['default'].inject.service(),

    error: 'error',

    selectedKeyFile: _ember['default'].computed('model.sftpKeyFile', function () {
      var keyFile = this.get('model.sftpKeyFile');
      return keyFile ? keyFile : this.get('intl').t('common.file');
    }),

    sftpData: _ember['default'].computed('model.{sftpHost,sftpPort,sftpUser,sftpPassword,sftpTarget,sftpKeyFile}', function () {
      var data = this.get('model').getProperties('sftpHost', 'sftpPort', 'sftpUser', 'sftpPassword', 'sftpTarget', 'sftpTarget', 'sftpKeyFile');
      return new Blob([JSON.stringify(data)], { type: 'application/json;charset=UTF-8' });
    }),

    uploadUrl: _ember['default'].computed('model.id', function () {
      return '/api/' + this.get('modelType') + '/' + this.get('model.id') + '/uploadSftp';
    }),

    clearModel: function clearModel() {
      var model = this.get('model');
      model.set('sftpHost', undefined);
      model.set('sftpPort', undefined);
      model.set('sftpUser', undefined);
      model.set('sftpPassword', undefined);
      model.set('sftpTarget', undefined);
      model.set('sftpKeyFile', undefined);
      model.set('sftpKey', undefined);
      model.set('sftpKeyDate', undefined);
    },

    actions: {
      clear: function clear() {
        this.clearModel();
      },

      clearKey: function clearKey() {
        var model = this.get('model');
        model.set('sftpKey', undefined);
        model.set('sftpKeyFile', undefined);
      },

      save: function save() {
        if (!this.get('model.sftpHost')) {
          this.clearModel();
        }
        if (this.get('model.sftpKeyFile') && this.get('model.sftpHost') && !this.get('model.sftpKey')) {
          this.set('upload', true);
        } else {
          this.save('success');
        }
      },

      success: function success() {
        this.set('upload', false);
        this.get('model').rollbackAttributes();
        this.get('model').reload();
        this.sendAction('success');
      },

      failure: function failure(error) {
        this.set('upload', false);
        this.set('loading', false);
        this.sendAction('error', error.responseJSON);
      }
    }
  });

  SftpForm.reopenClass({
    positionalParams: ['model']
  });

  exports['default'] = SftpForm;
});