define('edibroker/routes/language/administration/member/detail/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Update bread crumb displayed by parent route
      this.controllerFor('language.administration.member.detail').set('isEditPage', true);
    },

    actions: {

      success: function success() {
        this.get('toast').success(this.get('intl').t('member.edit-success'));
        this.transitionTo('language.administration.member.detail');
      },

      cancel: function cancel() {
        this.transitionTo('language.administration.member.detail');
      }

    }
  });
});