define('edibroker/authenticators/custom', ['exports', 'ember', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _ember, _emberSimpleAuthTokenAuthenticatorsJwt) {
  exports['default'] = _emberSimpleAuthTokenAuthenticatorsJwt['default'].extend({

    scheduleAccessTokenRefresh: function scheduleAccessTokenRefresh(expiresAt, token) {
      if (this.refreshAccessTokens) {
        expiresAt = this.resolveTime(expiresAt);

        var now = this.getCurrentTime();
        var wait = (expiresAt - now - this.refreshLeeway) * 1000;

        if (!_ember['default'].isEmpty(token) && !_ember['default'].isEmpty(expiresAt) && wait > 0) {
          _ember['default'].run.cancel(this._refreshTokenTimeout);
          delete this._refreshTokenTimeout;
          this._refreshTokenTimeout = _ember['default'].run.later(this, this.refreshAccessToken, token, wait);
        }
      }
    },
    getTokenData: function getTokenData(token) {
      // Replace base64url chars by basic base64 ones before decoding
      return this._super(token.replace(/_/g, '/').replace(/-/g, '+'));
    }
  });
});