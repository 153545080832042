define('edibroker/serializers/certificate', ['exports', 'ember-data', 'edibroker/serializers/application'], function (exports, _emberData, _edibrokerSerializersApplication) {
  exports['default'] = _edibrokerSerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {
    attrs: {
      member: {
        deserialize: 'id',
        serialize: 'id'
      },
      isExpired: {
        serialize: false
      }
    }
  });
});