define('edibroker/validators/member-presence', ['exports', 'ember', 'ember-cp-validations/validators/base', 'ember-validators'], function (exports, _ember, _emberCpValidationsValidatorsBase, _emberValidators) {
  var _slice = Array.prototype.slice;

  var MemberPresenceValidator = _emberCpValidationsValidatorsBase['default'].extend({
    currentUser: _ember['default'].inject.service(),

    validate: function validate(value, options) /*, attribute*/{
      var model = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var profile = _ember['default'].get(model, 'userProfile');
      if (profile === 'NETPROASSUR' || profile === 'EDI_MANAGER') {
        return true;
      }

      var currentUserProfile = this.get('currentUser.user.userProfile');
      if (currentUserProfile === 'NETPROASSUR' || currentUserProfile === 'MEMBER_ADMIN') {
        var result = _emberValidators.validate.apply(undefined, ['presence'].concat(_slice.call(arguments)));

        if (result && typeof result === 'object') {
          return this.createErrorMessage(result.type, result.value, result.context);
        }
        return result;
      } else {
        return true;
      }
    }
  });

  MemberPresenceValidator.reopenClass({
    getDependentsFor: function getDependentsFor(attribute /*, options */) {
      return ['model.' + attribute + '.[]'];
    }
  });

  exports['default'] = MemberPresenceValidator;
});