define('edibroker/controllers/language/administration/member/detail', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({

    isEditPage: false,

    hasDirtyMessages: _ember['default'].computed('model.messages.@each.hasDirtyAttributes', function () {
      return this.get('model.messages').filterBy('hasDirtyAttributes').length > 0;
    }),

    updateTransitionStatus: function updateTransitionStatus(transition, routeName) {
      var _this = this;

      // Allow transitions to and from nested route
      var target = _ember['default'].get(transition, 'targetName');
      if (target && target.indexOf(routeName) > -1) {
        return;
      }

      var status = (0, _edibrokerServicesConstants.getOrCreateStatus)(transition);
      status.shouldConfirm = status.shouldConfirm || this.get('hasDirtyMessages');
      status.resetCallbacks.push(function () {
        _this.get('model.messages').filterBy('hasDirtyAttributes').forEach(function (message) {
          return message.rollbackAttributes();
        });
      });
    }

  });
});