define('edibroker/components/member-display', ['exports', 'ember'], function (exports, _ember) {

  var MemberDisplayComponent = _ember['default'].Component.extend({
    tagName: '',

    currentUser: _ember['default'].inject.service(),

    noLink: false,
    linkForbidden: _ember['default'].computed(function () {
      return !this.get('currentUser').hasMemberAccess(this.get('member'));
    }),
    hideLink: _ember['default'].computed.or('noLink', 'linkForbidden')

  });

  MemberDisplayComponent.reopenClass({
    positionalParams: ['member']
  });

  exports['default'] = MemberDisplayComponent;
});