define('edibroker/controllers/language/administration/dynamicfield/upload', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

  var Validations = (0, _emberCpValidations.buildValidations)({
    file: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  exports['default'] = _ember['default'].Controller.extend(Validations, {
    currentUser: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    data: _ember['default'].computed('activateNewVersion', function () {
      return new Blob(['{"activate": ' + this.get('activateNewVersion') + '}'], {
        type: 'application/json;charset=UTF-8'
      });
    }),

    didValidate: false,
    loading: false,
    upload: false,
    activateNewVersion: true,

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    uploadUrl: '/api/dynamicfields/upload',

    resetStatus: function resetStatus() {
      this.set('loading', false);
      this.set('upload', false);
      this.set('file', undefined);
      this.set('didValidate', false);
    },

    actions: {

      validate: function validate() {
        var _this = this;

        this.set('loading', true);
        this.validate().then(function (result) {
          _this.set('didValidate', true);
          if (_ember['default'].get(result, 'validations.isValid')) {
            _this.set('upload', true);
          } else {
            _this.set('loading', false);
            _this.get('toast').error(_this.get('intl').t('error.validations.hasError'));
          }
        });
      },

      cancel: function cancel() {
        this.resetStatus();
        this.transitionToRoute('language.administration.dynamicfields');
      },

      failure: function failure(error) {
        this.resetStatus();
        this.send('error', error.responseJSON);
      },

      success: function success() {
        this.resetStatus();
        this.get('toast').success(this.get('intl').t('dynamicfields.upload-success'));
        this.transitionToRoute('language.administration.dynamicfield');
      }

    }
  });
});