define('edibroker/controllers/language/administration/certificate/index', ['exports', 'ember', 'edibroker/services/constants'], function (exports, _ember, _edibrokerServicesConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    rendered: false,
    loading: true,
    showDeleteModal: false,
    showRenewalModal: false,

    filter: _ember['default'].computed.alias('model.filter'),
    certificates: _ember['default'].computed.alias('model.certificates'),

    availableStatus: _edibrokerServicesConstants.IDENTIFICATION_STATUSES,

    availableProtocols: _ember['default'].computed('intl.currentLanguage', function () {
      var _this = this;

      _ember['default'].Logger.debug('Recomputing available protocols as language is now ' + this.get('intl.currentLanguage'));
      var content = _edibrokerServicesConstants.PROTOCOL_TYPES.filter(function (p) {
        return p !== 'UPLOAD' && p !== 'SFTP_PUSH';
      }).map(function (protocol) {
        return {
          value: protocol,
          label: _this.get('intl').t('enums.ProtocolType.' + protocol)
        };
      });
      content.unshift({
        value: '',
        label: ''
      });
      return content;
    }),

    selectedProtocol: _ember['default'].computed('filter.protocol', {
      get: function get() {
        var value = _ember['default'].get(this, 'filter.protocol');
        if (value) {
          return {
            value: value,
            label: this.get('intl').t('enums.ProtocolType.' + value)
          };
        } else {
          return {
            value: '',
            label: ''
          };
        }
      },
      set: function set(key, value) {
        _ember['default'].set(this, 'filter.protocol', value.value);
        return value;
      }
    }),

    filterChange: _ember['default'].observer('filter.{memberIdsIn.[],statusIn.[],protocol,sort,page,size}', function () {
      if (this.get('rendered')) {
        this.set('loading', true);
        this.filterCertificates();
      }
    }),

    filterCertificates: function filterCertificates() {
      var _this2 = this;

      this.store.query('certificate', this.get('filter')).then(function (certificates) {
        if (!_ember['default'].get(certificates, 'length') && _this2.get('filter.page')) {
          _this2.set('filter.page', 0);
        }
        _this2.set('certificates', certificates);
        _this2.set('loading', false);
      })['catch'](function (errors) {
        _this2.send('error', errors);
      });
    },

    loadingStyle: _ember['default'].computed('loading', function () {
      if (this.get('loading')) {
        return _ember['default'].String.htmlSafe('display: block');
      } else {
        return _ember['default'].String.htmlSafe('display: none');
      }
    }),

    actions: {
      sort: function sort(value) {
        var sort = this.get('filter.sort');
        if (sort.indexOf(value) === -1) {
          this.set('filter.sort', value);
        } else if (sort.indexOf(',') === -1) {
          this.set('filter.sort', value + ',desc');
        } else {
          this.set('filter.sort', value);
        }
      },

      error: function error() {
        this.set('loading', false);
        return true;
      },

      reset: function reset() {
        this.set('selectedMembers', []);
        return true;
      },

      askDelete: function askDelete(certificate) {
        this.set('certificateToDelete', certificate);
        this.set('showDeleteModal', true);
      },

      proceedDelete: function proceedDelete(defer) {
        var _this3 = this;

        var certificate = this.get('certificateToDelete');
        this.set('certificateToDelete', null);
        certificate.destroyRecord().then(function () {
          defer.resolve();
          _this3.get('toast').success(_this3.get('intl').t('certificate.delete-success'));
        }, function (error) {
          defer.resolve();
          _this3.send('error', error);
        });
      },

      askRenewal: function askRenewal(certificate) {
        this.set('certificateToRenew', certificate);
        this.set('showRenewalModal', true);
      },

      proceedRenewal: function proceedRenewal(defer) {
        var _this4 = this;

        var certificate = this.get('certificateToRenew');
        this.set('certificateToRenew', null);
        _ember['default'].$.ajax({
          url: '/api/certificates/' + certificate.get('id') + '/renew',
          method: 'POST'
        }).then(function () {
          defer.resolve();
          _this4.get('toast').success(_this4.get('intl').t('certificate.renewal-success'));
          _this4.set('loading', true);
          _this4.filterCertificates();
        }).fail(function (error) {
          defer.resolve();
          _this4.send('error', error.responseJSON);
        });
      }
    }
  });
});