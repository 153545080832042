define('edibroker/routes/language/administration/memberprofile/detail', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    templateName: 'language/administration/member/detail',
    controllerName: 'language/administration/member/detail',

    model: function model(params) {
      return this.store.queryRecord('member', { code: params.code });
    },

    afterModel: function afterModel(member, transition) {
      if (member.get('status') === 'DELETED') {
        this.get('toast').error(this.get('intl').t('error.back.state.deleted-member'));
        if (transition.sequence === 0) {
          this.replaceWith('language.dashboard');
        } else {
          transition.abort();
        }
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('isModelPage', false);
      controller.set('isProfilePage', true);
    },

    actions: {
      willTransition: function willTransition(transition) {
        this.get('controller').updateTransitionStatus(transition, this.get('routeName'));
        return true;
      }
    }
  });
});