define('edibroker/routes/language/file/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentUser: _ember['default'].inject.service(),

    model: function model() {
      return [];
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.set('rendered', true);
        controller.filterFiles();
      });
    },

    resetController: function resetController(controller, isExiting, transition) {
      controller.set('rendered', false);
      controller.set('fileLoading', true);
      controller.set('filterLoading', false);
      controller.set('filterListLoading', false);
      if (transition.targetName !== 'language.file.detail') {
        controller.resetFields();
        controller.set('isSelectedFilter', false);
      }
    }

  });
});