define('edibroker/adapters/application', ['exports', 'ember', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'lodash', 'edibroker/config/environment'], function (exports, _ember, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _lodash, _edibrokerConfigEnvironment) {
  exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    host: _edibrokerConfigEnvironment['default'].host,
    namespace: _edibrokerConfigEnvironment['default'].namespace,
    authorizer: 'authorizer:token',

    pathForType: function pathForType(type) {
      return _ember['default'].String.pluralize(type.replace(/-/g, '').toLowerCase());
    },

    ajaxOptions: function ajaxOptions(url, type, options) {
      var optionsCopy = undefined;
      if (type === 'GET' && options && options.data) {
        optionsCopy = {};
        optionsCopy.data = _lodash['default'].transform(options.data, function (result, value, key) {
          if (value instanceof Array) {
            result[key] = value.join(',');
          } else {
            result[key] = value;
          }
        });
      } else {
        optionsCopy = options;
      }
      return this._super(url, type, optionsCopy);
    }
  });
});