define('edibroker/mirage/fixtures/members', ['exports'], function (exports) {
  exports['default'] = [{
    id: 0,
    code: '00000001',
    label: 'Insurance Example',
    type: 'INSURANCE',
    status: 'ENABLED',
    address: 'anomaly.address@mail.fr',
    language: 'FR',
    messageIds: [0, 1],
    msgTypes: ['201', '202']

  }, {
    id: 1,
    code: '00000002',
    label: 'Broker Example',
    type: 'BROKER',
    status: 'ENABLED'
  }, {
    id: 2,
    code: '00000003',
    label: 'Another Insurance',
    type: 'INSURANCE',
    status: 'ENABLED'
  }, {
    id: 3,
    code: '00000004',
    label: 'Another Broker',
    type: 'BROKER',
    status: 'ENABLED'
  }];
});