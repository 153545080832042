define('edibroker/routes/language/administration/memberprofile/detail/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    templateName: 'language/administration/member/detail/edit',
    intl: _ember['default'].inject.service(),
    toast: _ember['default'].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // Update bread crumb displayed by parent route
      this.controllerFor('language.administration.memberprofile.detail').set('isEditPage', true);
    },

    actions: {
      success: function success() {
        this.get('toast').success(this.get('intl').t('member.edit-success'));
        this.transitionTo('language.administration.memberprofile.detail');
      },
      cancel: function cancel() {
        this.transitionTo('language.administration.memberprofile.detail');
      }
    }
  });
});