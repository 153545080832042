define('edibroker/validators/unique-document-name', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

  var UniqueDocumentName = _emberCpValidationsValidatorsBase['default'].extend({
    store: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    validate: function validate(value, options) {
      var _this = this;

      var model = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var filter = {
        name: value,
        page: 0,
        size: 1
      };
      var id = _ember['default'].get(model, 'id');
      if (id) {
        filter['idsNotIn'] = [id];
      }
      return this.get('store').query('document', filter).then(function (documents) {
        if (_ember['default'].get(documents, 'length') > 0) {
          return _this.get('intl').t('error.validations.documentNameUniqueness');
        } else {
          return true;
        }
      });
    }
  });

  UniqueDocumentName.reopenClass({
    getDependentsFor: function getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports['default'] = UniqueDocumentName;
});