define('edibroker/services/input-sequence', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Service proposing a sequence for input.
   */
  exports['default'] = _ember['default'].Service.extend({
    _initial: 0,

    next: function next() {
      return this.incrementProperty('_initial');
    }
  });
});